import { Outlet } from "react-router";

import { DevCheatsProvider } from "~/hooks/useDevCheats";
import { NavbarProvider } from "~/hooks/useNavbar";
import { SideNavProvider } from "~/hooks/useSideNav";

import { ViewProvider } from "~/hooks/useView";

/**
 * Combines custom providers that provide context to Qubit pages.
 * @constructor
 */
export function AppProviders() {
  return (
    <ViewProvider>
      <NavbarProvider>
        <SideNavProvider>
          <DevCheatsProvider>
            <Outlet />
          </DevCheatsProvider>
        </SideNavProvider>
      </NavbarProvider>
    </ViewProvider>
  );
}
