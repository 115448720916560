import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { ProgressButton } from "frontend-components";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { TotePlacementDto, UpdateWorkstation } from "~/types/api";

import { possibleGridCoordinates } from "./workstationConstants";

export function TotePlacements() {
  const { t } = useTranslation();
  const isAdmin = useAppSelector(selectUserIsAdmin);

  const { control, register, watch } = useFormContext<UpdateWorkstation>();
  const {
    fields: totePlacements,
    append: addTotePlacement,
    remove: removeTotePlacement
  } = useFieldArray({
    control,
    name: "totePlacements"
  });

  const createNewTotePlacementTemplate = (): TotePlacementDto => ({
    totePosition: totePlacements.length + 1,
    putToLightModuleId: 0,
    coordinate: {
      x: 0,
      y: 0
    }
  });

  return (
    <Box>
      <InputLabel
        shrink
        style={{
          fontWeight: 600,
          color: "#000",
          fontSize: 18,
          marginBottom: "20px"
        }}
      >
        {t("tote placements")}
      </InputLabel>

      {totePlacements.map((totePlacement, index) => (
        <Box
          key={totePlacement.id}
          style={{
            display: "flex",
            marginBottom: 20
          }}
          role="listitem"
        >
          <TextField
            label={t("position")}
            variant="standard"
            style={{
              width: 50,
              marginRight: 20,
              marginTop: 6
            }}
            {...register(`totePlacements.${index}.totePosition`)}
            disabled
          />

          <Box
            style={{
              width: 50,
              marginRight: 20,
              marginTop: 6
            }}
          >
            <TextField
              style={{ width: 38 }}
              label={t("ptl id")}
              variant="standard"
              disabled={!isAdmin}
              {...register(`totePlacements.${index}.putToLightModuleId`)}
            />
          </Box>

          <Box
            style={{
              width: 50,
              marginRight: 20
            }}
          >
            <InputLabel id="tp-coord-x" shrink>
              X
            </InputLabel>
            <Select
              labelId="tp-coord-x"
              fullWidth
              variant="standard"
              disabled={!isAdmin}
              {...register(`totePlacements.${index}.coordinate.x`)}
              value={watch(`totePlacements.${index}.coordinate.x`)}
            >
              {possibleGridCoordinates.map((numOption) => (
                <MenuItem key={numOption} value={numOption}>
                  {`${numOption}`}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box
            style={{
              width: 50,
              marginRight: 20
            }}
          >
            <InputLabel id="tp-coord-y" shrink>
              Y
            </InputLabel>
            <Select
              labelId="tp-coord-y"
              variant="standard"
              fullWidth
              {...register(`totePlacements.${index}.coordinate.y`)}
              value={watch(`totePlacements.${index}.coordinate.y`)}
              disabled={!isAdmin}
            >
              {possibleGridCoordinates.map((numOption) => (
                <MenuItem key={numOption} value={numOption}>
                  {`${numOption}`}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {isAdmin && (
            <Box
              style={{
                width: 50,
                marginRight: 20,
                marginTop: 12
              }}
            >
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <CancelIcon
                  aria-label="remove-tote-placement"
                  onClick={() => removeTotePlacement(index)}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
          )}
        </Box>
      ))}

      {isAdmin && (
        <Box
          style={{
            width: 50,
            marginRight: 20,
            marginTop: 12
          }}
        >
          <ProgressButton
            id="add-tote-button"
            emphasis="medium"
            buttonSize="small"
            responsive
            style={{
              width: 100
            }}
            color="primary"
            onClick={() => addTotePlacement(createNewTotePlacementTemplate())}
          >
            <Typography style={{ fontSize: 12 }}>
              {t("add tote placement")}
            </Typography>
          </ProgressButton>
        </Box>
      )}
    </Box>
  );
}
