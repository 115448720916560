import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { ProgressButton } from "frontend-components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PickingConfigurationOption } from "~/api/warehouseTypes/fulfillmentCenter";
import { useAppDispatch, useAppSelector } from "~/app/store";

import TwoColumnTable from "~/components/table/TwoColumnTable";
import envConstants from "~/config/envConstants";
import { ThisMachineInfo } from "~/features/status/ThisMachineInfo";
import { togglePTLSimulationEnabled } from "~/redux/actions/ptl";
import { toggleAutomatedOperationsEnabled } from "~/redux/actions/site";
import { updateUsersFulfillmentCenter } from "~/redux/actions/store";
import { getServerVersion } from "~/redux/actions/version";
import { setThisWorkstation, setSiteFcId } from "~/redux/actions/workstations";
import { StoreState } from "~/redux/reducers";
import { toggleConveyanceSimEnabled } from "~/redux/reducers/conveyance";
import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { selectLanguageCode } from "~/redux/selectors/siteSelectors";

import FulfillmentCenterSelector from "./FulfillmentCenterSelector";
import PrinterSettings from "./PrinterSettings";
import SettingsPanel from "./SettingsPanel";
import SyncGridStateButton from "./SyncGridStateButton";
import UserSettings from "./UserSettings";
import {
  selectEnableDevInformation,
  toggleDevInfomation
} from "./devSettings.slice";

const mapStateToProps = (state: StoreState) => ({
  selectedFulfillmentCenter: state.store.usersFulfillmentCenter,
  clientConfig: state.site.clientConfig,
  ptlSimulationEnabled: state.ptl.ptlSimulationEnabled,
  conveyanceSimEnabled: state.conveyance.conveyanceSimEnabled,
  automatedOperationsEnabled: state.site.automatedOperationsEnabled,
  serverVersion: state.version.serverVersion,
  usersName: state.login.profile?.name,
  loginPath: state.site.loginPath
});

const connector = connect(mapStateToProps, {
  updateUsersFulfillmentCenter,
  setThisWorkstation,
  setSiteFcId,
  togglePTLSimulationEnabled,
  toggleConveyanceSimEnabled,
  toggleAutomatedOperationsEnabled,
  getServerVersion
});
type SettingsInheritedProps = { viewTitle?: string };
type PropsFromRedux = ConnectedProps<typeof connector>;
type SettingsProps = PropsFromRedux & SettingsInheritedProps;

function Settings(props: SettingsProps) {
  const {
    selectedFulfillmentCenter,
    clientConfig,
    serverVersion,
    usersName,
    loginPath
  } = props;

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAdmin = useAppSelector(selectUserIsAdmin);
  const devInformationEnabled = useAppSelector(selectEnableDevInformation);
  const languageCode = useAppSelector(selectLanguageCode);

  // show and hide beamer selector upon mount and dismount
  useEffect(() => {
    const intervalId = setInterval(() => {
      const beamerSelector = document.getElementById("beamerSelector");
      if (beamerSelector) {
        clearInterval(intervalId);
        if (isAdmin) {
          beamerSelector.style.display = "block";
        }
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
      const beamerSelector = document.getElementById("beamerSelector");

      if (beamerSelector) {
        beamerSelector.style.display = "none";
      }
    };
  }, [isAdmin]);

  const {
    dev_orderCreation,
    dev_ptlSimulation,
    dev_conveyanceSimulation,
    dev_automatedOperations
  } = clientConfig;

  useEffect(() => {
    if (usersName?.includes("autostoresystem.com")) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getServerVersion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersName]);

  const isWorkstationConfigurer = useAppSelector(
    (state) => state.login.profile?.roles || []
  ).some((role) => role === "qubit-workstation-configurer");
  const areDevCheatsEnabled =
    envConstants.DEV_CHEATS_UAT === "true" ||
    envConstants.DEV_CHEATS_STAGING === "true";

  const showDevSection =
    dev_ptlSimulation ||
    dev_conveyanceSimulation ||
    dev_automatedOperations ||
    dev_orderCreation ||
    areDevCheatsEnabled;

  return (
    <Box
      id="settings-container"
      sx={{
        padding: "20px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <SyncGridStateButton />

      <Box
        id="settings-row-1"
        sx={{
          display: "flex",
          width: "100%"
        }}
      >
        <Box
          id="settings-row-1-left"
          sx={{
            width: "75%",
            boxSizing: "border-box",
            padding: "10px",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {isWorkstationConfigurer && (
            <SettingsPanel panelTitle={t("fc settings")}>
              <>
                <Box sx={{ maxWidth: "50%" }}>
                  <FulfillmentCenterSelector />
                </Box>
              </>
            </SettingsPanel>
          )}

          {isWorkstationConfigurer && (
            <SettingsPanel panelTitle={t("This Workstation")}>
              <Box sx={{ minHeight: "200px" }}>
                {selectedFulfillmentCenter?.pickingConfigurations.includes(
                  "Autostore" as PickingConfigurationOption
                ) && <ThisMachineInfo />}
              </Box>
            </SettingsPanel>
          )}
        </Box>

        <Box
          id="settings-row-1-right"
          style={{
            width: "25%",
            boxSizing: "border-box",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start"
          }}
        >
          <SettingsPanel panelTitle={t("nav.viewname.user settings")}>
            <UserSettings />
          </SettingsPanel>

          {usersName?.includes("autostoresystem.com") && (
            <SettingsPanel panelTitle={t("nav.viewname.version")}>
              <Box data-testid="version-info">
                <Typography>
                  Client Version:{" "}
                  {envConstants.VERSION_TAG || envConstants.VERSION}
                </Typography>
                <Typography>
                  Server Version: {serverVersion.tag || serverVersion.hash}
                </Typography>
              </Box>
            </SettingsPanel>
          )}
        </Box>
      </Box>

      <Box
        id="settings-row-2"
        sx={{
          width: "100%",
          display: "flex"
        }}
      >
        {isWorkstationConfigurer &&
          selectedFulfillmentCenter?.printerConfiguration ===
            "BrowserPrint" && (
            <Box
              id="settings-row-2-left"
              sx={{
                width: "50%",
                boxSizing: "border-box",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start"
              }}
            >
              <SettingsPanel panelTitle={t("nav.viewname.printer")}>
                <PrinterSettings />
              </SettingsPanel>
            </Box>
          )}

        {/* dev section */}
        {showDevSection && (
          <Box
            id="settings-row-2-right"
            sx={{
              width: "50%",
              boxSizing: "border-box",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start"
            }}
          >
            <SettingsPanel panelTitle={t("nav.viewname.dev")}>
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  item
                  direction="column"
                  spacing={2}
                  style={{ margin: 0 }}
                >
                  <Grid item>
                    <ProgressButton
                      variant="outlined"
                      color="primary"
                      onClick={() => navigate("/order-creation")}
                    >
                      {t("order creation")}
                    </ProgressButton>
                  </Grid>
                  {areDevCheatsEnabled && isAdmin && (
                    <Grid item data-testid="dev-information-enabled">
                      <InputLabel htmlFor="dev-info-enabled">
                        {t("dev information enabled")}
                      </InputLabel>
                      <Switch
                        checked={devInformationEnabled}
                        onChange={(e) =>
                          dispatch(
                            toggleDevInfomation(e.target.checked || false)
                          )
                        }
                      />
                    </Grid>
                  )}
                  {(envConstants.ENABLE_PTL_SIMULATION === "true" ||
                    dev_ptlSimulation) && (
                    <Grid item>
                      <InputLabel htmlFor="ptl-simulation-enabled">
                        {t("ptl simulation enabled")}
                      </InputLabel>
                      <Switch
                        id="ptl-simulation-enabled"
                        checked={props.ptlSimulationEnabled}
                        onChange={props.togglePTLSimulationEnabled}
                      />
                    </Grid>
                  )}
                  {(envConstants.ENABLE_CONVEYANCE_SIMULATION === "true" ||
                    dev_conveyanceSimulation) && (
                    <Grid item>
                      <InputLabel htmlFor="conveyance-simulation-enabled">
                        {t("conveyance simulation enabled")}
                      </InputLabel>
                      <Switch
                        id="conveyance-simulation-enabled"
                        checked={props.conveyanceSimEnabled}
                        onChange={() => props.toggleConveyanceSimEnabled()}
                      />
                    </Grid>
                  )}
                  {(envConstants.ENABLE_AUTOMATED_OPERATIONS ||
                    dev_automatedOperations) && (
                    <Grid item>
                      <InputLabel htmlFor="automated-picking-enabled">
                        {t("automated operations enabled")}
                      </InputLabel>
                      <Switch
                        id="automated-picking-enabled"
                        checked={props.automatedOperationsEnabled}
                        onChange={props.toggleAutomatedOperationsEnabled}
                      />
                    </Grid>
                  )}
                  {/* Enable i18next cimode to show only translation keys */}
                  <Grid item>
                    <InputLabel htmlFor="show-translation-keys">
                      {t("show translation keys")}
                    </InputLabel>
                    <Switch
                      id="show-translation-keys"
                      checked={i18n.language === "cimode"}
                      onChange={() => {
                        const newLanguage =
                          i18n.language !== "cimode" ? "cimode" : languageCode;
                        void i18n.changeLanguage(newLanguage);
                      }}
                    />
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    backgroundColor: "mediumGray.main",
                    padding: "10px",
                    width: "80%",
                    boxSizing: "border-box",
                    mt: 2
                  }}
                >
                  <TwoColumnTable
                    firstColumnWidth="30%"
                    tableData={[{ left: "loginPath", right: loginPath }]}
                  />
                </Box>
              </Box>
            </SettingsPanel>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default connector(Settings);
