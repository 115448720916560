import {
  AdjustInventoryNetRequest,
  GetInventorySummariesResponse,
  MeasuredValueDto,
  InventoryDto,
  VariantInventorySummaryResponse,
  RemoveInventoryHoldRequest,
  InventoryWithVariantsResponse,
  InventoryQuery,
  InventoryMovementResponse
} from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const inventoryApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getVariantInventorySummaries: build.query<
      VariantInventorySummaryResponse,
      void
    >({
      query: () => ({
        url: `/inventory/variant-inventory-summary`
      }),
      providesTags: ["variant inventory summaries"]
    }),
    getInventoryByAutostoreBinNumber: build.query<
      InventoryDto[],
      { autostoreGridId: Guid; binNumber: number }
    >({
      query: ({ autostoreGridId, binNumber }) => ({
        url: `/inventory/grid/${autostoreGridId}/by-autostore-bin-number/${binNumber}`
      }),
      providesTags: ["inventory by autostore bin number"]
    }),
    getInventoryWithVariantInfoByAutostoreBinNumber: build.query<
      InventoryWithVariantsResponse[],
      { autostoreGridId: Guid; binNumber: number }
    >({
      query: ({ autostoreGridId, binNumber }) => ({
        url: `/inventory/grid/${autostoreGridId}/by-autostore-bin-number-with-variant-and-product/${binNumber}`
      }),
      providesTags: ["inventory by autostore bin number"]
    }),
    getInventorySummaryList: build.query<
      GetInventorySummariesResponse,
      { inventoryIds: Guid[] | undefined; offset: number; limit: number }
    >({
      query: ({ inventoryIds, offset, limit }) => ({
        url: `/inventory/summaries-by-ids`,
        params: {
          InventoryIds: inventoryIds,
          Offset: offset,
          Limit: limit
        }
      }),
      providesTags: ["inventory summary list"]
    }),
    patchInventoryNetAdjustment: build.mutation<
      void,
      AdjustInventoryNetRequest & {
        inventoryId: Guid;
      }
    >({
      query: ({ inventoryId, ...rest }) => ({
        url: `/inventory/net/${inventoryId}`,
        method: "PATCH",
        data: {
          ...rest
        }
      })
    }),
    postInventoryMove: build.mutation<
      void,
      { inventoryId: Guid; targetBinId: string; quantity: MeasuredValueDto }
    >({
      query: ({ inventoryId, targetBinId, quantity }) => ({
        url: `/inventory/${inventoryId}/move`,
        method: "POST",
        data: {
          targetBinId,
          count: { Value: quantity.value, Units: quantity.units }
        }
      }),
      invalidatesTags: ["inventory"]
    }),
    postFlagBinInventoryMovements: build.mutation<
      InventoryMovementResponse,
      {
        autostoreGridId: Guid;
        workstationId: Guid;
        reason: string;
      }
    >({
      query: ({ autostoreGridId, workstationId, reason }) => {
        const request = {
          GridId: autostoreGridId,
          WorkstationId: workstationId,
          Reason: reason
        };

        return {
          url: `/inventory/movement/flag`,
          method: "POST",
          data: request
        };
      },
      invalidatesTags: ["flagged bins"]
    }),
    getInventorySummaries: build.query<
      GetInventorySummariesResponse,
      InventoryQuery
    >({
      query: (params) => {
        return {
          url: `/inventory`,
          params: { ...params, limit: params.limit || 100 }
        };
      }
    }),
    getInventoryById: build.query<InventoryDto, { inventoryId: Guid }>({
      query: ({ inventoryId }) => ({
        url: `/inventory/${inventoryId}`,
        method: "GET"
      }),
      providesTags: ["inventory"]
    }),
    deleteInventoryHold: build.mutation<
      void,
      RemoveInventoryHoldRequest & { inventoryId: Guid }
    >({
      query: ({ inventoryId, ...rest }) => ({
        url: `/inventory/${inventoryId}/hold`,
        method: "DELETE",
        data: rest
      }),
      invalidatesTags: ["inventory", "inventory by autostore bin number"]
    }),
    postPutawayBinNotEmpty: build.mutation<
      void,
      {
        productIdentifier: string;
        binId?: Guid | null;
        portId: number;
        gridId: Guid;
        workstationId: Guid;
      }
    >({
      query: ({ productIdentifier, binId, portId, gridId, workstationId }) => ({
        url: `/inventory/put-away/bin-not-empty`,
        method: "POST",
        data: { productIdentifier, binId, portId, gridId, workstationId }
      })
    })
  }),

  overrideExisting: false
});
