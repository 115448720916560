// this file is likely garbage and should be removed

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useMediaQuery, useTheme, Box, Typography } from "@mui/material";
import { mobileWidth } from "frontend-components";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { useAppDispatch, useAppSelector } from "~/app/store";
import AutostoreTable from "~/components/autostore/table/AutostoreTable";

import { formatUtcDate } from "~/lib/dateHelpers";
import { generateLocationNameFromBin, formatHoldText } from "~/lib/helpers";
import { StoreState } from "~/redux/reducers";
import { InventoryDto, CycleCountV1Dto } from "~/types/api";

import { selectCycleCountBinId, selectInventory } from "./cycleCounts.slice";

const mapStateToProps = (
  state: StoreState
): {
  cycleCount: CycleCountV1Dto | null;
  cycleCountInventoryRecords: InventoryDto[];
  loadingCycleCountBins: boolean;
} => ({
  cycleCount: state.cycleCounts.cycleCount,
  cycleCountInventoryRecords: state.cycleCounts.cycleCountInventoryRecords,
  loadingCycleCountBins: state.cycleCounts.loadingCycleCountBins
});

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export type CycleCountBinTableProps = PropsFromRedux & {
  binFilter?: number;
};

export function CycleCountBinTable(props: CycleCountBinTableProps) {
  const {
    cycleCount,
    cycleCountInventoryRecords,
    loadingCycleCountBins,
    binFilter
  } = props;
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const { t } = useTranslation();

  const isMobile = useMediaQuery(mobileWidth);

  const selectedCycleCountBinId = useAppSelector(
    (state) => state.cycleCountsSlice.selectedCycleCountBinId
  );

  const cycleCountsBinsToDisplay = binFilter
    ? cycleCountInventoryRecords.filter(
        (cycleCountBin) =>
          cycleCountBin.bin.autostoreBin?.autostoreBinId === binFilter
      )
    : cycleCountInventoryRecords;

  const isCycleCountBinVerified = ({
    cycleCountBin,
    currentCycleCount
  }: {
    cycleCountBin: InventoryDto | null;
    currentCycleCount: CycleCountV1Dto | null;
  }) => {
    if (!cycleCountBin || !cycleCountBin.count || !currentCycleCount)
      return false;

    const matchingBinCount = currentCycleCount?.binCounts.find(
      (binCount) => binCount.inventoryId === cycleCountBin.inventoryId
    );

    return (
      matchingBinCount &&
      matchingBinCount.count.value === cycleCountBin.count?.value
    );
  };

  return (
    <AutostoreTable<InventoryDto>
      widthOfCols={["20%", "20%", "20%", "20%", "20%"]}
      headerColNames={[
        t("Bin"),
        isMobile ? t("qty") : t("quantity"),
        t("expiration_abbr"),
        t("holds"),
        t("verified")
      ]}
      rowId={(row: InventoryDto) => `${row.inventoryId}`}
      renderColumns={[
        (row: InventoryDto) => {
          const location = generateLocationNameFromBin(row.bin);

          return location;
        },
        (row: InventoryDto) => {
          return row.count?.value.toString() || t("n/a");
        },
        (row: InventoryDto) => {
          return formatUtcDate(row.expiration);
        },
        (row: InventoryDto) => {
          return (
            <Box>
              {row?.holds.map((hold, i) => (
                <span key={hold.reasonCode.toLocaleLowerCase()}>
                  <Typography>
                    {formatHoldText(hold)}
                    {i !== row.holds.length - 1 && ", "}
                  </Typography>
                </span>
              ))}
            </Box>
          );
        },
        (row: InventoryDto) => {
          const isVerified = isCycleCountBinVerified({
            cycleCountBin: row,
            currentCycleCount: cycleCount
          });

          return (
            <Box>
              {isVerified && (
                <CheckCircleIcon style={{ color: palette.success.dark }} />
              )}
            </Box>
          );
        }
      ]}
      rowData={cycleCountsBinsToDisplay}
      selectedRows={
        selectedCycleCountBinId ? [selectedCycleCountBinId] : undefined
      }
      selectRowCallback={(row: InventoryDto) => {
        if (
          selectedCycleCountBinId &&
          selectedCycleCountBinId === row.inventoryId
        ) {
          dispatch(selectCycleCountBinId(null));
        } else {
          dispatch(selectCycleCountBinId(row.inventoryId));
          dispatch(selectInventory(row));
        }
      }}
      loading={loadingCycleCountBins}
      noResults={
        !loadingCycleCountBins &&
        cycleCountsBinsToDisplay &&
        cycleCountsBinsToDisplay.length === 0
      }
    />
  );
}

export default connector(CycleCountBinTable);
