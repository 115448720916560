import { QuestionMark } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import { CardMediaWithFallback } from "~/components/CardMediaWithFallback";
import { useVariantBySkuQuery } from "~/redux/warehouse/variant.hooks";

export const ProductInfo = ({ sku }: { sku: string }) => {
  const { data: variant, isLoading: variantIsLoading } =
    useVariantBySkuQuery(sku);

  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      flexBasis="300px"
      flexShrink={0.5}
    >
      {!variantIsLoading && variant?.imageFilename ? (
        <CardMediaWithFallback
          sx={{
            height: "50px",
            flexBasis: "75px",
            flexShrink: 0,
            backgroundSize: "contain"
          }}
          image={variant.imageFilename}
        />
      ) : (
        <Stack
          height="50px"
          flexBasis="75px"
          flexShrink={0}
          alignItems="center"
          justifyContent="center"
        >
          <QuestionMark />
        </Stack>
      )}
      <Stack direction="column">
        <Typography>{variant?.productName}</Typography>
        <Typography>Units: {variant?.realUnits}</Typography>
        <Typography>SKU: {sku}</Typography>
      </Stack>
    </Stack>
  );
};
