import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  PaperProps,
  Stack,
  Typography
} from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";

import { useAppSelector } from "~/app/store";
import alarm from "~/assets/alarm.mp3";
import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useCloseWorkstationMutation } from "~/redux/warehouse/workstation.hooks";

type Props = {
  open: boolean;
  timeLeft?: number;
  pageSpecificReset?: () => Promise<void> | void;
  resetTimerCallback: () => void;
  soundEnabled?: boolean;
};

function PaperComponent(props: PaperProps) {
  const draggableRef = useRef(null);
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={draggableRef}
    >
      <Paper ref={draggableRef} {...props} />
    </Draggable>
  );
}

export const AutostoreInactivityTimerModal = (props: Props) => {
  const {
    open,
    timeLeft,
    pageSpecificReset,
    resetTimerCallback,
    soundEnabled
  } = props;

  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const [closeWorkstation] = useCloseWorkstationMutation();
  const { successToast, errorToast } = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const closeWorkstationCallback = useCallback(
    async (logout?: boolean) => {
      try {
        if (siteWorkstation) {
          await closeWorkstation({
            autostoreGridId: siteWorkstation.autostoreGridId,
            workstationId: siteWorkstation.id
          }).unwrap();
          successToast(t("workstation closed"));
        }
      } catch (err) {
        errorToast(getMessageFromRtkError(err));
      }

      try {
        if (pageSpecificReset) await pageSpecificReset();
      } catch {
        // don't stop page specific reset to prevent logout
      }

      if (logout) navigate("/logout");
      else navigate("/autostore-main");
    },
    [
      closeWorkstation,
      errorToast,
      pageSpecificReset,
      navigate,
      siteWorkstation,
      successToast,
      t
    ]
  );

  const [play, { stop }] = useSound(alarm as string, {
    interrupt: true,
    soundEnabled: true,
    playbackRate: 2
  });

  const soundInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (soundEnabled) {
      soundInterval.current = setInterval(() => {
        play();
      }, 3200);
    }
  }, [play, soundEnabled]);

  useEffect(() => () => {
    if (soundEnabled) {
      stop();
      if (soundInterval.current) {
        clearInterval(soundInterval.current);
      }
    }
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetTimerCallback();
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="md"
      sx={{
        fontFamily: "Inter"
      }}
    >
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        variant="h5"
      >
        <Stack
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="center"
          gap={2}
        >
          <WarningAmberIcon
            fontSize="large"
            sx={{
              color: "warning.main"
            }}
          />
          <Typography variant="h5">
            {t("workstation auto-close warning")}
          </Typography>
          <WarningAmberIcon
            fontSize="large"
            sx={{
              color: "warning.main"
            }}
          />
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          mt: 2
        }}
      >
        <Typography variant="h5" sx={{ color: "darkGray.light" }}>
          {t("workstation will automatically close in")}
        </Typography>
        <CountdownCircleTimer
          isPlaying
          duration={timeLeft || 0}
          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
          colorsTime={[10, 7, 4, 0]}
          onComplete={() => {
            void closeWorkstationCallback(true);
          }}
        >
          {({ remainingTime }) => (
            <Typography
              variant="h3"
              sx={{
                color: "darkGray.light"
              }}
            >
              {remainingTime}
            </Typography>
          )}
        </CountdownCircleTimer>
        <Stack
          flexDirection="row"
          alignSelf="stretch"
          justifyContent="space-around"
        >
          <Button
            variant="contained"
            startIcon={<DeleteIcon sx={{ color: "#000" }} />}
            sx={{
              backgroundColor: "darkGray.light",
              height: "50px",
              width: "180px",
              "&:hover": {
                backgroundColor: "darkGray.light"
              }
            }}
            onClick={() => {
              void closeWorkstationCallback();
            }}
          >
            <Typography style={{ color: "gray.dark" }}>
              {t("close workstation")}
            </Typography>
          </Button>
          <Button
            variant="contained"
            startIcon={<RestartAltIcon />}
            sx={{
              backgroundColor: "warning.main",
              color: "#000",
              height: "50px",
              width: "180px",
              "&:hover": {
                backgroundColor: "warning.dark"
              }
            }}
            onClick={resetTimerCallback}
          >
            <Typography>{t("reset timer")}</Typography>
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
