import { ProgressButton } from "frontend-components";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import ChangeSuggestedBinModal from "~/features/autostorePutaway/modals/ChangeSuggestedBinModal";

import { useToast } from "~/hooks/useToast";
import { isWorkstationWithSinglePort } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";

import { useStartInductionMutation } from "~/redux/warehouse/workstation.hooks";
import { NextEmptyBinResponse } from "~/types/api";

import {
  resetBinAtPortSeconds,
  setIsAutostorePutawayDialogOpen,
  setIsChangeBinConfigModalOpen,
  setIsGetBinsLoading,
  setSelectedCompartment
} from "./inventory.slice";
import { useNextEmptyBin } from "./useNextEmptyBin";

type Props = {
  handleFetchAllInventory: () => Promise<void>;
};

export function CreateInventoryButton({ handleFetchAllInventory }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const nextEmptyBin = useNextEmptyBin();

  const [startInduction] = useStartInductionMutation();

  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const isChangeBinConfigModalOpen = useAppSelector(
    (state) => state.inventoryNew.isChangeBinConfigModalOpen
  );
  const isGetBinsLoading = useAppSelector(
    (state) => state.inventoryNew.isGetBinsLoading
  );
  const nextEmptyBinByPort = useAppSelector(
    (state) => state.autostore.nextEmptyBinByPort
  );
  const portStateByPort = useAppSelector(
    (state) => state.autostore.portStateByPort
  );
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);

  const confirmBinCompartment = useCallback(
    (bin: NextEmptyBinResponse, compartmentNumber: number) => {
      dispatch(setSelectedCompartment({ bin, compartmentNumber }));
      dispatch(setIsAutostorePutawayDialogOpen(true));
    },
    [dispatch]
  );

  const handleCreateProductInventory = async () => {
    if (!autostoreGridId || !siteWorkstation) return;

    dispatch(setIsGetBinsLoading(true));
    // start-induction and call get empty bins for each port
    try {
      await startInduction({
        autostoreGridId: autostoreGridId,
        workstationId: siteWorkstation.id
      }).unwrap();
      await Promise.all(
        siteWorkstation.ports.map((port) => nextEmptyBin(port.portId))
      );
      // jk - I traced this down and it does not actually open the dialog
      // delete me
      dispatch(setIsAutostorePutawayDialogOpen(true));
      dispatch(setIsGetBinsLoading(false));
    } catch (err) {
      errorToast(
        `Error encountered when opening ports: ${getMessageFromRtkError(err)}`
      );
    }
  };

  useEffect(() => {
    // if there's only one port and its a whole bin, automatically confirm bin and open putaway dialog
    const firstPortEmptyBin =
      siteWorkstation && nextEmptyBinByPort[siteWorkstation?.ports[0].portId];
    const allEmptyBins = Object.values(nextEmptyBinByPort);
    const shouldSkipCompartmentSelectModal =
      firstPortEmptyBin &&
      isWorkstationWithSinglePort(siteWorkstation) &&
      firstPortEmptyBin?.autostoreBinCompartments.length === 1;
    // empty bins are on the way, and there are multiple or at least multiple compartments
    const shouldOpenCompartmentSelectModal =
      firstPortEmptyBin &&
      allEmptyBins.length &&
      (allEmptyBins.length > 1 ||
        firstPortEmptyBin?.autostoreBinCompartments.length > 1);
    if (shouldSkipCompartmentSelectModal) {
      confirmBinCompartment(firstPortEmptyBin, 0);
    } else if (shouldOpenCompartmentSelectModal) {
      // else, if there's multiple ports, open the change bin config modal to allow user to select compartment
      dispatch(setIsChangeBinConfigModalOpen(true));
    }
  }, [
    siteWorkstation,
    nextEmptyBinByPort,
    dispatch,
    autostoreGridId,
    confirmBinCompartment
  ]);

  return (
    <>
      <ProgressButton
        variant="contained"
        color="primary"
        size="large"
        onClick={handleCreateProductInventory}
        disabled={!siteWorkstation}
      >
        {t("create inventory")}
      </ProgressButton>
      <ChangeSuggestedBinModal
        isOpen={isChangeBinConfigModalOpen}
        onClose={() => {
          dispatch(setIsChangeBinConfigModalOpen(false));
        }}
        onCancel={async () => {
          dispatch(setIsAutostorePutawayDialogOpen(false));
          dispatch(resetBinAtPortSeconds());
          dispatch(setIsChangeBinConfigModalOpen(false));
          await handleFetchAllInventory();
        }}
        cancelDisabled={isGetBinsLoading}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        workstation={siteWorkstation}
        onConfirm={(bin: NextEmptyBinResponse, compartmentNumber: number) => {
          confirmBinCompartment(bin, compartmentNumber);
        }}
        currentSelectedBin={null}
        currentSelectedCompartment={null}
        showInitialLoading={isGetBinsLoading}
      />
    </>
  );
}
