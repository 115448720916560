import { Button, Toolbar, Typography, useMediaQuery } from "@mui/material";

import { autostoreTheme } from "frontend-components";
import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import AutostoreLoadingIcon from "~/components/AutostoreLoadingIcon";
import { NavBarButtonGroup, UnselectButton } from "~/components/navbar/Navbar";
import { MoveInventoryButton } from "~/features/inventory/MoveInventoryButton";

import { useCloseWorkstationWithErrorToast } from "~/hooks/useCloseWorkstationWithErrorToast";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";
import { isAutostoreView } from "~/lib/helpers";

import {
  binNotAtPort,
  closePort,
  createBinRequest,
  fetchPortStatus,
  getNextBin,
  GetPortResponse,
  openPort
} from "~/redux/actions";

import {
  clearSelectedInventoryId,
  clearSelectedVariant,
  getInventoryReport
} from "~/redux/actions/inventory";

import {
  selectInventorySummaryToDisplay,
  selectSelectedInventoryAtPort
} from "~/redux/selectors/inventoryOldSelectors";
import {
  selectSelectedPortId,
  selectThisWorkstation
} from "~/redux/selectors/workstationsSelectors";
import { InventorySummaryDto } from "~/types/api";

import {
  setIsAddPanelOpen,
  setIsAdjustingBins,
  setIsAdjustPanelOpen,
  setIsBinComponentShown,
  setIsBinHoldModalOpen,
  setIsFetchingBin,
  setPortOpened,
  setPortPollingActive
} from "./inventoryOld.slice";

import { getViewType } from "./viewType";

type Props = {
  handleCloseBin: () => void;
  handleExitGetBins: () => Promise<void>;
  handleGetEmptyBinClick: () => Promise<void>;
  handleGetInventorySummaries: (args: {
    variantId?: Guid;
    binId?: Guid;
    offsetZero?: boolean;
  }) => void;
  handleGetNextBin: () => Promise<void>;
  resetPage: () => void;
};

export function InventoryOldToolbar({
  handleCloseBin,
  handleGetEmptyBinClick,
  handleGetInventorySummaries,
  handleGetNextBin,
  handleExitGetBins,
  resetPage
}: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locationInfo = useLocation();
  const { pathname, search } = locationInfo;
  const { variantId: variantIdParam, binId: binIdParam } = useParams<{
    variantId?: string;
    binId?: string;
  }>();

  const closeWorkstation = useCloseWorkstationWithErrorToast();

  const shouldListenToGridEvents = useShouldListenToGridEvents();
  const isMobile = useMediaQuery(autostoreTheme.breakpoints.down("sm"));

  const binAtPort = useAppSelector((state) => state.autostore.binAtPort);

  const getBinsIndex = useAppSelector(
    (state) => state.inventoryOld.getBinsIndex
  );
  const isAdjustingBins = useAppSelector(
    (state) => state.inventoryOld.isAdjustingBins
  );
  const isBinComponentShown = useAppSelector(
    (state) => state.inventoryOld.isBinComponentShown
  );
  const isFetchingBin = useAppSelector(
    (state) => state.inventoryOld.isFetchingBin
  );
  const inventorySummaryToDisplay = useAppSelector(
    selectInventorySummaryToDisplay
  );
  const pickingConfigurations = useAppSelector(
    (state) => state.store.usersFulfillmentCenter?.pickingConfigurations || []
  );
  const portState = useAppSelector((state) => state.autostore.portState);
  const workstation = useAppSelector(selectThisWorkstation);
  const selectedInventoryAtPort = useAppSelector(selectSelectedInventoryAtPort);
  const selectedPortId = useAppSelector(selectSelectedPortId);
  const selectedRows = useAppSelector(
    (state) => state.inventoryOld.selectedRows
  );
  const selectedSummaries = useAppSelector(
    (state) => state.inventoryOld.selectedSummaries
  );
  const selectedVariant = useAppSelector(
    (state) => state.inventory.selectedVariant
  );

  const [getBinsMaxIndex, setGetBinsMaxIndex] = useState(1);

  const binIds = selectedSummaries.reduce((acc: number[], summary) => {
    if (summary?.autostoreBinNumber) acc.push(summary.autostoreBinNumber);
    return acc;
  }, []);
  const isAnySelect: boolean = selectedRows.length >= 1;
  const isSingleSelect: boolean = selectedRows.length === 1;
  const singleSelectSummary: InventorySummaryDto | null =
    (isSingleSelect && inventorySummaryToDisplay) || null;

  const invSumToModify = isAdjustingBins
    ? selectedInventoryAtPort
    : inventorySummaryToDisplay;

  const allSelectedBinTypeAreAutostore = selectedSummaries.every(
    (summary) => summary?.binType === "autostore"
  );

  const viewType = getViewType(pathname);
  const isBinView = viewType === "bin";
  const isProductView = viewType === "product";
  const isProductsView = viewType === "products";

  const includesManualOperation = pickingConfigurations.includes("Manual");

  // if moving from an Autostore bin, require bin to be at port before enabling "Move" button
  const canMoveInventory =
    singleSelectSummary?.binType === "autostore"
      ? includesManualOperation &&
        portState?.isReady &&
        portState?.selectedBin === selectedInventoryAtPort?.autostoreBinNumber
      : includesManualOperation;

  const handleSearchSelected = () => {
    if (["bin", "products"].includes(viewType) && selectedVariant) {
      navigate({
        pathname: `/inventory/product/${selectedVariant.variantId}`,
        search
      });
    } else if (isProductView && singleSelectSummary) {
      navigate({
        pathname: `/inventory/bin/${singleSelectSummary.binId}`,
        search
      });
    }
  };

  const handleGetBins = async () => {
    if (!!workstation && !!selectedPortId) {
      if (!isFetchingBin) {
        dispatch(setIsFetchingBin(true));
      }
      if (!isBinComponentShown) {
        dispatch(setIsBinComponentShown(true));
      }

      await closeWorkstation();
      dispatch(setPortOpened(false));
      // create task group with inventory-holds category
      const categoryId = 6000 + selectedPortId;
      await dispatch(createBinRequest({ binIds, categoryId }));

      if (!shouldListenToGridEvents) {
        dispatch(setPortPollingActive(true));
      } else {
        dispatch(binNotAtPort());
      }

      // open port with category used above to create bin request
      await dispatch(openPort({ categoryId }));
      dispatch(setPortOpened(true));
      await dispatch(getNextBin({ shouldSoftFail: true }));

      await dispatch(fetchPortStatus()).then((data: GetPortResponse | void) => {
        if (!data) return;

        const portIsOpen = data.mode.toLowerCase().includes("open");
        const portIsReady = data.isReady;

        // the right bin is here and open.  Enable the adjust button.
        if (portIsOpen && portIsReady) {
          if (shouldListenToGridEvents && !binAtPort) return;
          dispatch(setIsFetchingBin(false));
        }
      });
    }
  };

  return (
    <Toolbar sx={{ pl: isMobile ? 3 : 2 }}>
      {isAnySelect && !isAdjustingBins && (
        <>
          <UnselectButton
            onClick={async () => {
              resetPage();
              if (!shouldListenToGridEvents) {
                dispatch(setPortPollingActive(false));
              }
              dispatch(setIsFetchingBin(false));
              if (workstation?.autostoreGridId) {
                await dispatch(closePort());
                handleCloseBin();
              }
            }}
          />
          <NavBarButtonGroup>
            {/* is manual ops || is not fetching : create inventory (1 item) */}
            {!isAdjustingBins && isSingleSelect && (
              <Button
                color="secondary"
                onClick={() => {
                  if (isAutostoreView(search)) {
                    if (!isBinComponentShown) {
                      dispatch(setIsBinComponentShown(true));
                    }
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                    handleGetEmptyBinClick();
                  }
                  dispatch(setIsAddPanelOpen(true));
                }}
                sx={{
                  flexBasis: "auto",
                  width: "175px"
                }}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  {t("create inventory")}
                </Typography>
              </Button>
            )}
            {/* is selected && not manual ops && not fetching && length > 1 : adjust bin */}
            {isAnySelect &&
              allSelectedBinTypeAreAutostore &&
              isAutostoreView(search) &&
              !isFetchingBin && (
                <Button
                  color="secondary"
                  onClick={() => {
                    if (!isBinComponentShown) {
                      dispatch(setIsBinComponentShown(true));
                    }
                    dispatch(setIsAdjustingBins(true));
                    setGetBinsMaxIndex(selectedRows.length - 1);
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                    handleGetBins();
                  }}
                  sx={{
                    flexBasis: "auto",
                    width: "175px"
                  }}
                >
                  <Typography variant="body2" style={{ color: "#fff" }}>
                    {isSingleSelect
                      ? t("get bin")
                      : `${t("get bins")} (${selectedRows.length})`}
                  </Typography>
                </Button>
              )}{" "}
            {/* is selected && not manual ops && is fetching : loading button instead of previous button */}
            {isAnySelect &&
              allSelectedBinTypeAreAutostore &&
              isAutostoreView(search) &&
              isFetchingBin && (
                <Button
                  style={{
                    width: 175
                  }}
                >
                  <AutostoreLoadingIcon />
                </Button>
              )}
            {/* is selected && manual ops : adjust product directly. */}
            {isSingleSelect && singleSelectSummary?.binType !== "autostore" && (
              <Button
                color="primary"
                id="clear-batch-id"
                onClick={(): void => {
                  dispatch(setIsAdjustPanelOpen(true));
                }}
                sx={{
                  flexBasis: "auto",
                  width: "175px"
                }}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  {t("adjust")}
                </Typography>
              </Button>
            )}
            {/* is selected && can move inventory : move */}
            {isSingleSelect && canMoveInventory && invSumToModify && (
              <MoveInventoryButton
                invToModify={invSumToModify}
                refreshCb={async ({ inventoryWasEmptied }) => {
                  if (isProductView) {
                    handleGetInventorySummaries({
                      variantId: variantIdParam
                    });
                    dispatch(clearSelectedInventoryId());
                  }
                  if (isBinView) {
                    handleGetInventorySummaries({
                      binId: binIdParam
                    });
                  }

                  if (isProductsView) {
                    handleGetInventorySummaries({});
                  }

                  if (inventoryWasEmptied && isBinView) {
                    dispatch(clearSelectedVariant());
                  }

                  if (selectedVariant) {
                    await dispatch(
                      getInventoryReport(selectedVariant.variantId)
                    );
                  }
                }}
              />
            )}
            {/* is selected : search product/bin */}
            {isSingleSelect && (
              <Button
                onClick={(): void => {
                  handleSearchSelected();
                }}
                style={{
                  flexBasis: "auto",
                  width: "175px"
                }}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  {`${t("search")} ${
                    isBinView || isProductsView ? t("product") : t("bin")
                  }`}
                </Typography>
              </Button>
            )}
            {isAnySelect && (
              <Button
                id="add-holds-button"
                color="secondary"
                onClick={() => dispatch(setIsBinHoldModalOpen(true))}
                sx={{
                  flexBasis: "auto",
                  width: "175px"
                }}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  {t("nav.link.inventory holds")}
                </Typography>
              </Button>
            )}
          </NavBarButtonGroup>
        </>
      )}
      {isAdjustingBins && (
        <NavBarButtonGroup>
          <Button
            id="adjust-button"
            color="secondary"
            onClick={() => dispatch(setIsAdjustPanelOpen(true))}
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {`${t("adjust")}`}
            </Typography>
          </Button>
          <Button
            id="remove-holds-button"
            color="secondary"
            onClick={() => dispatch(setIsBinHoldModalOpen(true))}
            sx={{
              flexBasis: "auto",
              width: "175px"
            }}
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {t("nav.link.inventory holds")}
            </Typography>
          </Button>
          <Button
            id="close-port-button"
            color="secondary"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
              handleExitGetBins();
              resetPage();
            }}
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {`${t("close port")}`}
            </Typography>
          </Button>
          {getBinsIndex < getBinsMaxIndex && !isFetchingBin && (
            <Button
              id="next-bin-button"
              color="secondary"
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                handleGetNextBin();
              }}
            >
              <Typography variant="body2" style={{ color: "#fff" }}>
                {t("next bin")}
              </Typography>
            </Button>
          )}
        </NavBarButtonGroup>
      )}
    </Toolbar>
  );
}
