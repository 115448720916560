import { Box, Typography, ButtonGroup, Button, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { ProgressButton } from "frontend-components";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { SplitButton } from "~/components/SplitButton";
import { buildBatchStatusFilterAdmin } from "~/lib/helpers";
import { selectStatusSelectedAutostoreGridId } from "~/redux/selectors/statusSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import {
  useGetAutostoreGridsQuery,
  useGetAutostoreTasksQuery
} from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetBatchesQuery } from "~/redux/warehouse/batches.hooks";
import { AutostoreTaskGroupInfoExtended } from "~/types/api";

import { TaskGroupTableByBatch } from "./TaskGroupTableByBatch";
import { TaskGroupTableByBin } from "./TaskGroupTableByBin";
import { TaskGroupTableByTaskGroup } from "./TaskGroupTableByTaskGroup";
import { setSelectedAutostoreGridId } from "./status.slice";

interface NewTaskGroups {
  taskGroupId: number;
  batchName?: string;
  batchStatus?: string;
  selectedPortId?: number;
  tasks: number[];
}

export interface ShapedTasksByBinIds {
  binId: number;
  newTaskGroups: NewTaskGroups[];
  taskGroups: AutostoreTaskGroupInfoExtended[];
  tasks: {
    taskId: number;
    taskGroup: AutostoreTaskGroupInfoExtended;
  }[];
}

export const TaskAssessment = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const selectedFulfillmentCenter = useAppSelector(
    selectUsersFulfillmentCenter
  );
  const selectedAutostoreGridId = useAppSelector(
    selectStatusSelectedAutostoreGridId
  );
  const workstationAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );

  const { data: batchesResponse, refetch: refetchBatches } = useGetBatchesQuery(
    (selectedFulfillmentCenter?.pickingConfigurations || []).includes(
      "Autostore"
    )
      ? {
          limit: 1000,
          status: buildBatchStatusFilterAdmin(selectedFulfillmentCenter)
        }
      : skipToken
  );
  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  const { data: autostoreTasks, refetch: refetchAutostoreTasks } =
    useGetAutostoreTasksQuery(selectedAutostoreGridId || skipToken);

  const [taskGroupViewType, setTaskGroupViewType] = useState("taskGroup");

  useEffect(() => {
    if (!selectedAutostoreGridId && autostoreGrids?.length) {
      dispatch(
        setSelectedAutostoreGridId(
          workstationAutostoreGridId || autostoreGrids[0].autostoreGridId
        )
      );
    }
  }, [
    autostoreGrids,
    workstationAutostoreGridId,
    selectedAutostoreGridId,
    dispatch
  ]);

  useEffect(() => {
    const search: string = location.search.substring(1);
    if (!search) {
      setTaskGroupViewType("taskGroup");
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedSearch: Record<string, unknown> = JSON.parse(
      `{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
      (key: string, value: string) =>
        key === "" ? value : decodeURIComponent(value)
    );

    if (parsedSearch.taskGroupViewBy) {
      setTaskGroupViewType(parsedSearch.taskGroupViewBy as string);
    }
  }, [location]);

  const shapedTasksByBinIds: ShapedTasksByBinIds[] = (autostoreTasks || [])
    .map((taskGroup) =>
      taskGroup.tasks.map((task) => ({
        ...task,
        taskGroup
      }))
    )
    .flat()
    .reduce<ShapedTasksByBinIds[]>(
      (acc: ShapedTasksByBinIds[], el): ShapedTasksByBinIds[] => {
        const matchingBinId = !!acc.find(
          (binInfo) => binInfo.binId === el.binId
        );

        return matchingBinId
          ? acc.map((binInfo) =>
              el.binId === binInfo.binId
                ? {
                    ...binInfo,
                    taskGroups: [...binInfo.taskGroups, el.taskGroup],
                    tasks: [
                      ...binInfo.tasks,
                      { taskId: el.taskId, taskGroup: el.taskGroup }
                    ],
                    newTaskGroups: binInfo.taskGroups.find(
                      (taskGroup) =>
                        taskGroup.taskGroupId === el.taskGroup.taskGroupId
                    )
                      ? binInfo.newTaskGroups.map((newTaskGroup) =>
                          newTaskGroup.taskGroupId === el.taskGroup.taskGroupId
                            ? {
                                ...newTaskGroup,
                                tasks: [...newTaskGroup.tasks, el.taskId]
                              }
                            : newTaskGroup
                        )
                      : [
                          ...binInfo.newTaskGroups,
                          {
                            taskGroupId: el.taskGroup.taskGroupId,
                            batchName: el.taskGroup.batchName,
                            batchStatus: el.taskGroup.batchStatus,
                            selectedPortId: el.taskGroup.selectedPortId,
                            tasks: [el.taskId]
                          }
                        ]
                  }
                : binInfo
            )
          : [
              ...acc,
              {
                binId: el.binId,
                taskGroups: [el.taskGroup],
                tasks: [
                  {
                    taskId: el.taskId,
                    taskGroup: el.taskGroup
                  }
                ],
                newTaskGroups: [
                  {
                    taskGroupId: el.taskGroup.taskGroupId,
                    batchName: el.taskGroup.batchName,
                    batchStatus: el.taskGroup.batchStatus,
                    selectedPortId: el.taskGroup.selectedPortId,
                    tasks: [el.taskId]
                  }
                ]
              }
            ];
      },
      []
    );

  let totalName = "";
  let totalValue = 0;

  switch (taskGroupViewType) {
    case "taskGroup":
      totalName = "Task Groups";
      totalValue = autostoreTasks?.length || 0;
      break;
    case "bin":
      totalName = "Bins";
      totalValue = shapedTasksByBinIds.length;
      break;
    case "batch":
      totalName = "Batches";
      totalValue =
        batchesResponse?.batches.filter(
          (batch) => batch.batchType === "Autostore"
        ).length || 0;
      break;
    default:
  }

  return (
    <Stack
      p={4}
      sx={{
        height: "100%",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          display: "none"
        },
        scrollbarWidth: "none",
        msOverflowStyle: "none"
      }}
    >
      <Stack justifyContent="right" direction="row" gap={2}>
        <SplitButton
          selectedValue={selectedAutostoreGridId}
          options={(autostoreGrids || []).map((autostoreGrid) => ({
            label: autostoreGrid.autostoreGridName,
            value: autostoreGrid.autostoreGridId
          }))}
          clickCb={(value) => dispatch(setSelectedAutostoreGridId(value))}
          switchCb={(value) => dispatch(setSelectedAutostoreGridId(value))}
        />
        <ProgressButton
          data-testid="fetch-autostore-tasks"
          buttonSize="medium"
          emphasis="medium"
          responsive
          variant="contained"
          color="primary"
          onClick={(): void => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            refetchAutostoreTasks();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            refetchBatches();
          }}
        >
          {t("refresh batches or tasks")}
        </ProgressButton>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py={2}
      >
        <Box>
          <Typography variant="h6">{t("group by")}</Typography>

          <ButtonGroup size="small" aria-label="small outlined button group">
            <Button
              onClick={() => {
                setTaskGroupViewType("taskGroup");
              }}
              color={taskGroupViewType === "taskGroup" ? "primary" : "inherit"}
              variant="contained"
            >
              {t("task group")}
            </Button>
            <Button
              onClick={() => {
                setTaskGroupViewType("bin");
              }}
              variant="contained"
              color={taskGroupViewType === "bin" ? "primary" : "inherit"}
            >
              {t("bin")}
            </Button>
            <Button
              onClick={() => {
                setTaskGroupViewType("batch");
              }}
              variant="contained"
              color={taskGroupViewType === "batch" ? "primary" : "inherit"}
            >
              {t("batch")}
            </Button>
          </ButtonGroup>
        </Box>
        <Typography variant="h4">
          {`Total ${totalName}: ${totalValue.toString()}`}
        </Typography>
      </Stack>
      <Box>
        {taskGroupViewType === "taskGroup" && <TaskGroupTableByTaskGroup />}
        {taskGroupViewType === "bin" && (
          <TaskGroupTableByBin shapedTasksByBinIds={shapedTasksByBinIds} />
        )}
        {taskGroupViewType === "batch" && <TaskGroupTableByBatch />}
      </Box>
    </Stack>
  );
};
