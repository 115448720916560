import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { CloseWorkstationSuccessAction } from "~/redux/actions";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useCloseWorkstationMutation } from "~/redux/warehouse/workstation.hooks";

import { useToast } from "./useToast";

export const useCloseWorkstationWithErrorToast = () => {
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();
  const [closeWorkstation] = useCloseWorkstationMutation();
  const siteWorkstation = useAppSelector(selectThisWorkstation);

  return useCallback(async () => {
    if (!siteWorkstation) return;

    try {
      await closeWorkstation({
        autostoreGridId: siteWorkstation.autostoreGridId,
        workstationId: siteWorkstation.id
      }).unwrap();

      // TODO: shim until state is moved from legacy redux
      dispatch<CloseWorkstationSuccessAction>({
        type: "autostore/CLOSE_WORKSTATION_SUCCESS"
      });
    } catch (err) {
      const message = getMessageFromRtkError(err);
      errorToast(message);
      return message;
    }

    return;
  }, [closeWorkstation, errorToast, siteWorkstation, dispatch]);
};
