import SvgRobot20Px from "@locaisolutions/icons/dist/icons20px/Robot20Px";
import SvgError24Px from "@locaisolutions/icons/dist/icons24px/Error24Px";
import {
  Divider,
  Stack,
  Box,
  Button,
  Typography,
  SvgIcon
} from "@mui/material";

import { Center } from "frontend-components";
import { useTranslation } from "react-i18next";

type Props = {
  errorCode?: number;
  errorMessage?: string;
  goBackLocation?: string;
};

export function ErrorPage({
  errorCode = 404,
  errorMessage,
  goBackLocation
}: Props) {
  const { t } = useTranslation();

  return (
    <Center>
      <Stack spacing={8} marginTop="25vh">
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          gap={6}
        >
          <SvgIcon
            component={SvgError24Px}
            sx={{
              fontSize: 64,
              color: "error.main",
              marginLeft: 2
            }}
          />
          <Typography variant="h1">{errorCode}</Typography>
        </Stack>
        <Box>
          <Center>
            <Typography>{t("oops there's an error")}</Typography>
            <Typography>
              {t("please either refresh the page or return home to try again")}
            </Typography>
          </Center>
        </Box>
        {goBackLocation && (
          <Button
            variant="contained"
            href={goBackLocation}
            startIcon={<SvgIcon component={SvgRobot20Px} />}
          >
            {t("get back on track")}
          </Button>
        )}
        {errorMessage && (
          <Stack>
            <Divider />
            <Typography variant="h5">{t("error details")}</Typography>
            <Typography variant="caption" component="pre">
              {errorMessage}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Center>
  );
}
