import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { ProgressButton } from "frontend-components";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { handleWarehouseError } from "~/api/warehouse";
import { useAppDispatch, useAppSelector } from "~/app/store";

import { useToast } from "~/hooks/useToast";
import { updatePickingState } from "~/redux/actions";
import { selectWorkstationId } from "~/redux/selectors/workstationsSelectors";
import {
  useGetTotesForBatchQuery,
  useRepickProductsMutation
} from "~/redux/warehouse/autostorePicking.hooks";
import { RepickProductsRequest } from "~/types/api";

type RepickProductsModalProps = {
  open: boolean;
  totePosition: number;
  onClose: () => void;
};

type RepickQuantity = {
  variantId: string;
  quantityToRepick: number;
  maxQuantity: number;
  units: string;
  variantName: string;
};

function RepickProductsModal({
  open,
  totePosition,
  onClose
}: RepickProductsModalProps) {
  const dispatch = useAppDispatch();
  const { successToast, warningToast, errorToast } = useToast();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const workstationId = useAppSelector(selectWorkstationId);
  const batchId = useAppSelector(
    (state) => state.autostore.pickingState?.batchId
  );
  const [repickQuantities, setRepickQuantities] = useState<RepickQuantity[]>(
    []
  );

  const { toteId, toteContents, isLoading, isFetching } =
    useGetTotesForBatchQuery(
      batchId && totePosition
        ? {
            batchId
          }
        : skipToken,
      {
        refetchOnMountOrArgChange: 5,
        selectFromResult: ({ data, isLoading, isFetching }) => {
          const tote = data
            ?.find((pos) => pos.position === totePosition)
            ?.totes?.find((tote) => !tote.labelPlacedTimestamp);

          return {
            toteId: tote?.toteId,
            toteContents: tote?.contents,
            isLoading,
            isFetching
          };
        }
      }
    );

  // initialize data
  useEffect(() => {
    if (toteContents && !repickQuantities.length) {
      setRepickQuantities(
        toteContents
          ?.filter((ele) => ele.quantity.value > 0)
          .map((contentItem) => {
            const currentQuantity = repickQuantities.find(
              (ele) => ele.variantId === contentItem.variantId
            )?.quantityToRepick;

            return {
              variantId: contentItem.variantId,
              quantityToRepick: currentQuantity ?? 0,
              maxQuantity: contentItem.quantity.value,
              units: contentItem.quantity.units,
              variantName: contentItem.variantName
            } as RepickQuantity;
          }) || []
      );
    }
  }, [toteContents, repickQuantities]);

  const totalRepickQuantity = repickQuantities.reduce(
    (acc, ele) => acc + ele.quantityToRepick,
    0
  );

  const { t } = useTranslation();

  const [repickProducts] = useRepickProductsMutation();

  const incrementQuantity = (variantId: string) => {
    setRepickQuantities(
      repickQuantities.map((ele) =>
        ele.variantId === variantId
          ? {
              ...ele,
              quantityToRepick: Math.min(
                ele.maxQuantity,
                ele.quantityToRepick + 1
              )
            }
          : ele
      )
    );
  };

  const decrementQuantity = (variantId: string) => {
    setRepickQuantities(
      repickQuantities.map((ele) =>
        ele.variantId === variantId
          ? {
              ...ele,
              quantityToRepick: Math.max(0, ele.quantityToRepick - 1)
            }
          : ele
      )
    );
  };

  const repickAll = () => {
    setRepickQuantities(
      repickQuantities.map((ele) => {
        return {
          ...ele,
          quantityToRepick: ele.maxQuantity
        };
      })
    );
  };

  const repickNone = () => {
    setRepickQuantities(
      repickQuantities.map((ele) => {
        return {
          ...ele,
          quantityToRepick: 0
        };
      })
    );
  };

  const handleRepickConfirm = async () => {
    if (repickQuantities?.length) {
      if (batchId && toteId && workstationId) {
        try {
          const request: RepickProductsRequest = {
            toteId,
            batchId,
            workstationId,
            repick: repickQuantities
              .filter((contentLocal) => contentLocal.quantityToRepick > 0)
              .map((repick) => ({
                variantId: repick.variantId,
                quantity: {
                  value: repick.quantityToRepick,
                  units: repick.units
                }
              })),
            reason: "Damaged"
          };

          const message = await repickProducts(request).unwrap();

          if (message.includes("Some picks could not be rescheduled"))
            warningToast(message);
          else successToast(message);

          await dispatch(updatePickingState({}));

          onClose();
        } catch (err) {
          handleWarehouseError(err, (message) => {
            errorToast(message);
          });
        }
      }
    }
  };

  if (isLoading || isFetching) {
    return (
      <Dialog
        onClose={onClose}
        open={open}
        fullScreen={fullScreen}
        maxWidth={"lg"}
      >
        <DialogTitle variant="h5" fontWeight="bold" aria-label="dialog-title">
          {t("repick products")}
        </DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  } else if (!repickQuantities.length) {
    return (
      <Dialog onClose={onClose} open={open} maxWidth={"lg"}>
        <DialogTitle variant="h5" fontWeight="bold" aria-label="dialog-title">
          {t("repick products")}
        </DialogTitle>
        <DialogContent>
          <Alert
            sx={{ marginTop: "20px" }}
            aria-label={`maximum-quantity-repicked`}
            severity={"error"}
          >
            <Typography variant="h6">
              {t("the maximum amount repicked")}
            </Typography>
          </Alert>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
          <ProgressButton
            color="primary"
            onClick={onClose}
            fullWidth
            buttonSize="medium"
            emphasis="high"
            responsive
            aria-label="close-repick-button"
          >
            {t("ok")}
          </ProgressButton>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        onClose={onClose}
        open={open}
        fullScreen={fullScreen}
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle variant="h5" fontWeight="bold" aria-label="dialog-title">
          {t("repick products")}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Fragment key={toteId}>
              {repickQuantities.map((contentItem, i) => (
                <Grid
                  key={`repick-${i}`}
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    paddingTop: "10px",
                    paddingLeft: "10px"
                  }}
                >
                  <Grid item xs={9}>
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      aria-label={contentItem.variantName}
                    >
                      {contentItem.variantName}
                    </Typography>
                  </Grid>
                  {contentItem.quantityToRepick > 0 ? (
                    <Grid
                      item
                      xs={1}
                      sx={{
                        textAlign: "right"
                      }}
                    >
                      <RemoveIcon
                        fontSize="large"
                        onClick={() => decrementQuantity(contentItem.variantId)}
                        sx={{
                          "&:hover": {
                            cursor: "pointer"
                          }
                        }}
                        aria-label={`repick-${contentItem.variantName}-minus`}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={1} />
                  )}
                  <Grid
                    item
                    xs={1}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ textAlign: "center" }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        textAlign: "center"
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          color:
                            contentItem.quantityToRepick === 0
                              ? "gray.main"
                              : undefined,
                          letterSpacing: 4
                        }}
                        aria-label={`${contentItem.variantName}-quantity-to-repick`}
                      >
                        {contentItem.quantityToRepick}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{ letterSpacing: 4 }}
                        aria-label={`${contentItem.variantName}-quantity-total`}
                      >
                        {`/${contentItem.maxQuantity}`}
                      </Typography>
                    </Box>
                  </Grid>
                  {contentItem.quantityToRepick < contentItem.maxQuantity ? (
                    <Grid item xs={1}>
                      <AddIcon
                        fontSize="large"
                        onClick={() => incrementQuantity(contentItem.variantId)}
                        sx={{
                          "&:hover": {
                            cursor: "pointer"
                          }
                        }}
                        aria-label={`repick-${contentItem.variantName}-plus`}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={1} />
                  )}
                </Grid>
              ))}
            </Fragment>
            <Grid item sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
              <Divider />
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                paddingLeft: "10px"
              }}
            >
              <Grid item xs={9}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  aria-label="repick-all-text"
                >
                  {t("repick all")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  textAlign: "right"
                }}
              >
                <RemoveIcon
                  fontSize="large"
                  sx={{
                    "&:hover": {
                      cursor: "pointer"
                    }
                  }}
                  onClick={() => {
                    repickNone();
                  }}
                  aria-label="repick-all-products-minus"
                />
              </Grid>

              <Grid item xs={1} sx={{ textAlign: "center" }}>
                <Typography variant="h5" aria-label="repick-all-products-value">
                  {totalRepickQuantity}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <AddIcon
                  fontSize="large"
                  sx={{
                    textAlign: "right",
                    "&:hover": {
                      cursor: "pointer"
                    }
                  }}
                  onClick={() => {
                    repickAll();
                  }}
                  aria-label="repick-all-products-plus"
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              sx={{
                paddingTop: "20px",
                paddingBottom: "20px",
                marginTop: "20px"
              }}
            >
              <Grid item xs={6} sx={{ display: { md: "none" } }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={onClose}
                  fullWidth
                >
                  <Typography variant="h5">{t("cancel")}</Typography>
                </Button>
              </Grid>
              <Grid item md={12} xs={6}>
                <ProgressButton
                  onClick={handleRepickConfirm}
                  fullWidth
                  responsive
                  disabled={totalRepickQuantity <= 0}
                  aria-label="confirm-button"
                >
                  <Typography variant="h5">{t("confirm")}</Typography>
                </ProgressButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}
export default RepickProductsModal;
