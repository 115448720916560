import {
  createMigrate,
  MigrationManifest,
  PersistedState
} from "redux-persist";

import { LoginState } from "./login.slice.interfaces";

export type PersistedLoginStateV1 = LoginState & PersistedState;

export type PersistedLoginStateV0 = Omit<
  PersistedLoginStateV1,
  "authMethod"
> & {
  idToken: string | null;
};

export const v0ToV1 = (state: PersistedLoginStateV0): PersistedLoginStateV1 => {
  // ANDON users will unfortunately be logged out since we cannot differentiate between andon and qubit auth from the token
  const authMethod =
    !!state.accessToken && !!state.profile?.sub?.includes("pepsi")
      ? "BEV_QUBIT"
      : !!state.accessToken && !!state.profile?.sub?.includes("auth0")
        ? "QUBIT"
        : null;

  return {
    ...state,
    isUserLoggedIn: !!authMethod,
    authMethod: authMethod
  };
};

const persistMigrations: MigrationManifest = {
  // @ts-expect-error -- TS2322 Migration types are wrong for typescript. See https://github.com/rt2zz/redux-persist/issues/1065
  1: v0ToV1
};

export const persistMigrate = createMigrate(persistMigrations);

export const persistVersion = 1;
