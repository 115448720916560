import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Typography, useMediaQuery, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import { mobileWidth } from "frontend-components";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { formatTime } from "~/lib/helpers";
import { useKeyDown } from "~/lib/keydownHook";
import { FulfillmentCenterDto, OrderSummaryDto } from "~/types/api";

import AutostoreTable from "./autostore/table/AutostoreTable";

type OrderTableProps = {
  formatOrderStatus?: (order: OrderSummaryDto) => string;
  fulfillmentCenter: FulfillmentCenterDto | null;
  onOrderSelected: (order: OrderSummaryDto) => void;
  orders: OrderSummaryDto[];
  ordersLoading: boolean;
  selectedDate: Date | null;
  selectedOrderIds: Guid[];
  title: string;
};

type ShiftClickFunction = "select" | "unselect";

export function OrderTable(props: OrderTableProps) {
  const {
    formatOrderStatus = undefined,
    fulfillmentCenter,
    onOrderSelected,
    orders,
    ordersLoading,
    selectedDate,
    selectedOrderIds,
    title
  } = props;

  const { t } = useTranslation();
  const isMobile = useMediaQuery(mobileWidth);

  const displayDate = selectedDate
    ? moment(selectedDate).format("MMM Do")
    : null;

  const displayDay = selectedDate ? moment(selectedDate).format("ddd") : null;
  const defaultOrderStatusFormat = (order: OrderSummaryDto) => order.status;
  const formatOrderStatus2 = formatOrderStatus || defaultOrderStatusFormat;

  // shift click
  const shiftIsPressed = useKeyDown("Shift");

  const selectedIndexes = selectedOrderIds.map((selectedOrderId) =>
    orders.findIndex((order) => order.orderId === selectedOrderId)
  );

  const [mostRecentClickIndex, setMostRecentClickIndex] = useState<
    number | null
  >(null);
  const [shiftClickFunction, setShiftClickFunction] =
    useState<ShiftClickFunction>("select");

  const handleRowClick = (clickedOrder: OrderSummaryDto) => {
    const clickedIndex = orders.findIndex(
      (order) => order.orderId === clickedOrder.orderId
    );
    setMostRecentClickIndex(clickedIndex);

    const clickedItemIsSelected = !!selectedOrderIds.find(
      (orderId) => orderId === clickedOrder.orderId
    );

    if (!shiftIsPressed) {
      setShiftClickFunction(clickedItemIsSelected ? "unselect" : "select");
    }

    if (shiftIsPressed && !(mostRecentClickIndex == null)) {
      const start = Math.min(mostRecentClickIndex, clickedIndex);
      const end = Math.max(mostRecentClickIndex, clickedIndex);

      const selectedIndexRange = Array.from(
        { length: end - start + 1 },
        (_x, i) => i + start
      );

      const indexesToSelect = selectedIndexRange.filter((index) =>
        shiftClickFunction === "select"
          ? !selectedIndexes.includes(index)
          : selectedIndexes.includes(index)
      );

      indexesToSelect.forEach((orderIndex: number) => {
        const orderToSelect: OrderSummaryDto = orders[orderIndex];
        onOrderSelected(orderToSelect);
      });
    } else {
      onOrderSelected(clickedOrder);
    }
  };

  return (
    <>
      <Box id="order-table-container" sx={{ height: "100%" }}>
        <AutostoreTable<OrderSummaryDto>
          title={`${displayDay} ${displayDate}`}
          widthOfCols={["70%", "15%", "15%"]}
          headerColNames={[
            title,
            isMobile ? t("Time") : t("Window"),
            t("Status")
          ]}
          rowId={(row: OrderSummaryDto) => row.orderId}
          renderColumns={[
            (row: OrderSummaryDto) => {
              const { externalOrderId = "", customerName } = row;

              return (
                <>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid item>
                      <Typography className="test-id" variant="caption">
                        {customerName}
                      </Typography>
                      <Typography
                        className="test-id"
                        variant="caption"
                        component="div"
                      >
                        {externalOrderId}
                      </Typography>
                    </Grid>
                    {row.priority.toLowerCase() === "express" && (
                      <Grid
                        item
                        style={
                          isMobile
                            ? { marginLeft: "3vw" }
                            : { marginLeft: "1vw" }
                        }
                      >
                        <Grid container direction="column" alignItems="center">
                          <Grid
                            item
                            style={{
                              transform: "translateY(15%)"
                            }}
                          >
                            <AccessTimeIcon fontSize="medium" />
                          </Grid>
                          <Grid
                            item
                            style={{
                              transform: "translateY(-20%)"
                            }}
                          >
                            <Typography variant="caption">
                              {row.priority}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </>
              );
            },
            (row) => {
              return (
                <>
                  <Typography variant="body2">
                    {row.pickingEndTime &&
                      formatTime(
                        row.pickingEndTime,
                        fulfillmentCenter?.timeZone
                      )}
                  </Typography>
                </>
              );
            },
            (row: OrderSummaryDto) => (
              <>
                <Typography variant="body2">
                  {" "}
                  {!formatOrderStatus
                    ? t(`${formatOrderStatus2(row).toLowerCase()}`)
                    : formatOrderStatus2(row)}
                </Typography>
              </>
            )
          ]}
          rowData={orders}
          selectedRows={selectedOrderIds}
          selectRowCallback={(row: OrderSummaryDto) => {
            handleRowClick(row);
          }}
          loading={ordersLoading}
        />
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={shiftIsPressed}
      >
        <SnackbarContent
          message={shiftClickFunction === "select" ? "Select" : "Unselect"}
          style={{
            width: 150,
            minWidth: 150,
            display: "flex",
            justifyContent: "center"
          }}
        />
      </Snackbar>
    </>
  );
}
