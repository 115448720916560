import {
  Box,
  Select,
  Menu,
  MenuItem,
  Typography,
  Checkbox,
  InputLabel,
  Stack
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ProgressButton } from "frontend-components";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useResizeDetector } from "react-resize-detector";

import { useAppSelector } from "~/app/store";

import DragAndDropGridLayout from "~/components/tools/DragAndDropGridLayout";
import {
  createNumberRangeArr,
  findClosestEmptyPositions,
  getEmptyPositions,
  convertPixelCoordinatesToGridCoordinates
} from "~/lib/helpers";

import AndonBoardElement from "./AndonBoardElement";
import LayoutCodeModal from "./LayoutCodeModal";
import WorkstationSelectorModal from "./WorkstationSelectorModal";
import {
  setAndonViewOption,
  setBoardViewIds,
  setBoardLayout,
  setBoardDimensions,
  setBoardLocked,
  selectAndonWorkstations,
  selectAndonBoardViewsIds,
  selectAndonBoardLayout,
  selectAndonBoardDimensions,
  selectCurrentAndonBoardView,
  selectAndonBoardLocked
} from "./andon.slice";

function calculateFontSize({
  baseContainerWidth,
  baseContainerHeight,
  maxCharacters,
  widthSpan = 1,
  heightSpan = 1
}: {
  baseContainerWidth: number;
  baseContainerHeight: number;
  maxCharacters: number;
  widthSpan?: number;
  heightSpan?: number;
}): number {
  const containerWidth = baseContainerWidth * widthSpan;
  const containerHeight = baseContainerHeight * heightSpan;

  // Constants for calculation
  const startingFontSize = 16; // Starting point for font size
  const averageCharWidth = 0.6; // Assumed average width of a character at starting font size
  const widthRatio = 0.8; // Limit width to 90% of the container
  const lineHeightFactor = 1.2; // Assume line height is 20% greater than the font size

  // Calculate the maximum allowable width for the title
  const maxTitleWidth = containerWidth * widthRatio;

  // Calculate font size based on width
  const fontSizeByWidth =
    maxTitleWidth / maxCharacters / (averageCharWidth * startingFontSize);

  // Calculate the maximum allowable height for the text
  const maxHeight = containerHeight * 0.25; // 25% of the container height

  // Calculate font size based on height
  const fontSizeByHeight = maxHeight / lineHeightFactor; // 20 / 1.2

  // The final font size should be the smaller of the width-based and height-based calculations
  let finalFontSize = Math.min(
    fontSizeByWidth * startingFontSize,
    fontSizeByHeight
  );

  // Define the maximum font size if necessary
  const fontSizeMax = 40;
  finalFontSize = Math.min(finalFontSize, fontSizeMax);

  // Ensure the font size is not too small (adjust the minimum size if necessary)
  finalFontSize = Math.max(finalFontSize, 10);

  return finalFontSize;
}

export default function AndonBoard() {
  const {
    width: boardWidth,
    height: boardHeight,
    ref: boardRef
  } = useResizeDetector();
  const andonWorkstations = useAppSelector(selectAndonWorkstations);

  const [selectedGridIndex, setSelectedGridIndex] = useState<string | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorPosition, setAnchorPosition] = useState<{
    left: number;
    top: number;
  } | null>(null);
  const [fontSizeCache, setFontSizeCache] = useState<{ [key: string]: number }>(
    {}
  );
  const [workstationSelectorOpen, setWorkstationSelectorOpen] = useState(false);
  const [layoutCodeOpen, setLayoutCodeOpen] = useState(false);
  const boardViewIds = useAppSelector(selectAndonBoardViewsIds);
  const boardLayout = useAppSelector(selectAndonBoardLayout);
  const boardDimensions = useAppSelector(selectAndonBoardDimensions);

  const open = Boolean(anchorEl || anchorPosition);

  const gridHeightPercentage = 100 / boardDimensions.rows;
  const gridColumnWidth = boardWidth ? boardWidth / boardDimensions.columns : 0;
  const gridColumnHeight = boardHeight
    ? (boardHeight * gridHeightPercentage) / 100
    : 0;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const andonViewOption = useAppSelector(selectCurrentAndonBoardView);
  const isFullScreen = andonViewOption === "board fullscreen";
  const boardLocked = useAppSelector(selectAndonBoardLocked);

  useEffect(() => {
    // Calculate and cache font sizes based on the longest title per size
    const newFontSizeCache: { [key: string]: number } = {};

    const longestTitlesBySize: { [key: string]: string } = {};

    // Find the longest title for each container size
    for (const layoutItem of boardLayout) {
      const sizeKey = `${layoutItem.w}x${layoutItem.h}`;
      const title =
        andonWorkstations[layoutItem.i]?.workstation?.autostoreGridName || "";
      if (
        !longestTitlesBySize[sizeKey] ||
        title.length > longestTitlesBySize[sizeKey].length
      ) {
        longestTitlesBySize[sizeKey] = title;
      }
    }

    // Calculate the font size for the longest title of each size
    for (const [sizeKey, title] of Object.entries(longestTitlesBySize)) {
      const [widthSpan, heightSpan] = sizeKey.split("x").map(Number);
      newFontSizeCache[sizeKey] = calculateFontSize({
        baseContainerWidth: gridColumnWidth,
        baseContainerHeight: gridColumnHeight,
        maxCharacters: title.length,
        widthSpan,
        heightSpan
      });
    }

    setFontSizeCache(newFontSizeCache);
  }, [andonWorkstations, gridColumnWidth, gridColumnHeight, boardLayout]);

  const updateLayoutAndDimensions = (
    dimensionType: string,
    newValue: number
  ) => {
    const newAndonLayout = boardLayout
      .filter((item) => {
        if (dimensionType === "columns") {
          return item.x + item.w <= newValue;
        } else {
          return item.y + item.h <= newValue;
        }
      })
      .map((item) => ({
        ...item,
        // Recalculate maxH for the change in rows
        ...(dimensionType === "rows" && { maxH: newValue - item.y })
      }));

    dispatch(setBoardLayout(newAndonLayout));
    dispatch(
      setBoardDimensions({
        columns: dimensionType === "rows" ? boardDimensions.columns : newValue,
        rows: dimensionType === "columns" ? boardDimensions.rows : newValue
      })
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedGridIndex(index);
  };

  const handleGridClick = (event: MouseEvent) => {
    const emptyCoordinates = getEmptyPositions(
      boardLayout,
      boardDimensions.columns,
      boardDimensions.rows
    );

    if (emptyCoordinates.length) {
      setAnchorPosition({
        left: event.clientX,
        top: event.clientY
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedGridIndex(null);
    setAnchorPosition(null);
  };

  const removeWorkstation = (workstationId: string) => {
    const updatedLayout = boardLayout.filter(
      (item) => item.i !== workstationId
    );
    dispatch(setBoardLayout(updatedLayout));
    setAnchorEl(null);
    setSelectedGridIndex(null);
  };

  const selectWorkstation = (workstationId: string | null) => {
    // the x option removes the workstation
    if (!workstationId && selectedGridIndex != null) {
      removeWorkstation(selectedGridIndex);

      // if you select an existing element/workstation, update with the newly selected workstation
    } else if (workstationId && selectedGridIndex != null) {
      const updatedLayout = boardLayout.map((item) => {
        if (item.i === selectedGridIndex) {
          return { ...item, i: workstationId };
        }
        return item;
      });

      dispatch(setBoardLayout(updatedLayout));

      // from grid clicks (not workstation clicks)
    } else {
      const grid = document.querySelector(`.andon-board`);

      if (grid && anchorPosition) {
        const andonBoardDiv = grid.getBoundingClientRect();

        const clickedGridPosition = convertPixelCoordinatesToGridCoordinates(
          // use the saved anchor position minus offsets when sending pixel location
          anchorPosition.left - andonBoardDiv.left,
          anchorPosition.top - andonBoardDiv.top,
          boardWidth || 0,
          boardHeight ? boardHeight * (gridHeightPercentage / 100) : 0,
          boardDimensions.columns
        );

        const emptyCoordinates = getEmptyPositions(
          boardLayout,
          boardDimensions.columns,
          boardDimensions.rows
        );

        const closestPositions = findClosestEmptyPositions(
          clickedGridPosition,
          emptyCoordinates
        );

        if (closestPositions && workstationId) {
          const newItem = {
            i: workstationId,
            x: closestPositions.x,
            y: closestPositions.y,
            w: 1,
            h: 1,
            maxH: boardDimensions.rows - closestPositions.y // Setting maxH based on available rows
          };

          const updatedLayout = [...boardLayout, newItem];

          dispatch(setBoardLayout(updatedLayout));
        }
      }
    }

    handleClose();
    setSelectedGridIndex(null);
  };

  const workstationsForDropdown = Object.values(andonWorkstations)
    .slice()
    .sort(
      (a, b) =>
        a.workstation.autostoreGridName.localeCompare(
          b.workstation.autostoreGridName
        ) || a.workstation.deviceId.localeCompare(b.workstation.deviceId)
    )
    .filter(
      (aws) =>
        boardViewIds.includes(aws.id) &&
        !boardLayout.find((layout) => layout.i === aws.id)
    );

  return (
    <Box sx={{ height: "100%", px: 4 }} ref={boardRef}>
      {/* top row, buttons etc */}
      {!isFullScreen && (
        <Stack direction="row" gap={1} alignItems="center">
          <ProgressButton
            emphasis="high"
            responsive
            variant="contained"
            color="primary"
            onClick={() => {
              setWorkstationSelectorOpen(true);
            }}
          >
            {t("select workstations")}
          </ProgressButton>
          <ProgressButton
            emphasis="high"
            responsive
            variant="contained"
            color="primary"
            sx={{ marginLeft: "10px" }}
            onClick={() => {
              dispatch(setAndonViewOption("board fullscreen"));
            }}
          >
            {t("full screen")}
          </ProgressButton>
          <ProgressButton
            emphasis="high"
            responsive
            variant="contained"
            color="primary"
            sx={{ marginLeft: "10px" }}
            onClick={() => {
              dispatch(setBoardLayout([]));
            }}
          >
            {t("clear")}
          </ProgressButton>
          <ProgressButton
            emphasis="high"
            responsive
            variant="contained"
            color="primary"
            sx={{ marginLeft: "10px" }}
            onClick={() => {
              setLayoutCodeOpen(true);
            }}
          >
            {t("layout code")}
          </ProgressButton>
          {/* columns */}
          <InputLabel
            id="andon-dimensions-columns-label"
            htmlFor="andon-dimensions-columns"
          >
            <Typography variant="button">{t("columns")}</Typography>
          </InputLabel>
          <Select
            id="andon-dimensions-columns"
            value={boardDimensions.columns}
            sx={{ height: "50px", width: "75px" }}
            labelId="andon-dimensions-columns-label"
          >
            {createNumberRangeArr(1, 10).map((numb) => (
              <MenuItem
                key={`${numb}columns`}
                value={numb}
                onClick={() => updateLayoutAndDimensions("columns", numb)}
              >
                {numb}
              </MenuItem>
            ))}
          </Select>
          {/* rows */}
          <InputLabel
            id="andon-dimensions-rows-label"
            htmlFor="andon-dimensions-rows"
          >
            <Typography variant="button">{t("rows")}</Typography>
          </InputLabel>
          <Select
            id="andon-dimensions-rows"
            value={boardDimensions.rows}
            labelId="andon-dimensions-rows-label"
            sx={{ height: "50px", width: "75px" }}
          >
            {createNumberRangeArr(1, 10).map((numb) => (
              <MenuItem
                key={`${numb}rows`}
                value={numb}
                onClick={() => updateLayoutAndDimensions("rows", numb)}
              >
                {numb}
              </MenuItem>
            ))}
          </Select>
          {/* size lock */}
          <FormControlLabel
            label={
              <Typography variant="button" color="textSecondary">
                {t("size lock")}
              </Typography>
            }
            labelPlacement="start"
            control={
              <Checkbox
                onChange={() => dispatch(setBoardLocked(!boardLocked))}
                checked={boardLocked}
              />
            }
          />
        </Stack>
      )}
      <Box
        sx={{
          width: "100%",
          height: isFullScreen ? "100%" : "90%"
        }}
        data-testid="andon-board"
      >
        {boardWidth && boardHeight && (
          <DragAndDropGridLayout
            sizeLocked={boardLocked}
            className="andon-board"
            andonWorkstations={andonWorkstations}
            layout={boardLayout}
            cols={boardDimensions.columns}
            rows={boardDimensions.rows}
            width={boardWidth}
            rowHeight={
              (isFullScreen ? boardHeight : boardHeight * 0.9) *
                (gridHeightPercentage / 100) -
              1
            }
            onLayoutChange={(layout) => dispatch(setBoardLayout(layout))}
            preventCollision
            onExistingElementClick={handleClick}
            onGridClick={handleGridClick}
            renderElementWithIndex={(workstationId) => {
              const aws = andonWorkstations[workstationId];

              if (!aws) return null;

              const name = aws.workstation.deviceId;
              const matchingLayout = boardLayout.find(
                (lo) => lo.i === workstationId
              );

              const widthSpan = matchingLayout?.w || 1;
              const heightSpan = matchingLayout?.h || 1;
              // all sizes will have consistent font size for their size
              const sizeKey = `${widthSpan}x${heightSpan}`;

              const fontSize =
                fontSizeCache[sizeKey] ||
                calculateFontSize({
                  baseContainerWidth: gridColumnWidth,
                  baseContainerHeight: gridColumnHeight,
                  maxCharacters: name.length,
                  widthSpan,
                  heightSpan
                });
              return (
                <AndonBoardElement
                  workstationId={workstationId}
                  titleFontSize={fontSize}
                  elWidth={gridColumnWidth}
                  elHeight={gridColumnHeight}
                />
              );
            }}
          />
        )}

        <Menu
          id="workstation-menu"
          anchorEl={anchorEl}
          anchorPosition={anchorPosition || undefined}
          anchorReference={anchorEl ? undefined : "anchorPosition"}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "select-workstation"
          }}
        >
          {workstationsForDropdown.map((aws) => (
            <MenuItem
              key={`${aws.id}-choice`}
              onClick={() => selectWorkstation(aws.id)}
            >
              <Typography>{aws.workstation.deviceId}</Typography>
            </MenuItem>
          ))}
          {selectedGridIndex && (
            <MenuItem key="null" onClick={() => selectWorkstation(null)}>
              <Typography>X</Typography>
            </MenuItem>
          )}
        </Menu>
      </Box>
      {/* modals */}
      <WorkstationSelectorModal
        isOpen={workstationSelectorOpen}
        selectedWorkstationIds={boardViewIds}
        workstationSelectorCb={(newIds) => {
          dispatch(setBoardViewIds(newIds));
        }}
        setModalOpen={(isOpen) => setWorkstationSelectorOpen(isOpen)}
        footer={
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Box sx={{ width: "150px" }}>
              <ProgressButton
                id="autofill-button"
                data-testid="autofill-button"
                emphasis="high"
                responsive
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  const gridItemWidth = 1;
                  const gridItemHeight = 1;

                  const numberOfElements =
                    boardDimensions.rows * boardDimensions.columns;

                  const newLayout = boardViewIds
                    .slice(0, numberOfElements)
                    .map((workstationId, index) => {
                      // Calculate the position of each workstation based on its index
                      const positionX =
                        (index % boardDimensions.columns) * gridItemWidth;
                      const positionY =
                        Math.floor(index / boardDimensions.columns) *
                        gridItemHeight;

                      return {
                        i: workstationId,
                        x: positionX,
                        y: positionY,
                        w: gridItemWidth,
                        h: gridItemHeight,
                        maxH: boardDimensions.rows - positionY
                      };
                    });
                  dispatch(setBoardLayout(newLayout));
                  setWorkstationSelectorOpen(false);
                }}
              >
                {t("autofill")}
              </ProgressButton>
            </Box>
          </Box>
        }
      />
      <LayoutCodeModal
        isOpen={layoutCodeOpen}
        setModalOpen={(isOpen) => setLayoutCodeOpen(isOpen)}
      />
    </Box>
  );
}
