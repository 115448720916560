import { Button, Stack, useMediaQuery } from "@mui/material";
import { mobileWidth } from "frontend-components";
import { RefObject } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";

import { warehouseService } from "~/api/warehouse";
import { PrinterConfiguration } from "~/api/warehouseTypes/fulfillmentCenter";
import { useAppDispatch, useAppSelector } from "~/app/store";

import { useToast } from "~/hooks/useToast";
import { downloadBlob } from "~/lib/shared";
import { prepareCart } from "~/redux/actions/batch";
import { getBatchLabels } from "~/redux/actions/labels";
import { printToDefaultPrinter } from "~/redux/actions/printer";
import {
  selectBatch,
  selectBatchTotes,
  selectCanUnassignCart,
  selectToteLabels
} from "~/redux/selectors/batchSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { GetBatchSummariesResponse } from "~/types/api";

import { openAssignCartModal, openUnassignCartModal } from "./batch.slice";
import { toteLabelZPL } from "./toteLabelZpl";

type Props = {
  printComponentRef: RefObject<HTMLDivElement>;
};

export function BatchCartPrepToolbar({ printComponentRef }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { infoToast, successToast } = useToast();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(mobileWidth);

  const batch = useAppSelector(selectBatch);
  const batchTotes = useAppSelector(selectBatchTotes);
  const defaultPrinter = useAppSelector(
    (state) => state.printer.defaultPrinter
  );
  const { cartAssignmentEnabled } = useAppSelector(selectClientConfig);
  const fetchBatchFilters = useAppSelector(
    (state) => state.batch.fetchBatchFilters
  );
  const printerConfiguration = useAppSelector(
    (state) =>
      (state.store.usersFulfillmentCenter
        ?.printerConfiguration as PrinterConfiguration) || null
  );
  const toteLabels = useAppSelector(selectToteLabels);
  const usersFC = useAppSelector(selectUsersFulfillmentCenter);
  const canUnassignCart = useAppSelector(selectCanUnassignCart);

  const batchType = batch?.batchType;
  const cartNumberAssigned = batch?.cartNumber;

  const handlePagePrint = useReactToPrint({
    pageStyle: `
      @page {
        margin: 0
      }
    `,
    contentRef: printComponentRef
  });

  const isCartAssigned = batchTotes.every((tote) => !!tote.cartId);
  const canBatchBePicked =
    usersFC &&
    (usersFC.toteConfiguration === "NoTotes" ||
      (usersFC.toteConfiguration === "GeneratedTotes" && isCartAssigned));

  const handleDownloadZPL = async (): Promise<void> => {
    if (batch) {
      dispatch(prepareCart(batch.batchId, usersFC || null));
      const labelData = await dispatch(getBatchLabels(batch.batchId));

      if (labelData) {
        downloadBlob(labelData, `${batch.batchName}.labels.zpl`);
        successToast(t("labels printed"), { duration: 500 });
      }
    }
  };

  const handleNextCartClicked = async () => {
    const nextBatchSearch =
      await warehouseService.get<GetBatchSummariesResponse>("api/batches", {
        params: {
          status: ["Scheduled"],
          batchType: fetchBatchFilters.selectedBatchTypes.filter(
            (type) => type !== "Bulk" // dont include bulk batch types since theres no prep for bulk batches
          ),
          limit: 1,
          ...(fetchBatchFilters.selectedTempZones.length && {
            temperatureZone: fetchBatchFilters.selectedTempZones[0]
          }),
          ...(fetchBatchFilters.selectedOrderType && {
            orderType: fetchBatchFilters.selectedOrderType
          })
        }
      });
    const nextBatch = nextBatchSearch.data.batches;
    if (!nextBatch.length) {
      infoToast("No Scheduled Batches Ready");
    } else {
      navigate(`/batches/${nextBatch[0].batchName}/cart-prep`);
    }
  };

  const handleBrowserPrint = () => {
    const labelsZPL = toteLabels?.map((label) => {
      const {
        zone,
        position = "",
        toteId,
        firstName,
        lastName = "",
        externalOrderId,
        putwallPosition,
        orderType
      } = label;
      return toteLabelZPL({
        zone,
        cartPosition: `${position}`,
        toteId,
        putwallPosition,
        customerName: `${firstName} ${lastName}`,
        externalOrderId,
        orderType
      });
    });
    if (!defaultPrinter && labelsZPL)
      dispatch(printToDefaultPrinter(labelsZPL.join()));
    if (labelsZPL && defaultPrinter) {
      defaultPrinter.send(labelsZPL.join());
    }
  };

  const prepareAndPrint = () => {
    if (batch && batch.status === "Scheduled")
      dispatch(prepareCart(batch.batchId, usersFC || null));
    if (handlePagePrint && printerConfiguration === "PagePrint") {
      handlePagePrint();
    } else if (printerConfiguration === "BrowserPrint") {
      handleBrowserPrint();
    }
  };

  return (
    <Stack direction="row" spacing={1}>
      {!!batchTotes.length &&
        (printerConfiguration === "PagePrint" ||
          printerConfiguration === "BrowserPrint") && (
          <Button color="primary" variant="contained" onClick={prepareAndPrint}>
            {t("print")}
          </Button>
        )}

      {/* only allow Assign Cart when batch is Scheduled and no cartNumber is assigned */}
      {cartAssignmentEnabled &&
        !cartNumberAssigned &&
        batch?.status === "Scheduled" && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => dispatch(openAssignCartModal())}
          >
            {t("assign")}
          </Button>
        )}

      {!isMobile && canUnassignCart && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => dispatch(openUnassignCartModal())}
        >
          {t("unassign")}
        </Button>
      )}
      {printerConfiguration === "DownloadZPL" && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            void handleDownloadZPL();
          }}
        >
          Print ZPL
        </Button>
      )}

      {/* only show manual pick option for non-autostore batches that are ready to be picked */}
      {batchType !== "Autostore" && canBatchBePicked && (
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            batch ? navigate(`/batches/${batch.batchName}/pick`) : null
          }
        >
          Pick
        </Button>
      )}
      {(batch?.status !== "Scheduled" || cartNumberAssigned !== null) && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            void handleNextCartClicked();
          }}
        >
          {t("next")}
        </Button>
      )}
    </Stack>
  );
}
