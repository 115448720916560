import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { Dialog, Grid, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import dayjs, { Dayjs } from "dayjs";
import { ProgressButton } from "frontend-components";
import { useEffect, useState, MouseEvent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import { useAppDispatch, useAppSelector } from "~/app/store";
import { AutostorePutawayModal } from "~/components/AutostorePutawayModal";
import { ErrorPanel } from "~/components/ErrorPanel";
import { ErrorSuccessSnackbar } from "~/components/ErrorSuccessSnackbar";
import { InventoryAddDialog } from "~/components/InventoryAddDialog";

import ScanningIndicator, {
  useScanIndicator
} from "~/components/ScanningIndicator";
import AutostoreBin from "~/components/autostore/autostoreBin";
import { AutostoreBinConfigModal } from "~/components/autostore/modals/AutostoreBinConfigModal";
import { openBinConfigModal } from "~/components/autostore/modals/AutostoreBinConfigModal.slice";
import UniversalProductCard, {
  PickInfoIsLoading
} from "~/components/productCard/UniversalProductCard";
import envConstants from "~/config/envConstants";
import ActionButtons from "~/features/autostorePutaway/ActionButtons";

import ChangeSuggestedBinModal from "~/features/autostorePutaway/modals/ChangeSuggestedBinModal";
import DecantRateModal from "~/features/autostorePutaway/modals/DecantRateModal";
import FlagBinModal from "~/features/autostorePutaway/modals/FlagBinModal";
import InventoryHoldModal from "~/features/autostorePutaway/modals/InventoryHoldModal";

import PutawayAdjustModal from "~/features/putaway/PutawayAdjustModal";
import { useDebounce } from "~/hooks/useDebounce";
import { useNavbar } from "~/hooks/useNavbar";
import usePortStatus from "~/hooks/usePortStatus";
import usePrevious from "~/hooks/usePrevProps";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";
import { useToast } from "~/hooks/useToast";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import useWindowDimensions from "~/lib/browserDimensions";
import { getEmptyCompartmentNumbers } from "~/lib/getEmptyBinCompartmentNumbers";
import {
  ternaryIff,
  checkIsExpiration,
  getBarcodeValue,
  getAxiosErrorMessage,
  getInventoryDateObjDayjs,
  searchProduct
} from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useGridV2Subscription } from "~/lib/signalr";
import usePromiseInterval from "~/lib/usePromiseIntervalEffect";

import {
  nextEmptyBin,
  fetchPutawayTasks,
  setPortStatus,
  fetchPortStatus,
  closeBin,
  setSelectedBinConfigurations,
  resetPortBinData
} from "~/redux/actions";
import {
  setSelectedAutostoreBinId,
  setCurrentEmptyBin,
  closeWorkstation
} from "~/redux/actions/autostore";
import {
  adjustInventory,
  getBinById,
  findInventoryByAutostoreBinNumber,
  clearSelectedVariant,
  clearInventoryMessage
} from "~/redux/actions/inventory";
import {
  clearErrorMessage,
  clearSuccessMessage,
  putAwayItem
} from "~/redux/actions/putaway";
import { setUserMessage } from "~/redux/actions/site";
import { StoreState } from "~/redux/reducers";
import { useGetAutostoreGridQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetBinConfigurationsQuery } from "~/redux/warehouse/bin.hooks";
import { useStartInductionMutation } from "~/redux/warehouse/workstation.hooks";
import {
  AutostoreEvent,
  NextEmptyBinResponse,
  PutAwayTaskSummaryDto,
  SuggestBinConfigurationResponse
} from "~/types/api";

import { AutostorePutawayOldSearch } from "./AutostorePutawayOldSearch";
import { AutostorePutawayOldToolbar } from "./AutostorePutawayOldToolbar";
import PutawayTasksTableOld from "./PutawayTasksTableOld";

import {
  setSearchData,
  selectRow,
  setIsPutawayTaskTableRefreshed,
  setOnLeaveInputTextShown,
  setChangeQuantity
} from "./autostorePutawayOld.slice";

const mapStateToProps = (state: StoreState) => ({
  selectedAutostoreGridId: state.workstations.siteWorkstation?.autostoreGridId,
  putawayTasks: state.autostore.putawayTasks,
  putawayTasksLoading: state.autostore.putawayTasksLoading,
  /** response from nextEmptyBin call */
  nextEmptyBinByPort: state.autostore.nextEmptyBinByPort,
  selectedBinConfigurations: state.autostore.selectedBinConfigurations,
  successPutawayCompletion: state.putaway.successMessage,
  errorPutawayCompletion: state.putaway.error,
  /** portId for independent or parentPortId for multi-port */
  selectedPortId: state.workstations.sitePortId,
  portStateByPort: state.autostore.portStateByPort,
  clientId: getUserClientId(state.login.profile),
  inv_inventoryDateLabel: state.site.clientConfig.inv_inventoryDateLabel,
  putaway_multipleSearchTerms:
    state.site.clientConfig.putaway_multipleSearchTerms,
  siteAllPortIds: state.workstations.siteAllPortIds,
  fulfillmentCenter: state.store.usersFulfillmentCenter,
  inv_inventoryDateRequired: state.site.clientConfig.inv_inventoryDateRequired,
  siteWorkstation: state.workstations.siteWorkstation,
  clientConfig: state.site.clientConfig,
  suggestedBin: state.autostore.suggestedBin,
  currentSelectedBin: state.autostore.currentEmptyBin,
  automatedOperationsEnabled: state.site.automatedOperationsEnabled
});

const connector = connect(mapStateToProps, {
  fetchPutawayTasks,
  adjustInventory,
  getBinById,
  clearErrorMessage,
  clearSuccessMessage,
  clearInventoryMessage,
  nextEmptyBin,
  fetchPortStatus,
  setPortStatus,
  closeBin,
  setSelectedAutostoreBinId,
  findInventoryByAutostoreBinNumber,
  clearSelectedVariant,
  putAwayItem,
  setSelectedBinConfigurations,
  resetPortBinData,
  setUserMessage,
  setCurrentEmptyBin,
  closeWorkstation
});

type Props = ConnectedProps<typeof connector> & { viewTitle: string };

function AutostorePutawayOld(props: Props) {
  const {
    putawayTasks,
    putawayTasksLoading,
    nextEmptyBinByPort,
    successPutawayCompletion,
    errorPutawayCompletion,
    selectedAutostoreGridId,
    selectedPortId,
    portStateByPort,
    selectedBinConfigurations,
    siteAllPortIds,
    inv_inventoryDateLabel,
    putaway_multipleSearchTerms,
    inv_inventoryDateRequired,
    viewTitle,
    clientId,
    siteWorkstation,
    clientConfig,
    suggestedBin,
    currentSelectedBin,
    closeWorkstation,
    setUserMessage
  } = props;
  const currentSelectedPortId = currentSelectedBin?.openBinResponse.portId;
  const currentSelectedBinId = currentSelectedBin?.openBinResponse.binId;

  const [startInduction] = useStartInductionMutation();

  const { data: selectedAutostoreGrid } = useGetAutostoreGridQuery(
    selectedAutostoreGridId ?? skipToken
  );

  const { data: availableBinConfigurations } = useGetBinConfigurationsQuery();

  // Hooks
  const { width } = useWindowDimensions();

  const { t } = useTranslation();
  const { errorToast } = useToast();
  const shouldListenToGridEvents = useShouldListenToGridEvents();
  useKeyDownHandler();
  const { setMenuItems, setToolbar } = useNavbar({
    centerComponent: useMemo(() => <AutostorePutawayOldSearch />, []),
    viewTitle
  });
  const {
    areAllPortsReady,
    areAllPortsOpen,
    firstPort,
    isSelectedBinReady,
    portStateByPortArray
  } = usePortStatus(portStateByPort, siteAllPortIds, currentSelectedPortId);
  const [scanState, setScanState] = useScanIndicator();
  const prevSuggestedBin = usePrevious<SuggestBinConfigurationResponse | null>(
    suggestedBin
  );

  const dispatch = useAppDispatch();

  const changeQuantity = useAppSelector(
    (state) => state.autostorePutawayOld.changeQuantity
  );
  const isPutawayTaskTableRefreshed = useAppSelector(
    (state) => state.autostorePutawayOld.isPutawayTaskTableRefreshed
  );
  const searchData = useAppSelector(
    (state) => state.autostorePutawayOld.searchData
  );
  const selectedRowId = useAppSelector(
    (state) => state.autostorePutawayOld.selectedRowId
  );

  // Local State - Modals
  const [isPutawayModalOpen, setIsPutawayModalOpen] = useState(false);
  const [changeSuggestedBinModalOpen, setChangeSuggestedBinModalOpen] =
    useState(false);
  const [isAddInventoryPanelOpen, setAddInventoryPanelOpen] = useState(false);
  const [isInventoryHoldModalOpen, setIsInventoryHoldModalOpen] =
    useState(false);
  const [isFlagBinModalOpen, setIsFlagBinModalOpen] = useState(false);
  const [isDecantRateModalOpen, setIsDecantRateModalOpen] =
    useState<boolean>(false);

  // Local State
  const [binExceptionsMenuAnchor, setBinExceptionsMenuAnchor] =
    useState<null | HTMLElement>(null);
  const isBinExceptionsMenuOpen = !!binExceptionsMenuAnchor;
  const closeBinExceptionsMenu = () => setBinExceptionsMenuAnchor(null);
  const [binComponentDimensions, setBinComponentDimensions] = useState<
    Record<string, number>
  >({ numOfRows: 1, numOfCols: 1 });

  const selectedRow = putawayTasks.find(
    (task) => task.putAwayTaskId === selectedRowId
  );
  const [changedQuantity, setChangedQuantity] = useState<number | null>(null);
  const [closePortBtnDisabled, disableClosePortBtn] = useState(true);
  const [inventoryDate, setInventoryDate] = useState<Dayjs | null>(
    dayjs().utc().add(1, "year")
  );
  const [portPollingActive, setPortPollingActive] = useState<boolean>(false);
  // Compartment number (0-3)
  const [selectedCompartment, setSelectedCompartment] = useState<
    number | undefined
  >(undefined);
  // Compartment number (0-3) used for selecting a new compartment
  const [newSelectedCompartment, setNewSelectedCompartment] = useState<
    number | null
  >(null);
  const [binAtPortSeconds, setBinAtPortSeconds] = useState(0);

  const [isPlaceHoldDisabled, setIsPlaceHoldDisabled] = useState(true);
  const [lastScannedBarcode, setLastScannedBarcode] = useState<string | null>(
    null
  );
  const [hasStartInductionRun, setHasStartInductionRun] =
    useState<boolean>(false);

  // Misc
  const workstationId = siteWorkstation?.id;
  const emptyCompartmentNumbers =
    getEmptyCompartmentNumbers(currentSelectedBin);
  const selectedCompartmentBinId =
    !!(selectedCompartment || selectedCompartment === 0) &&
    currentSelectedBin?.autostoreBinCompartments.find(
      (compartment) =>
        compartment.autostoreCompartmentNumber === selectedCompartment + 1
    )?.binId;
  const hasSuggestedBinChanged =
    suggestedBin && suggestedBin !== prevSuggestedBin;
  const pageLimit = 5;
  const browserWidth =
    width < 1280
      ? ternaryIff<"xs" | "sm" | "md">(
          width > 960,
          "md",
          ternaryIff<"xs" | "sm">(width > 600, "sm", "xs")
        )
      : "lg";

  // Action button disabled states
  const shouldShowActionButtons =
    putawayTasksLoading || putawayTasks.length >= 0;
  const isPutawayButtonDisabled =
    (clientConfig.putaway_showDecantRate &&
      !selectedRow?.decantingRate?.value) ||
    !selectedRow ||
    (selectedCompartment && selectedCompartment < 0) ||
    (portPollingActive && !shouldListenToGridEvents) ||
    !isSelectedBinReady;
  const isBinNotEmptyButtonDisabled =
    (portPollingActive && !shouldListenToGridEvents) ||
    !areAllPortsOpen ||
    !areAllPortsReady;

  // Single port only
  const isChangeBinConfigButtonDisabled =
    putawayTasks.length === 0 || !areAllPortsReady || !areAllPortsOpen;
  const isWaitingForBin =
    (portPollingActive && !shouldListenToGridEvents) ||
    (shouldListenToGridEvents &&
      !(
        firstPort?.getPortResponse.mode === "OPEN" &&
        firstPort?.getPortResponse.isReady
      ));
  const singlePortBinId =
    firstPort &&
    ((!portPollingActive && !shouldListenToGridEvents) ||
      (areAllPortsReady && shouldListenToGridEvents))
      ? firstPort.getPortResponse.selectedBin
      : undefined;

  const reset = useCallback(() => {
    dispatch(selectRow(null));
    setNewSelectedCompartment(null);
    setIsPutawayModalOpen(false);
    setSelectedCompartment(undefined);
    setInventoryDate(dayjs().add(1, "year"));
    setLastScannedBarcode(null);
  }, [dispatch]);

  const handleBarcodeScan = (barcode: string) => {
    // if client expects to scan PO and product for one search (search within a search)
    if (putaway_multipleSearchTerms) {
      // if there are already 2 values in the search or the current search has no results, clear and set to new barcode value
      const newBarcodeArray =
        searchData.scannedBarcodes.length > 1 || putawayTasks.length === 0
          ? [barcode]
          : [...searchData.scannedBarcodes, barcode];
      dispatch(
        setSearchData({
          scannedBarcodes: newBarcodeArray,
          offset: 1
        })
      );
    } else {
      dispatch(
        setSearchData({
          scannedBarcodes: [barcode],
          offset: 1
        })
      );
    }
  };

  useBarcodeScanner<boolean>({
    findScanMatch: (buffer: string) => {
      setLastScannedBarcode(buffer);
      const barcodeValue = getBarcodeValue(buffer);
      handleBarcodeScan(barcodeValue);
      setScanState("success");
      return true;
    },
    processScanMatch: () => null
  });

  // Clear port and bin state on first render
  useEffect(() => {
    props.resetPortBinData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // component did unmount
  useEffect(
    () => () => {
      dispatch(selectRow(null));
      dispatch(
        setSearchData({
          scannedBarcodes: [],
          offset: 1
        })
      );
      reset();
    },
    [dispatch, reset]
  );

  // Set current bin
  useEffect(() => {
    if (
      // if no suggested bin and we don't care about suggested bin, set to first bin
      !suggestedBin &&
      !clientConfig.putaway_showDecantRate &&
      firstPort &&
      nextEmptyBinByPort
    ) {
      props.setCurrentEmptyBin(
        nextEmptyBinByPort[firstPort.getPortResponse.portId]
      );
    } else if (!selectedRow) {
      // after putaway is complete, row will be unselected, unselect bin/port
      props.setCurrentEmptyBin(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextEmptyBinByPort, hasSuggestedBinChanged, firstPort, selectedRow]);

  // After scanning barcode, if there are no putaway tasks returned check backend products.
  // If product doesn't exist display an error.

  useEffect(() => {
    if (putawayTasks.length === 0 && searchData.scannedBarcodes.length) {
      const productSearchFunc = async () => {
        const hits = clientId
          ? await searchProduct(searchData.scannedBarcodes)
          : [];
        if (!hits.length) {
          setUserMessage({
            title: t("scanned product does not exist"),
            severity: "error",
            origin: "putaway/SCANNED_PRODUCT_DOES_NOT_EXIST"
          });
        }
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      productSearchFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putawayTasks.length]);

  const handleSetBinComponentDimensions = () => {
    if (currentSelectedBin?.autostoreBinConfiguration) {
      setBinComponentDimensions({
        numOfRows:
          currentSelectedBin?.autostoreBinConfiguration
            .horizontalCompartmentCount,
        numOfCols:
          currentSelectedBin?.autostoreBinConfiguration.verticalCompartmentCount
      });
    } else {
      setBinComponentDimensions({ numOfRows: 1, numOfCols: 1 });
    }
  };

  const handleFetchPortStatus = async (portId?: number) => {
    await props.fetchPortStatus({ portId });
  };

  // Port polling: if log publisher enabled, poll port and log publisher state
  const waitInterval = 7;
  usePromiseInterval(
    async () => {
      setBinAtPortSeconds((binAtPortSecondsState) => binAtPortSecondsState + 1);
      if (binAtPortSeconds > waitInterval) {
        await handleFetchPortStatus();
      }
    },
    1000,
    !areAllPortsReady && shouldListenToGridEvents
  );

  // If port polling active but log publisher not active for grid, poll port every half second
  usePromiseInterval(
    async () => {
      await Promise.all(
        (siteWorkstation?.ports ?? []).map((port) =>
          handleFetchPortStatus(port.portId)
        )
      );
    },
    500,
    portPollingActive && !shouldListenToGridEvents
  );

  // Reset binAtPortSeconds, when bin arrives at port
  useEffect(() => {
    if (areAllPortsReady && binAtPortSeconds > 0) {
      setBinAtPortSeconds(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areAllPortsReady]);

  // Stop polling when bin arrives
  useEffect(() => {
    if (!shouldListenToGridEvents && areAllPortsReady) {
      setPortPollingActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portStateByPort]);

  // TODO: Debounce an issue here?
  const debouncedSearchData = useDebounce(searchData, 200);
  const handlePutawayTaskFetch = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props.fetchPutawayTasks({
      search: searchData.searchInputValue,
      searches: searchData.scannedBarcodes,
      binType: ["induction", "receiving"],
      limit: pageLimit,
      offset: (searchData.offset - 1) * pageLimit,
      temperatureZone: selectedAutostoreGrid
        ? [selectedAutostoreGrid.temperatureZone.toLowerCase()]
        : [],
      searchSource: searchData.scannedBarcodes.length ? "scanner" : undefined,
      workstationId
    });
  };

  // Search tasks on barcode scan or input entry
  useEffect(() => {
    handlePutawayTaskFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchData]);

  const handleSetSuggestedCompartment = () => {
    if (currentSelectedBin) {
      const suggestedCompartment =
        suggestedBin?.binNumber &&
        suggestedBin.binCompartmentNumber !== undefined &&
        suggestedBin.binNumber === currentSelectedBin.openBinResponse.binId
          ? suggestedBin.binCompartmentNumber
          : currentSelectedBin.suggestedPutawayCompartment;
      setSelectedCompartment((suggestedCompartment || 1) - 1);
    }
  };

  // Set suggested compartment and bin dimensions
  useEffect(() => {
    if (!currentSelectedBin) {
      return;
    }
    handleSetBinComponentDimensions();
    if (newSelectedCompartment === null) {
      handleSetSuggestedCompartment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedBinId]);

  // If there's only one putaway default to that task
  useEffect(() => {
    if (putawayTasks.length === 1) {
      dispatch(selectRow(putawayTasks[0].putAwayTaskId));
    }
  }, [dispatch, putawayTasks]);

  // Set inventory date to display
  useEffect(() => {
    if (checkIsExpiration(inv_inventoryDateLabel)) {
      setInventoryDate(
        selectedRow?.expirationDate ? dayjs(selectedRow.expirationDate) : null
      );
    } else {
      setInventoryDate(
        selectedRow?.manufactureDate ? dayjs(selectedRow.manufactureDate) : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  // Enable/disable showing text in search bar
  useEffect(() => {
    dispatch(setOnLeaveInputTextShown(!isPutawayTaskTableRefreshed));
  }, [isPutawayTaskTableRefreshed, dispatch]);

  // Clear selected row
  useEffect(() => {
    if (putawayTasks.length === 0 && !putawayTasksLoading) {
      dispatch(selectRow(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putawayTasks.length]);

  // Enable/disable the 'place inventory hold' button
  useEffect(() => {
    const checkInventoryAvailabilityForPlaceHold = () => {
      const inventoryPromises = [];
      if (selectedAutostoreGridId) {
        for (const port of portStateByPortArray) {
          if (port.getPortResponse.selectedBin === 0) {
            inventoryPromises.push(Promise.resolve(false));
            break;
          }

          inventoryPromises.push(
            props
              .findInventoryByAutostoreBinNumber(
                selectedAutostoreGridId,
                port.getPortResponse.selectedBin
              )
              .then((response) => response?.length && response.length > 0)
          );
        }
      }
      return Promise.all(inventoryPromises);
    };

    if (portStateByPortArray.length && areAllPortsReady) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      checkInventoryAvailabilityForPlaceHold().then((results) => {
        setIsPlaceHoldDisabled(!results.some((inventory) => inventory));
      });
    } else setIsPlaceHoldDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portStateByPortArray]);

  const gridSub = (data: AutostoreEvent) => {
    if (data.case !== "BinModeChange" || data.event.binMode !== "O") {
      return;
    }

    if (
      data.event.gridId === props.selectedAutostoreGridId &&
      !!data.event.portId &&
      siteAllPortIds.includes(data.event.portId)
    ) {
      // if "binOpened" event is received, trigger port polling
      setBinAtPortSeconds((time) => time + waitInterval);
    }
  };

  useGridV2Subscription(gridSub);

  // For now, we don't have something like 'close bin' event
  // So there is no way to know when this operation will be completed
  // Because of that, we periodically check if bin is closed
  /** Specific to single port workstation, run through the close port, open port, get next empty bin flow */
  const fetchNextEmptyBin = useCallback(() => {
    const id = setInterval(() => {
      if (!!selectedAutostoreGridId && !!selectedPortId) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        props.fetchPortStatus().then((portStatus) => {
          if (portStatus && portStatus.selectedBin === 0) {
            clearInterval(id);
            props.nextEmptyBin().catch((error: AxiosError) => {
              errorToast(getAxiosErrorMessage(error));
              if (portStatus.mode === "OPEN") {
                if (!shouldListenToGridEvents) {
                  setPortPollingActive(false);
                }
                if (!closePortBtnDisabled) {
                  disableClosePortBtn(true);
                }
              }
            });
            if (!shouldListenToGridEvents) {
              setPortPollingActive(true);
            }
            reset();
          }
        });
      }
    }, 500);
  }, [
    closePortBtnDisabled,
    errorToast,
    props,
    reset,
    selectedAutostoreGridId,
    selectedPortId,
    shouldListenToGridEvents
  ]);

  // Call close workstation, start induction, and get empty bin
  useEffect(() => {
    if (
      !hasStartInductionRun &&
      selectedBinConfigurations.length === 0 &&
      !!workstationId &&
      !!selectedAutostoreGridId &&
      !!selectedPortId
    ) {
      const handleGetEmptyBin = () => {
        fetchNextEmptyBin();
      };

      setHasStartInductionRun(true);

      void closeWorkstation()
        .then(async () => {
          try {
            await startInduction({
              autostoreGridId: selectedAutostoreGridId,
              workstationId: workstationId
            });
          } catch (err) {
            errorToast(getMessageFromRtkError(err));
          }
        })
        .then(handleGetEmptyBin);
    }
  }, [
    selectedAutostoreGridId,
    selectedPortId,
    selectedBinConfigurations,
    hasStartInductionRun,
    workstationId,
    closeWorkstation,
    startInduction,
    fetchNextEmptyBin,
    errorToast
  ]);

  const handleClickBinNotEmptyButton = () => {
    if (currentSelectedBin) {
      Sentry.captureMessage(
        `Bin not empty was clicked on bin number ${currentSelectedBin.openBinResponse.binId}`,
        "info"
      );
      if (shouldListenToGridEvents) {
        setBinAtPortSeconds(0);
      }
      if (currentSelectedBin) {
        setAddInventoryPanelOpen(true);
      }
    }
  };

  const handleClickPlaceInventoryHold = () => {
    closeBinExceptionsMenu();
    setIsInventoryHoldModalOpen((prev) => !prev);
  };

  const handleClickFlagBin = () => {
    closeBinExceptionsMenu();
    setIsFlagBinModalOpen((prev) => !prev);
  };

  const onPutawayButtonClick = async (): Promise<void> => {
    if (!selectedRow || !selectedCompartmentBinId) {
      throw new Error("Selected row or compartment bin id is undefined");
    }
    await props
      .putAwayItem({
        putAwayTaskId: selectedRow.putAwayTaskId,
        quantity: {
          value: changedQuantity || selectedRow.remaining.value,
          units: selectedRow.remaining.units
        },
        targetBinId: selectedCompartmentBinId,
        targetPort: {
          gridId: selectedAutostoreGridId || "",
          portId: currentSelectedPortId || 0
        },
        ...getInventoryDateObjDayjs(inv_inventoryDateLabel, inventoryDate),
        workstationId
      })
      .then(() => {
        setIsPutawayModalOpen(false);
        if (putaway_multipleSearchTerms)
          dispatch(setSearchData({ ...searchData, scannedBarcodes: [] }));
      });

    fetchNextEmptyBin();

    // last task on selected page but not a partial putaway - clear search, refresh list
    if (
      putawayTasks.length === 1 &&
      !(changedQuantity && changedQuantity !== selectedRow.remaining.value)
    ) {
      if (searchData.offset !== 1) {
        // user is not on first page, send them back a page
        dispatch(
          setSearchData({ ...searchData, offset: searchData.offset - 1 })
        );
      } else {
        // user is on first page, clear search, then refetch putaways
        if (searchData.searchInputValue || searchData.scannedBarcodes.length) {
          dispatch(
            setSearchData({
              scannedBarcodes: [],
              offset: 1
            })
          );
        }
        setIsPutawayTaskTableRefreshed(true);
      }
      // not the last task or any type of partial putaway - keep search term, refetch results
    } else if (
      putawayTasks.length > 1 ||
      (changedQuantity && changedQuantity !== selectedRow.remaining.value)
    ) {
      // fetch tasks after putaway is performed
      handlePutawayTaskFetch();
    }
  };

  useEffect(() => {
    if (
      envConstants.ENABLE_AUTOMATED_OPERATIONS === "true" &&
      props.automatedOperationsEnabled
    ) {
      if (putawayTasks.length && !selectedRow) {
        dispatch(
          selectRow(
            putawayTasks[Math.floor(Math.random() * putawayTasks.length)]
              .putAwayTaskId
          )
        );
      }
      if (isSelectedBinReady && selectedRow) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        onPutawayButtonClick().finally(handlePutawayTaskFetch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedBinReady, selectedRow, putawayTasks]);

  const onConfirmChangeSuggestedBin = (
    bin: NextEmptyBinResponse,
    compartmentNumber: number
  ) => {
    props.setCurrentEmptyBin(bin);
    setSelectedCompartment(compartmentNumber);
  };

  const inventoryAddPanelRefreshCb = () => {
    if (!currentSelectedBin) {
      return;
    }
    closeBinExceptionsMenu();
    if (currentSelectedBin) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props
        .closeBin({
          binId: currentSelectedBin.openBinResponse.binId,
          taskId: currentSelectedBin.openBinResponse.taskId
        })
        .then(() => fetchNextEmptyBin());
    }
  };

  const putawayAdjustModalSuccessCallback = () => {
    dispatch(setChangeQuantity(false));
    handlePutawayTaskFetch();
    dispatch(selectRow(null));
  };

  const handleClickBinExceptionsButton = (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    setBinExceptionsMenuAnchor(event.currentTarget);
  };

  const handleSelectRow = (row: PutAwayTaskSummaryDto) => {
    if (row.putAwayTaskId !== selectedRowId) {
      setNewSelectedCompartment(null);
      dispatch(selectRow(row.putAwayTaskId));
      handleSetSuggestedCompartment();
    }
  };

  useEffect(() => {
    setMenuItems([
      {
        textContent: "Refresh",
        actionCb: () => {
          dispatch(setIsPutawayTaskTableRefreshed(true));
          setSearchData({
            scannedBarcodes: [],
            offset: 1
          });
          dispatch(selectRow(null));
        }
      }
    ]);
  }, [dispatch, setMenuItems]);

  useEffect(() => {
    if (selectedRow && clientConfig.putaway_showExceptionsButton) {
      setToolbar(<AutostorePutawayOldToolbar />);
    } else {
      setToolbar(undefined);
    }
  }, [clientConfig.putaway_showExceptionsButton, selectedRow, setToolbar]);

  return (
    <Grid
      container
      direction="column"
      flexWrap="nowrap"
      height="calc(100vh - 56px)"
    >
      <Grid container item xs={12} direction="row" p={2} height={"unset"}>
        <Grid item xs={4}>
          {selectedRow ? (
            <UniversalProductCard
              productName={selectedRow.product.name}
              imageFileName={selectedRow.product.imageFilename}
              sku={selectedRow.product.sku}
              upc={selectedRow.product.upc}
              weight={`${selectedRow.remaining.value} ${selectedRow.remaining.units}`}
              cardHeight="500px"
            />
          ) : (
            <PickInfoIsLoading marginTop="0" height={490} />
          )}

          {!siteWorkstation && (
            <ErrorPanel m={3} message={t("no autostore port selected")} />
          )}
          <Grid item xs={12} container marginTop={4} justifyContent="center">
            <Grid item xs={12} textAlign="center">
              {availableBinConfigurations &&
                availableBinConfigurations.length > 1 && (
                  <ProgressButton
                    style={{
                      minWidth: "156px"
                    }}
                    id="change-bin config-button"
                    data-testid="change-bin-config-button"
                    buttonSize="small"
                    emphasis="low"
                    responsive
                    variant="contained"
                    color="primary"
                    disabled={isChangeBinConfigButtonDisabled}
                    startIcon={<ViewModuleIcon style={{ fontSize: 22 }} />}
                    onClick={() => {
                      dispatch(openBinConfigModal());
                    }}
                  >
                    {t("change bin config")}
                  </ProgressButton>
                )}
            </Grid>
            <Grid item xs={12} height="32px">
              {!!singlePortBinId && (
                <Typography
                  variant="h5"
                  style={{
                    textAlign: "center",
                    width: "100%"
                  }}
                >
                  {firstPort?.getPortResponse.selectedBin
                    ? `${t("bin")} ${firstPort.getPortResponse.selectedBin}`
                    : undefined}
                </Typography>
              )}
            </Grid>
            <AutostoreBin
              state={
                !areAllPortsOpen
                  ? "Port Closed"
                  : isWaitingForBin
                    ? "Waiting for Bin"
                    : "Bin Opened"
              }
              pickQuantity={0}
              pickCompartment={null}
              numberOfRows={binComponentDimensions.numOfRows}
              numberOfColumns={binComponentDimensions.numOfCols}
              binId={singlePortBinId}
              hideBinId
              selectedCompartment={selectedCompartment}
              setSelectedCompartmentCallback={setSelectedCompartment}
              emptyCompartments={emptyCompartmentNumbers}
            />
          </Grid>
        </Grid>

        <Grid item xs={8} height="850px">
          <PutawayTasksTableOld
            inv_inventoryDateLabel={inv_inventoryDateLabel}
            pageLimit={pageLimit}
            putawayTasks={putawayTasks}
            putawayTasksLoading={putawayTasksLoading}
            selectedRow={selectedRow}
            handleSelectRow={handleSelectRow}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} direction={"row"} flexWrap="nowrap">
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignContent="center"
          style={{ maxWidth: "1200px", margin: "0 auto" }}
        >
          {shouldShowActionButtons && (
            <ActionButtons
              areAllPortsOpen={areAllPortsOpen}
              areAllPortsReady={areAllPortsReady}
              binExceptionsMenuAnchor={binExceptionsMenuAnchor}
              clientConfig={clientConfig}
              closeBinExceptionsMenu={closeBinExceptionsMenu}
              currentSelectedBin={currentSelectedBin}
              handleClickBinExceptionsButton={handleClickBinExceptionsButton}
              handleClickBinNotEmptyButton={handleClickBinNotEmptyButton}
              handleClickFlagBin={handleClickFlagBin}
              handleClickPlaceInventoryHold={handleClickPlaceInventoryHold}
              isBinExceptionsMenuOpen={isBinExceptionsMenuOpen}
              isBinNotEmptyButtonDisabled={isBinNotEmptyButtonDisabled}
              isPutawayButtonDisabled={isPutawayButtonDisabled}
              shouldListenToGridEvents={shouldListenToGridEvents}
              isPlaceHoldDisabled={isPlaceHoldDisabled}
              portStateByPortArray={portStateByPortArray}
              selectedRow={selectedRow}
              setChangeSuggestedBinModalOpen={setChangeSuggestedBinModalOpen}
              setIsDecantRateModalOpen={setIsDecantRateModalOpen}
              setIsPutawayModalOpen={setIsPutawayModalOpen}
            />
          )}
        </Grid>
      </Grid>

      {/* Modals */}
      <AutostoreBinConfigModal
        dimensionsCallback={setBinComponentDimensions}
        currentBinConfigurations={selectedBinConfigurations}
        saveBinConfigurations={props.setSelectedBinConfigurations}
      />
      <AutostorePutawayModal
        inv_inventoryDateRequired={inv_inventoryDateRequired}
        open={isPutawayModalOpen}
        onClose={() => {
          setIsPutawayModalOpen(false);
        }}
        maxWidth={browserWidth}
        browserWidth={width}
        binId={
          currentSelectedBin ? currentSelectedBin.openBinResponse?.binId : null
        }
        onPutawayButtonClick={onPutawayButtonClick}
        changeQuantityFunc={setChangedQuantity}
        inventoryDate={inventoryDate}
        onDateChange={setInventoryDate}
        numberOfRows={binComponentDimensions.numOfRows}
        numberOfColumns={binComponentDimensions.numOfCols}
        selectedCompartment={selectedCompartment}
        emptyCompartments={emptyCompartmentNumbers}
        inv_inventoryDateLabel={inv_inventoryDateLabel}
        decantRate={selectedRow?.decantingRate}
        remaining={selectedRow?.remaining}
        isExpirationRequired={selectedRow?.product.isExpirationRequired}
      />
      <InventoryHoldModal
        isOpen={isInventoryHoldModalOpen}
        onClose={() => setIsInventoryHoldModalOpen(false)}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        currentSelectedBin={currentSelectedBin}
      />
      <FlagBinModal
        isOpen={isFlagBinModalOpen}
        onClose={() => setIsFlagBinModalOpen(false)}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        workstation={siteWorkstation}
        shouldCloseBinUponSubmit
      />
      {!!currentSelectedBin &&
        (selectedCompartment || selectedCompartment === 0) && (
          <ChangeSuggestedBinModal
            isOpen={changeSuggestedBinModalOpen}
            onClose={() => setChangeSuggestedBinModalOpen(false)}
            nextEmptyBinByPort={nextEmptyBinByPort}
            portStateByPort={portStateByPort}
            workstation={siteWorkstation}
            currentSelectedBin={currentSelectedBin}
            onConfirm={onConfirmChangeSuggestedBin}
            currentSelectedCompartment={selectedCompartment}
            newSelectedCompartment={newSelectedCompartment}
            setNewSelectedCompartment={setNewSelectedCompartment}
          />
        )}
      {currentSelectedBin && currentSelectedPortId && (
        <InventoryAddDialog
          open={isAddInventoryPanelOpen}
          onClose={() => {
            setAddInventoryPanelOpen(false);
          }}
          autostorePortId={currentSelectedPortId}
          binId={selectedCompartmentBinId || null}
          refreshCb={inventoryAddPanelRefreshCb}
          disableClosePortOnClose
        />
      )}
      <Dialog
        onClose={() => setIsDecantRateModalOpen(false)}
        open={isDecantRateModalOpen}
      >
        {selectedRow && (
          <DecantRateModal
            putAwayTask={selectedRow}
            closeModal={() => setIsDecantRateModalOpen(false)}
            successCallback={() => {
              handlePutawayTaskFetch();
            }}
          />
        )}
      </Dialog>
      <ErrorSuccessSnackbar
        successMessage={successPutawayCompletion}
        errorMessage={errorPutawayCompletion}
        clearErrorMessage={() => {
          props.clearErrorMessage();
        }}
        clearSuccessMessage={() => {
          props.clearInventoryMessage();
          props.clearSuccessMessage();
        }}
      />
      <ScanningIndicator
        scanState={scanState}
        scannedBarcode={lastScannedBarcode}
        placeholderText="Scan Product"
      />
      <Dialog
        open={changeQuantity}
        onClose={() => dispatch(setChangeQuantity(false))}
        aria-labelledby="InventoryAdjustPanel-header-text"
      >
        {changeQuantity && selectedRow && (
          <PutawayAdjustModal
            putawayTask={selectedRow}
            closeModal={() => dispatch(setChangeQuantity(false))}
            successCallback={putawayAdjustModalSuccessCallback}
          />
        )}
      </Dialog>
    </Grid>
  );
}

export default connector(AutostorePutawayOld);
