import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { navBarHeight } from "~/components/navbar/Navbar";
import Andon from "~/features/andon/Andon";
import { selectIsAndonBoardFullScreen } from "~/features/andon/andon.slice";
import { selectEnableDevInformation } from "~/features/settings/devSettings.slice";
import { Status } from "~/features/status/Status";
import { TaskAssessment } from "~/features/status/TaskAssessment";
import { useNavbar } from "~/hooks/useNavbar";
import { useTabNavLocation } from "~/hooks/useTabNavLocation";
import { useView } from "~/hooks/useView";

import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import Configurations from "./Configurations";
import ServiceSupport from "./ServiceSupport";

type ServiceSupportViewProps = {
  viewTitle?: string;
};

const ServiceSupportView = (props: ServiceSupportViewProps): JSX.Element => {
  const { viewTitle } = props;
  const { t } = useTranslation();

  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const shouldRenderTasksTab =
    usersFulfillmentCenter?.pickingConfigurations.includes("Autostore");

  useView({ permanentSidenav: true, fixedHeight: true });
  useNavbar({ viewTitle });

  const clientConfig = useAppSelector(selectClientConfig);
  const isAndonBoardFullScreen = useAppSelector(selectIsAndonBoardFullScreen);
  const { an_andonFeaturesEnabled, an_displayEventFeatures } = clientConfig;

  const shouldDisplayAndonTab =
    an_andonFeaturesEnabled || an_displayEventFeatures;
  const shouldRenderConfigurationsTab = useAppSelector(
    selectEnableDevInformation
  );

  const validTabs = [
    "service-support",
    ...(shouldRenderConfigurationsTab ? ["configurations"] : []),
    "status",
    ...(shouldRenderTasksTab ? ["tasks"] : []),
    ...(shouldDisplayAndonTab ? ["andon"] : [])
  ];

  const { tabIndex, handleTabIndexChange } = useTabNavLocation({
    defaultTab: "service-support",
    validTabs,
    key: "service-support-tab",
    subTabs: [
      {
        subTab: "configurations",
        subTabKey: "configurations-tab",
        subTabDefault: "client-configurations"
      },
      {
        subTab: "andon",
        subTabKey: "andon-tab",
        subTabDefault: "andon-board"
      },
      {
        subTab: "status",
        subTabKey: "status-tab",
        subTabDefault: "ports-status"
      }
    ]
  });

  const isCurrentTabAndonAndIsFullscreenMode =
    tabIndex === "andon" && isAndonBoardFullScreen;
  return (
    <Box
      sx={{
        height: isCurrentTabAndonAndIsFullscreenMode
          ? "100vh"
          : `calc(100vh - ${navBarHeight})`,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        p: 2
      }}
    >
      {!isCurrentTabAndonAndIsFullscreenMode && (
        <Tabs
          value={tabIndex}
          onChange={(_event, newValue) =>
            handleTabIndexChange(newValue as string)
          }
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={t("service and support")} value="service-support" />
          <Tab label={t("status")} value="status" />
          {shouldRenderTasksTab && <Tab label={t("Tasks")} value="tasks" />}
          {shouldDisplayAndonTab && <Tab label={t("Andon")} value="andon" />}
          {shouldRenderConfigurationsTab && (
            <Tab label={t("configurations")} value="configurations" />
          )}
        </Tabs>
      )}
      <Box
        sx={{
          height: "calc(100% - 48px)"
        }}
      >
        {tabIndex === "service-support" && <ServiceSupport />}
        {tabIndex === "status" && <Status />}
        {tabIndex === "andon" && shouldDisplayAndonTab && <Andon />}
        {tabIndex === "tasks" && shouldRenderTasksTab && <TaskAssessment />}
        {tabIndex === "configurations" && shouldRenderConfigurationsTab && (
          <Configurations />
        )}
      </Box>
    </Box>
  );
};

export default ServiceSupportView;
