import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from "@hello-pangea/dnd";

import CloseIcon from "@mui/icons-material/Close";
import { ButtonBase } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { ProgressButton } from "frontend-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "~/app/store";

import { figureAndonColorFromStatus } from "~/lib/helpers";

import { WorkstationSummaryDto, WorkstationPortDto } from "~/types/api";

import WorkstationSelectorModal from "./WorkstationSelectorModal";
import {
  selectAndonCompactViewsIds,
  selectAndonGrids,
  selectAndonWorkstations,
  setAndonViewOption,
  setCompactViewIds
} from "./andon.slice";

type WSPortsGroupedByParent = {
  [parentPortIdString: string]: WorkstationPortDto[];
};

const groupByParentPorts = (
  ws: WorkstationSummaryDto
): WSPortsGroupedByParent =>
  ws.ports.reduce((acc: Record<string, WorkstationPortDto[]>, port) => {
    const parentPortIdKey = port.parentPortId
      ? String(port.parentPortId)
      : "Unassigned";

    return {
      ...acc,
      [parentPortIdKey]: [...(acc[parentPortIdKey] || []), port]
    };
  }, {});

export function AndonScreen() {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [workstationSelectorOpen, setWorkstationSelectorOpen] = useState(false);

  const dispatch = useAppDispatch();

  const compactViewIds = useAppSelector(selectAndonCompactViewsIds);
  const andonGrids = useAppSelector(selectAndonGrids);
  const andonWorkstations = useAppSelector(selectAndonWorkstations);

  const reorder = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleRemoveAndonWorkstation = (workstationId: string) => {
    dispatch(
      setCompactViewIds(compactViewIds.filter((id) => id !== workstationId))
    );
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) return;

    const newItems = reorder(
      compactViewIds,
      result.source.index,
      result.destination.index
    );
    dispatch(setCompactViewIds(newItems));
  };

  return (
    <Box sx={{ height: "100%" }}>
      {/* buttons container */}
      <Box
        sx={{
          height: "10%",
          marginLeft: "20px",
          marginRight: "20px",
          display: "flex",
          width: "100%",
          alignItems: "center"
        }}
      >
        <ProgressButton
          emphasis="high"
          responsive
          variant="contained"
          color="primary"
          onClick={() => {
            setWorkstationSelectorOpen(true);
          }}
        >
          {t("select workstations")}
        </ProgressButton>
        <ProgressButton
          emphasis="high"
          responsive
          variant="contained"
          color="primary"
          sx={{ marginLeft: "10px" }}
          onClick={() => {
            dispatch(setAndonViewOption("compact fullscreen"));
          }}
        >
          {t("full screen")}
        </ProgressButton>
      </Box>
      {/* dropbox container */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "95%",
          height: "80%",
          margin: "auto"
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  background: snapshot.isDraggingOver
                    ? palette.darkGray.light
                    : palette.darkGray.light,
                  display: "flex",
                  padding: 5,
                  boxSizing: "border-box",
                  overflow: "auto",
                  width: "100%"
                }}
                {...provided.droppableProps}
              >
                {compactViewIds.map((bid, index) => {
                  const baws = andonWorkstations[bid];

                  if (!baws) return;

                  const ws = baws.workstation;
                  const groupedByParentPorts = groupByParentPorts(ws);

                  return (
                    <Draggable key={ws.id} draggableId={ws.id} index={index}>
                      {(provided2, snapshot2) => (
                        <Box
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}
                          sx={{
                            userSelect: "none",
                            border: snapshot2.isDragging
                              ? "1px solid black"
                              : "none",
                            marginTop: "20px",
                            marginBottom: "20px",
                            marginRight: "10px",
                            ...provided2.draggableProps.style
                          }}
                        >
                          <Box
                            sx={{
                              width: "200px",
                              margin: "5px",
                              boxSizing: "border-box",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center"
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: figureAndonColorFromStatus({
                                  handRaised: baws.handRaised,
                                  workstationStatus: baws.status,
                                  workstationActive:
                                    baws.workstation.status === "Active",
                                  workstationOpen: baws.isOpen,
                                  gridSystemMode:
                                    andonGrids[baws.workstation.autostoreGridId]
                                      .systemMode || undefined
                                }),
                                display: "flex",
                                flexDirection: "column",
                                padding: "5px"
                              }}
                            >
                              <Typography align="center">
                                {ws.deviceId}
                              </Typography>
                              <Typography>{ws.status}</Typography>

                              <>
                                {Object.entries(groupedByParentPorts).map(
                                  ([parentPortId, ports]) => (
                                    <Box
                                      key={parentPortId}
                                      sx={{
                                        border: "1px solid black",
                                        display: "flex",
                                        flexDirection: "column"
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          width: "100%",
                                          justifyContent: "flex-end"
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            color: "white",
                                            backgroundColor:
                                              parentPortId === "Unassigned"
                                                ? "transparent"
                                                : "black",
                                            padding: "5px"
                                          }}
                                        >
                                          {parentPortId === "Unassigned"
                                            ? ""
                                            : parentPortId}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        {ports.map((port) => (
                                          <Box
                                            key={port.portId}
                                            sx={{
                                              backgroundColor: "white",
                                              margin: "5px",
                                              padding: "5px"
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                backgroundColor: "black",
                                                borderRadius: "100%",
                                                width: "50px",
                                                height: "50px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  color: "white"
                                                }}
                                              >
                                                {port.portId}
                                              </Typography>
                                            </Box>
                                            <Typography
                                              sx={{
                                                fontStyle: "italic"
                                              }}
                                            >
                                              {port.configuration}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: "10px"
                                              }}
                                            >
                                              {`${t("size")}: ${port.binSize}`}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: "10px"
                                              }}
                                            >
                                              {`${t("cleaningDirection")}: ${
                                                port.cleaningDirection
                                              }`}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: "10px"
                                              }}
                                            >
                                              {`${t(
                                                "inductionBinConfiguration"
                                              )}: ${
                                                port.inductionBinConfiguration
                                                  ? port.inductionBinConfiguration.join(
                                                      " "
                                                    )
                                                  : " "
                                              }`}
                                            </Typography>
                                          </Box>
                                        ))}
                                      </Box>
                                    </Box>
                                  )
                                )}
                              </>
                            </Box>

                            <ButtonBase
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "8px",
                                height: "25px",
                                backgroundColor: "transparent"
                              }}
                              aria-label={"remove workstation"}
                              onClick={() =>
                                handleRemoveAndonWorkstation(ws.id)
                              }
                            >
                              <CloseIcon sx={{ color: "#000000" }} />
                            </ButtonBase>
                          </Box>
                        </Box>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      {/* modals */}
      <WorkstationSelectorModal
        isOpen={workstationSelectorOpen}
        selectedWorkstationIds={compactViewIds}
        workstationSelectorCb={(newIds) => {
          dispatch(setCompactViewIds(newIds));
        }}
        setModalOpen={(isOpen) => setWorkstationSelectorOpen(isOpen)}
      />
    </Box>
  );
}

export default AndonScreen;
