import { Box, Chip, ListItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import { useAppSelector } from "~/app/store";
import NavSearchInput from "~/components/navbar/NavSearchInput";
import { createProductSearchOptions } from "~/config/searchProductConfig";

import { searchProduct } from "~/lib/helpers";

import { BinOrProductResult } from "./InventoryOld";
import { createBinSearchOptions } from "./createBinSearchOptions";
import { useSearchBinsByText } from "./useSearchBinsByText";
import { getViewType } from "./viewType";

type Props = {
  handleAutocompleteSelect: (result: BinOrProductResult) => void;
};

export function InventoryOldSearch({ handleAutocompleteSelect }: Props) {
  const { t } = useTranslation();
  const locationInfo = useLocation();
  const { pathname } = locationInfo;

  const searchBinsByText = useSearchBinsByText();

  const clientId = useAppSelector((state) =>
    getUserClientId(state.login.profile)
  );
  const isAdjustingBins = useAppSelector(
    (state) => state.inventoryOld.isAdjustingBins
  );

  const [searchText, setSearchText] = useState<string | null>(null);
  const searchPlaceholder = t("search products or bin");

  const [autocompleteOptions, updateAutocompleteOptions] = useState<
    BinOrProductResult[]
  >([] as BinOrProductResult[]);

  const viewType = getViewType(pathname);

  // clear autocomplete options if view changes from bin to product or vice versa
  useEffect(() => updateAutocompleteOptions([]), [viewType]);

  const autocompleteSearchBinsAndProducts = async (
    input: string | null
  ): Promise<void> => {
    // search bins
    if (input === null || input === "" || !clientId) {
      updateAutocompleteOptions([]);
    } else {
      const [binSearch, hits] = await Promise.all([
        searchBinsByText(input),
        searchProduct(input)
      ]);

      const hitsAsAutocompleteRecords = createProductSearchOptions({ hits });
      const binsAsAutocompleteRecords = createBinSearchOptions(binSearch);

      updateAutocompleteOptions([
        ...binsAsAutocompleteRecords,
        ...hitsAsAutocompleteRecords
      ]);
    }
  };

  return (
    <Box
      id="inventoryLookup-navbar-searchComponent"
      display="flex"
      flexGrow="1"
      justifyContent="center"
    >
      <NavSearchInput<BinOrProductResult>
        textInput={searchText || ""}
        setTextInput={(text) => setSearchText(text)}
        isAutocomplete
        searchPlaceholder={searchPlaceholder}
        autocompleteSearchCb={(input) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          autocompleteSearchBinsAndProducts(input);
        }}
        autocompleteOptions={autocompleteOptions}
        autocompleteLabelConstructor={(option: BinOrProductResult): string =>
          option.displayText
        }
        groupBy={(option) => t(option.type)}
        selectCb={(result: BinOrProductResult) => {
          handleAutocompleteSelect(result);
        }}
        clearTextOnSelect
        renderOption={(params, option) => {
          // find all bin results that are the same binId
          const matchingOptions = autocompleteOptions.filter(
            (opt) => opt.displayText === option.displayText
          );
          // if there's more than one, display the compartmentId along with the binId
          const shouldShowCompartment =
            option.autostore_compartment_number && matchingOptions.length > 1;
          return (
            <ListItem
              {...params}
              key={`${option.displayText}${
                option.autostore_compartment_number || ""
              }`}
            >
              <Box mr={1}>{option.displayText}</Box>
              {!!shouldShowCompartment &&
                !!option.autostore_compartment_number && (
                  <Chip
                    label={
                      `${t("compartment")}` +
                      ` ${option.autostore_compartment_number}`
                    }
                    size="small"
                    sx={{
                      backgroundColor: "primary.main",
                      color: "primary.contrastText"
                    }}
                  />
                )}
            </ListItem>
          );
        }}
        autofocus={!isAdjustingBins}
      />
    </Box>
  );
}
