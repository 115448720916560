import { LoosePickSummaryDto } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const loosePicksApi = warehouseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLoosePicks: builder.query<LoosePickSummaryDto[], { orderId: Guid }>({
      query: ({ orderId }) => ({
        url: `/loose-picks`,
        method: "GET",
        params: { orderId }
      }),
      providesTags: ["loose pick"]
    }),
    loadLoosePicks: builder.mutation<void, { loosePickIds: Guid[] }>({
      query: ({ loosePickIds }) => {
        return {
          url: `/loose-picks/load`,
          method: "POST",
          data: loosePickIds
        };
      },
      invalidatesTags: ["loose pick"]
    }),
    verifyLoosePicks: builder.mutation<void, { loosePickIds: Guid[] }>({
      query: ({ loosePickIds }) => {
        return {
          url: `/loose-picks/verify`,
          method: "POST",
          data: loosePickIds
        };
      },
      invalidatesTags: ["loose pick"]
    })
  }),
  overrideExisting: false
});
