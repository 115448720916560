import ErrorIcon from "@locaisolutions/icons/dist/icons24px/Error24Px";
import { Container, Box, Typography } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";

import { useAppSelector } from "~/app/store";
import andonBoardImg from "~/assets/img/andon-board.png";
import OrganizationLoginButton from "~/components/OrganizationLoginButton";

function OrganizationLogin() {
  const { name } = useParams<{ name: string }>();
  const errorMessage = useAppSelector((state) => state.login.errorMessage);
  const [searchParams] = useSearchParams();
  const isAndonAuth = searchParams.get("andon-login") === "true";

  return (
    <Container
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        paddingY: 4
      }}
    >
      {isAndonAuth && (
        <Box
          sx={{
            position: "absolute",
            top: "10%",
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}
        >
          <img
            src={andonBoardImg}
            alt="andon board logo"
            style={{ maxHeight: 200 }}
          />
        </Box>
      )}

      <Box>
        <OrganizationLoginButton
          sx={{
            width: 350,
            fontSize: "2rem"
          }}
          organizationName={name}
        />
      </Box>

      {errorMessage && (
        <Box
          sx={{
            position: "absolute",
            top: "calc(50% + 100px)",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            alignItems: "center"
          }}
        >
          <ErrorIcon style={{ stroke: "red", fill: "red" }} />

          <Typography
            sx={{
              marginLeft: 1,
              color: "red",
              textAlign: "center",
              fontSize: "1.5rem"
            }}
          >
            {errorMessage}
          </Typography>
        </Box>
      )}
    </Container>
  );
}

export default OrganizationLogin;
