import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { andonColors } from "frontend-components";

import { useNavbar } from "~/hooks/useNavbar";
import { useView } from "~/hooks/useView";

import SpeechSimulator from "./SpeechSimulator";

type Props = { viewTitle?: string };

export function DemoPage(props: Props) {
  const { viewTitle } = props;

  useView({ permanentSidenav: true });
  useNavbar({ viewTitle });

  return (
    <Grid container spacing={2}>
      <Grid item spacing={2} xs={12} lg={8} style={{ marginTop: 16 }}>
        <Grid item spacing={2}>
          <Typography variant="h6">Demo</Typography>
        </Grid>
        <Paper
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Grid>
            {[
              andonColors.openGreen,
              andonColors.handRaisedYellow,
              andonColors.safetyStopRed,
              andonColors.portFaultAmber,
              andonColors.gridDownPurple
            ].map((color) => (
              <Box
                key={color}
                sx={{
                  backgroundColor: color,
                  height: "100px",
                  width: "100px"
                }}
              />
            ))}
          </Grid>
        </Paper>
        <div style={{ marginTop: 10 }} />
        <Paper
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Grid>
            <Typography>Speech Simulator</Typography>
            <SpeechSimulator />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
