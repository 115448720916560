import { ImportExport } from "@mui/icons-material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { ProgressButton } from "frontend-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectedProps, connect } from "react-redux";

import { changeDecantRate } from "~/redux/actions";
import { PutAwayTaskSummaryDto } from "~/types/api";

const IncrementButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  background: "white",
  border: `2px solid ${theme.palette.primary.main}`,
  marginRight: 10,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main
  }
}));

const connector = connect(null, {
  changeDecantRate
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type DecantRateModalProps = PropsFromRedux & {
  putAwayTask: PutAwayTaskSummaryDto;
  closeModal: () => void;
  successCallback: () => void;
};

export function DecantRateModal(props: DecantRateModalProps) {
  const { t } = useTranslation();

  const { putAwayTask, closeModal, successCallback } = props;

  const [quantityFieldValue, updateQuantityFieldValue] = useState<
    number | null
  >(putAwayTask.decantingRate?.value || null);

  const quantityTextField = (
    <TextField
      style={{ width: "100%" }}
      id="quantity"
      label="Qty Per Induction Task"
      type="number"
      variant="outlined"
      value={quantityFieldValue || 0}
      onChange={(e) => updateQuantityFieldValue(parseInt(e.target.value, 10))}
      inputProps={{
        sx: { fontSize: "50px", padding: "35px 10px 35px 18px" }
      }}
      InputLabelProps={{
        shrink: true
      }}
    />
  );

  const quantityButtons = (
    <Grid
      container
      item
      direction="column"
      xs={6}
      spacing={1}
      wrap="nowrap"
      sx={{ position: "absolute", top: "28px", right: "8px" }}
    >
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IncrementButton
          aria-label="increment"
          sx={{
            color: "primary.main",
            border: `2px solid`,
            borderColor: "primary.main"
          }}
          onClick={(): void => {
            updateQuantityFieldValue(
              quantityFieldValue ? quantityFieldValue + 1 : 1
            );
          }}
          size="large"
          role="button"
          data-testid="increment"
        >
          <ExpandLessIcon
            style={{
              fontSize: 27
            }}
          />
        </IncrementButton>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IncrementButton
          aria-label="decrement"
          onClick={(): void => {
            updateQuantityFieldValue(
              quantityFieldValue ? quantityFieldValue - 1 : 0
            );
          }}
          sx={{
            color: "primary.main",
            border: `2px solid`,
            borderColor: "primary.main"
          }}
          size="large"
          role="button"
          data-testid="decrement"
        >
          <ExpandMoreIcon
            style={{
              fontSize: 27
            }}
          />
        </IncrementButton>
      </Grid>
    </Grid>
  );

  const unitOfMeasureField = (
    <TextField
      id="unitOfMeasure"
      label={t("uom")}
      type="string"
      fullWidth
      variant="outlined"
      value={putAwayTask.decantingRate?.units || ""}
      disabled
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        sx: { fontSize: "50px", padding: "35px 5px" }
      }}
    />
  );

  const AdjustRateButton = (
    <ProgressButton
      id="adjust-button"
      data-testid="adjust-rate-button"
      buttonSize="medium"
      emphasis="high"
      responsive
      fullWidth
      startIcon={<ImportExport />}
      onClick={(): Promise<void> | null => {
        if (quantityFieldValue !== null) {
          return props
            .changeDecantRate(
              putAwayTask.putAwayTaskId,
              quantityFieldValue,
              putAwayTask.quantity.units
            )
            .then(() => {
              successCallback();
              closeModal();
            });
        }
        return null;
      }}
    >
      {t("adjust")}
    </ProgressButton>
  );

  return (
    <>
      <Grid
        className="InventoryAdjustPanel"
        container
        item
        xs={12}
        direction="column"
        wrap="nowrap"
      >
        <Grid className="InventoryAdjustPanel-card-container" item>
          <Card className="InventoryAdjustPanel-card">
            <DialogTitle variant="h6">
              <Typography>{putAwayTask.product.name}</Typography>
            </DialogTitle>
            <Box className="InventoryAdjustPanel-card-box" padding="20px">
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  xs={12}
                  md={7}
                  style={{
                    position: "relative"
                  }}
                >
                  {quantityTextField}
                  {quantityButtons}
                </Grid>
                <Grid item xs={12} md={5}>
                  {unitOfMeasureField}
                </Grid>
              </Grid>
              <Grid
                container
                item
                spacing={2}
                style={{ marginTop: 20 }}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={6}>
                  {AdjustRateButton}
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default connector(DecantRateModal);
