import { Plus24Px } from "@locaisolutions/icons";

import {
  TextField,
  Stack,
  Button,
  InputLabel,
  Switch,
  IconButton,
  useTheme,
  Paper
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm
} from "react-hook-form";

import { useAppSelector } from "~/app/store";
import { RemoveIcon } from "~/components/RemoveIcon";
import { useToast } from "~/hooks/useToast";
import { globalDateFormat } from "~/lib/dateHelpers";
import { getRndInteger } from "~/lib/helpers";
import { useCreatePepsiInductionOrderMutation } from "~/redux/pepsi/inductionOrder.hooks";
import {
  ReceivedInventory,
  ReceivedInventoryItem,
  usePostInventoryReceiveMutation
} from "~/redux/public/inventory.openApi";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";
import {
  selectOrderCreationSelectedSku,
  selectOrderCreationSimplified
} from "~/redux/selectors/orderCreationSelectors";

import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";

import { ProductInfo } from "./ProductInfo";
import { getErrorMessage } from "./shared";

import { translateToInductionOrder } from "./translateToPepsiApi";

const createInitialCreatePutawayProduct = (): ReceivedInventoryItem => ({
  upcOrPlu: "",
  sku: "",
  count: {
    value: 100,
    unit: "ea"
  },
  expiration: dayjs().add(5, "month").toISOString(),
  decantingRate: {
    value: 1,
    unit: "ea"
  },
  code: getRndInteger(80000, 90000).toString(),
  lotNumber: getRndInteger(8000000, 9000000).toString(),
  purchaseOrderNumber: getRndInteger(1000000, 90000000).toString(),
  purchaseOrderLineItemNumber: getRndInteger(800000, 900000).toString()
});

const initialCreatePutaway: ReceivedInventory = {
  receivings: [],
  failAllIfAnyFail: true,
  externalReceivingId: ""
};

export const InductionTab = () => {
  const { palette } = useTheme();
  const { successToast, errorToast } = useToast();
  const { dev_useV2OrderCreation } = useAppSelector(selectClientConfig);
  const clientId = useAppSelector(selectUsersClientId);
  const fulfillmentCenter =
    useAppSelector(selectUsersFulfillmentCenter) || undefined;
  const siteWorkstation = useAppSelector(selectThisWorkstation) || undefined;

  const [createInductionOrder, { isLoading: createInductionInprogress }] =
    useCreatePepsiInductionOrderMutation();
  const [createPutaway, { isLoading: createPutawayInProgress }] =
    usePostInventoryReceiveMutation();
  const simplified = useAppSelector(selectOrderCreationSimplified);
  const selectedSku = useAppSelector(selectOrderCreationSelectedSku);

  const createPutawayContext = useForm<ReceivedInventory>({
    defaultValues: initialCreatePutaway
  });

  const handleCreatePutaway = async (data: ReceivedInventory) => {
    if (!clientId || !fulfillmentCenter) return;

    try {
      if (dev_useV2OrderCreation) {
        await createInductionOrder({
          clientId,
          fulfillmentCenterId: fulfillmentCenter.fulfillmentCenterId,
          ...translateToInductionOrder(data, fulfillmentCenter, siteWorkstation)
        }).unwrap();
      } else {
        await createPutaway({
          clientId,
          fulfillmentCenterId: fulfillmentCenter.fulfillmentCenterId,
          receivedInventory: data
        }).unwrap();
      }
      successToast("Successfully created Putaway");
    } catch (err) {
      errorToast(getErrorMessage(err));
    }

    const code = getRndInteger(80000, 90000).toString();
    const lotNumber = getRndInteger(8000000, 9000000).toString();
    const purchaseOrderNumber = getRndInteger(1000000, 90000000).toString();
    const purchaseOrderLineItemNumber = getRndInteger(
      800000,
      900000
    ).toString();

    createPutawayContext.setValue(
      "receivings",
      data.receivings.map((wcp) => ({
        ...wcp,
        code,
        lotNumber,
        purchaseOrderNumber,
        purchaseOrderLineItemNumber
      }))
    );
  };

  const {
    fields: receivings,
    append: appendReceiving,
    remove: removeReceiving
  } = useFieldArray({
    control: createPutawayContext.control,
    name: "receivings"
  });

  return (
    <FormProvider {...createPutawayContext}>
      <form onSubmit={createPutawayContext.handleSubmit(handleCreatePutaway)}>
        {!simplified && (
          <Stack direction="row" alignItems="center">
            <InputLabel htmlFor="failAllIfAnyFail">FailAllIfAnyFail</InputLabel>
            <Switch
              id="failAllIfAnyFail"
              name="failAllIfAnyFail"
              checked={createPutawayContext.watch("failAllIfAnyFail") ?? true}
              onChange={() =>
                createPutawayContext.setValue(
                  "failAllIfAnyFail",
                  !createPutawayContext.watch("failAllIfAnyFail")
                )
              }
            />
          </Stack>
        )}

        <Stack direction="row" gap={3}>
          <Button
            variant={receivings.length ? "contained" : "outlined"}
            disabled={
              !receivings.length ||
              createPutawayInProgress ||
              createInductionInprogress
            }
            type="submit"
            color="primary"
          >
            Create Putaway
          </Button>
          <Button
            variant="contained"
            disabled={!receivings.length}
            onClick={() => createPutawayContext.reset(initialCreatePutaway)}
            color="warning"
          >
            Reset
          </Button>
        </Stack>

        <Stack gap={2} my={2}>
          {receivings.map((r, index) => (
            <Paper
              key={r.id}
              sx={{
                p: 2
              }}
            >
              <Stack flexDirection="row" gap={2} alignItems="center">
                <ProductInfo sku={r.sku} />
                <TextField
                  {...createPutawayContext.register(
                    `receivings.${index}.count.value`,
                    {
                      required: true
                    }
                  )}
                  sx={{ flexBasis: "50px" }}
                  variant="standard"
                  label="Quantity"
                  type="number"
                />
                <IconButton
                  aria-label="delete"
                  onClick={() => removeReceiving(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </Stack>
              {!simplified && (
                <Stack flexDirection="row" flexWrap="wrap" gap={2}>
                  {(dev_useV2OrderCreation ||
                    fulfillmentCenter?.inventoryDateConfiguration.toLowerCase() ==
                      "manufacturedate") && (
                    <Controller
                      control={createPutawayContext.control}
                      name={`receivings.${index}.manufactureDate`}
                      render={({
                        field: { onBlur, value, onChange, ...field }
                      }) => (
                        <DatePicker
                          {...field}
                          label="Manufacture Date"
                          value={dayjs(value)}
                          onChange={(event) => {
                            onChange(event?.toDate() || null);
                          }}
                          format={globalDateFormat}
                          slotProps={{
                            textField: { variant: "standard", onBlur }
                          }}
                        />
                      )}
                    />
                  )}
                  {fulfillmentCenter?.inventoryDateConfiguration.toLowerCase() ==
                    "expirationdate" && (
                    <Controller
                      control={createPutawayContext.control}
                      name={`receivings.${index}.expiration`}
                      render={({
                        field: { onBlur, value, onChange, ...field }
                      }) => (
                        <DatePicker
                          {...field}
                          label="Expiration"
                          value={dayjs(value)}
                          onChange={(event) => {
                            onChange(event?.toDate() || null);
                          }}
                          format={globalDateFormat}
                          slotProps={{
                            textField: { variant: "standard", onBlur }
                          }}
                        />
                      )}
                    />
                  )}
                  <TextField
                    {...createPutawayContext.register(
                      `receivings.${index}.decantingRate.value`
                    )}
                    variant="standard"
                    label="Decanting Rate"
                  />
                  <TextField
                    {...createPutawayContext.register(
                      `receivings.${index}.code`
                    )}
                    variant="standard"
                    label="Code"
                  />
                  <TextField
                    {...createPutawayContext.register(
                      `receivings.${index}.lotNumber`
                    )}
                    variant="standard"
                    label="Lot Number"
                  />
                  <TextField
                    {...createPutawayContext.register(
                      `receivings.${index}.purchaseOrderNumber`
                    )}
                    variant="standard"
                    label="Purchase Order Number"
                  />
                  <TextField
                    {...createPutawayContext.register(
                      `receivings.${index}.purchaseOrderLineItemNumber`
                    )}
                    variant="standard"
                    label="Purchase Order Line Item Number"
                  />
                </Stack>
              )}
            </Paper>
          ))}
        </Stack>
        <Button
          variant={!receivings.length ? "contained" : "outlined"}
          disabled={!selectedSku}
          color="primary"
          onClick={() => {
            if (!selectedSku) return;
            appendReceiving({
              ...createInitialCreatePutawayProduct(),
              sku: selectedSku
            });
          }}
          aria-label={"Add Product to Putaway"}
        >
          <Plus24Px
            style={{
              fill: !receivings.length
                ? palette.buttonGroup.main
                : palette.primary.main,
              marginRight: "8px"
            }}
          />{" "}
          Product
        </Button>
      </form>
    </FormProvider>
  );
};
