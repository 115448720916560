/* eslint-disable react/no-array-index-key */

import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import {
  Box,
  ClickAwayListener,
  Container,
  Grid,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { ProgressButton } from "frontend-components";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { AnimatedCheckMark, AnimatedX } from "~/components/AnimatedCheckMark";

import envConstants from "~/config/envConstants";
import { openAddToteModal } from "~/features/autostorePicking/addToteModal.slice";
import usePickingFunctionalities from "~/features/autostorePicking/hooks/usePickingFunctionalities";
import { ternaryIff } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { usePickSubscription } from "~/lib/signalr";
import { setTotePickingStarted } from "~/redux/actions";
import { selectPtlSimulationEnabled } from "~/redux/selectors/PTLselectors";
import {
  selectBinIsPresent,
  selectCartNumberConfirmed,
  selectIsPickQuantityConfirmed,
  selectNextPickingStateLoading,
  selectSignalR
} from "~/redux/selectors/autostoreSelectors";
import { selectStartPickingModalIsOpen } from "~/redux/selectors/pickingSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectWorkstationId } from "~/redux/selectors/workstationsSelectors";
import {
  useGetFocusedPickQuery,
  useGetTotesForBatchQuery
} from "~/redux/warehouse/autostorePicking.hooks";
import { warehouseApi } from "~/redux/warehouse/warehouseApi";
import { SignalRPickEventDto } from "~/types/api";

import ExpressIcon from "./ExpressIcon";
import PTL from "./PTL";
import ToteOptionsMenu from "./ToteOptionsMenu";
import { ToteProgressBar } from "./ToteProgressBar";

function AutostoreTote(props: {
  toteId: Guid;
  position: number;
  externalToteId?: Guid;
  isActiveTote: boolean;
  onClickCb: () => Promise<void | null> | null;
  isLastStackedTote: boolean;
}) {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const {
    toteId,
    position,
    externalToteId,
    isActiveTote,
    onClickCb,
    isLastStackedTote
  } = props;

  const dispatch = useAppDispatch();

  const [isExpressOrderState, setIsExpressOrderState] = useState(false);
  const [openOrderIdTooltip, setOpenOrderIdTooltip] = useState(false);
  const lastRefetchOccuredOn = useRef<number | null>(null);
  const binIsReady = useAppSelector(selectBinIsPresent);
  const nextPickingStateLoading = useAppSelector(selectNextPickingStateLoading);
  const cartNumberConfirmed = useAppSelector(selectCartNumberConfirmed);
  const clientConfig = useAppSelector(selectClientConfig);
  const pickingIsComplete = useAppSelector(selectStartPickingModalIsOpen);
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const isPickQuantityConfirmed = useAppSelector(selectIsPickQuantityConfirmed);
  const ptlSimulationEnabled = useAppSelector(selectPtlSimulationEnabled);
  const workstationId = useAppSelector(selectWorkstationId);
  const signalR = useAppSelector(selectSignalR);
  const { handleSlamProcess } = usePickingFunctionalities();
  const [searchParams] = useSearchParams();
  const { data: focusedPick, refetch: refetchFocusedPick } =
    useGetFocusedPickQuery(
      workstationId ? { workstationId: workstationId } : skipToken,
      {
        refetchOnMountOrArgChange: true,
        pollingInterval:
          signalR?.state?.toLowerCase() === "disconnected" ? 2000 : undefined
      }
    );
  const batchId = searchParams.get("batchId") || focusedPick?.batchId;

  const { tote } = useGetTotesForBatchQuery(
    batchId && position ? { batchId } : skipToken,
    {
      pollingInterval:
        signalR?.state?.toLowerCase() === "disconnected" ? 2000 : undefined,
      refetchOnMountOrArgChange: 5,
      selectFromResult: ({ data }) => ({
        tote: data
          ?.flatMap((pos) => pos.totes)
          ?.find((tote) => tote.toteId === toteId)
      })
    }
  );
  const {
    ap_confirmCartNumberEnabled,
    ap_fusionPortScreenEnabled,
    ap_addToteFunctionalityEnabled,
    ap_repickToteFunctionalityEnabled,
    ap_applyingLabelProcessEnabled,
    ap_isTopAreaOfToteClickable
  } = clientConfig;

  const fusionPortScreen = ap_fusionPortScreenEnabled;
  const addToteOptionEnabled = ap_addToteFunctionalityEnabled;
  const repickToteOptionEnabled = ap_repickToteFunctionalityEnabled;
  const slamProcessEnabled = ap_applyingLabelProcessEnabled;
  const isTopAreaClickable = ap_isTopAreaOfToteClickable;

  const pickReady =
    isActiveTote &&
    binIsReady &&
    !nextPickingStateLoading &&
    (!ap_confirmCartNumberEnabled || cartNumberConfirmed);

  // state that should indicate that pick quantity is confirmed
  const confirmDisabled =
    (!!fulfillmentCenter &&
      fulfillmentCenter.pickQuantityConfirmationEnabled &&
      !isPickQuantityConfirmed) ||
    !binIsReady;

  const isToteConfirmDisabled = !!(
    confirmDisabled ||
    !pickReady ||
    pickingIsComplete
  );

  const refetchFocusedPickAndToteContent = () => {
    try {
      const currentDate = Date.now();
      if (lastRefetchOccuredOn.current) {
        const refetchCalledBeforeSeconds =
          currentDate - lastRefetchOccuredOn.current;
        lastRefetchOccuredOn.current = currentDate;
        if (refetchCalledBeforeSeconds < 1000) return;
      }
      lastRefetchOccuredOn.current = currentDate;
      void refetchFocusedPick();
    } catch (e) {
      getMessageFromRtkError(e);
    }
  };

  // Show Checkmark icon on the tote box if:
  // - All picks in the tote are in the 'Completed' status
  // - There is at least one completed pick in the tote and tote is in the 'Picked' status
  const getShowCheckmark = () => {
    if (tote && tote.picks) {
      const isEveryPickCompleted = tote.picks.every(
        (p) => !!p.completedTimestamp
      );
      if (isEveryPickCompleted) return true;

      const isSomePickCompleted = tote.picks.some(
        (p) => !!p.completedTimestamp
      );

      return isSomePickCompleted && tote.pickedTimestamp;
    }
    return false;
  };

  // Show X icon on the tote box if:
  // - If the tote is in the 'Canceled' status
  // - All picks in the tote are in the 'Canceled' status
  const getShowX = () => {
    if (!tote) return false;
    if (tote.canceledTimestamp) return true;
    const isEveryPickCanceled = tote.picks?.every((p) => !!p.canceledTimestamp);
    return !!isEveryPickCanceled;
  };

  const getShowRepickProductsOption = () => {
    if (!tote) return false;
    if (tote.canceledTimestamp || tote.pickedTimestamp) return false;
    const allPicksForCurrentTote = tote.picks;
    if (allPicksForCurrentTote) {
      const anyConfirmedPicks = allPicksForCurrentTote.filter(
        (pick) => !!pick.completedTimestamp
      );
      if (anyConfirmedPicks.length) return true;
    }
    return false;
  };

  const showCheckmark = getShowCheckmark();
  const showX = getShowX();
  const showRepickProductsOption = getShowRepickProductsOption();

  useEffect(() => {
    if (tote) {
      const isExpressOrderLocal = tote.priority?.toLowerCase() === "express";
      setIsExpressOrderState(isExpressOrderLocal);
    }
  }, [tote]);

  const pickSubscription = (event: SignalRPickEventDto) => {
    if (event.batchId === batchId && event.pick.assignedToteId === toteId) {
      refetchFocusedPickAndToteContent();
    }
  };
  usePickSubscription(pickSubscription);

  let toteStyle: React.CSSProperties = {
    backgroundColor:
      isActiveTote && !pickingIsComplete
        ? ternaryIff(
            isToteConfirmDisabled,
            palette.gray.contrastText,
            palette.primary.main
          )
        : palette.secondary.main,
    color:
      isActiveTote && !pickingIsComplete
        ? palette.primary.contrastText
        : palette.darkGray.light
  };
  if (showCheckmark)
    toteStyle = {
      backgroundColor: palette.success.light,
      color: palette.success.main
    };

  const externalOrderId = tote?.externalOrderId;

  const matchingPickQuantity = focusedPick && focusedPick.quantity.value;

  const showPickQuantity = isActiveTote;

  const checkColor =
    pickReady && !pickingIsComplete ? palette.info.main : "#0000001f";

  const showAddToteOption = addToteOptionEnabled && isActiveTote;
  const showRepickOption = repickToteOptionEnabled && showRepickProductsOption;
  const showReprintLabelOption = !!slamProcessEnabled;

  const showThreeDotsMenu =
    showAddToteOption || showRepickOption || showReprintLabelOption;

  const isOnlyOneElementDisplayedAboveTote =
    (!!tote?.externalOrderId && !showThreeDotsMenu) ||
    (showThreeDotsMenu && !isExpressOrderState);

  const addToteCallback = () => {
    if (!addToteOptionEnabled) return;
    dispatch(openAddToteModal());
  };

  const addToteOptionClickCallback = () => {
    dispatch(setTotePickingStarted(toteId));
    // this is needed so we refresh the cache when the addTote modal is opened,
    // until we can invalidate these naturally from the natural workflow.
    dispatch(warehouseApi.util.invalidateTags(["focused pick"]));
    addToteCallback();
  };

  const reprintLabelOptionClickCallback = async () => {
    if (tote) {
      await handleSlamProcess(toteId, tote.orderId, null, true);
    }
  };

  return (
    <Container
      style={{
        textAlign: "center",
        padding: "0 4px",
        maxWidth: 240
      }}
      disableGutters
      data-testid="autostore-tote"
    >
      <Grid
        item
        xs={12}
        style={{
          position: "relative",
          minHeight: "35px"
        }}
      >
        {(externalOrderId || showThreeDotsMenu) && (
          <Grid
            container
            justifyContent={
              isOnlyOneElementDisplayedAboveTote ? "center" : "space-between"
            }
            alignItems="center"
            style={{ height: "5vH" }}
          >
            {externalOrderId && isLastStackedTote && (
              //  eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <Grid
                item
                xs={isOnlyOneElementDisplayedAboveTote ? 12 : 6}
                onClick={() => {
                  setOpenOrderIdTooltip((previousState) => !previousState);
                }}
                sx={{ position: "relative" }}
              >
                {externalOrderId.length > 15 ? (
                  <ClickAwayListener
                    onClickAway={() => setOpenOrderIdTooltip(false)}
                  >
                    <Box>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true
                        }}
                        open={openOrderIdTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          externalOrderId.length > 25 ? externalOrderId : ""
                        }
                      >
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "12px",
                            display: "inline-block",
                            marginRight: "1vw"
                          }}
                        >
                          {t("order")}: {externalOrderId.substring(0, 25)}
                        </Typography>
                      </Tooltip>
                      {!openOrderIdTooltip &&
                        isActiveTote &&
                        tote?.externalOrderId.length > 25 && (
                          <ExpandCircleDownIcon
                            fontSize="small"
                            sx={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              "@keyframes externalOrderIdText": {
                                from: {
                                  transform: "scale(0.8, 0.8)",
                                  opacity: "0.2"
                                },
                                to: {
                                  transform: "scale(1, 1)",
                                  opacity: "1"
                                }
                              },
                              ...(!openOrderIdTooltip && {
                                animation:
                                  "externalOrderIdText 2s infinite ease"
                              })
                            }}
                          />
                        )}
                    </Box>
                  </ClickAwayListener>
                ) : (
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "12px",
                      display: "inline-block",
                      marginRight: "1vw"
                    }}
                  >
                    {t("order")}: {` ${externalOrderId}`}
                  </Typography>
                )}
              </Grid>
            )}
            {showThreeDotsMenu && isLastStackedTote && (
              <Grid
                item
                style={{
                  position: isOnlyOneElementDisplayedAboveTote
                    ? "absolute"
                    : undefined,
                  right: isOnlyOneElementDisplayedAboveTote ? 0 : undefined
                }}
              >
                <ToteOptionsMenu
                  addToteDisabled={!pickReady || confirmDisabled}
                  addToteCallback={addToteOptionClickCallback}
                  showAddToteOption={showAddToteOption}
                  showRepickProductsOption={showRepickOption}
                  isReprintLabelOptionEnabled={!!tote?.pickedTimestamp}
                  reprintLabelCallback={reprintLabelOptionClickCallback}
                  toteId={toteId}
                  totePosition={position}
                  isActiveTote={isActiveTote}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      <Box sx={{ position: "relative" }}>
        {!isTopAreaClickable && (
          <Box
            sx={{
              height: "35px",
              width: "100%",
              position: "absolute",
              top: "-7%",
              zIndex: 1
            }}
          />
        )}
        <ProgressButton
          buttonSize="xLarge"
          emphasis="high"
          responsive
          variant="contained"
          data-testid={isActiveTote && pickReady ? "activeTote" : ""}
          aria-label="active tote"
          disabled={isToteConfirmDisabled}
          onClick={async () => {
            try {
              await onClickCb();
              refetchFocusedPickAndToteContent();
            } catch (e) {
              getMessageFromRtkError(e);
            }
          }}
          style={toteStyle}
          sx={{
            "@keyframes pulse": {
              from: {
                boxShadow: "0 0 0 0px rgba(0, 0, 0, 0.4)"
              },
              to: {
                boxShadow: "0 0 0 20px rgba(0, 0, 0, 0)"
              }
            },
            width: "100%",
            ...(pickReady &&
              !pickingIsComplete &&
              !isToteConfirmDisabled && { animation: "pulse 2s infinite ease" })
          }}
        >
          <Box
            height={
              (envConstants.ENABLE_PTL_SIMULATION === "true" &&
                ptlSimulationEnabled) ||
              fusionPortScreen
                ? 140
                : 240
            }
            width="100%"
          >
            <Grid
              container
              spacing={2}
              direction="row"
              wrap="nowrap"
              style={{
                height: "100%",
                position: "relative"
              }}
            >
              <Grid item xs={4}>
                {isLastStackedTote && (
                  <Box textAlign="left">
                    <Typography variant="h3">{`P${
                      tote?.totePosition || ""
                    }`}</Typography>
                  </Box>
                )}
              </Grid>

              {(showCheckmark || showX) &&
                isExpressOrderState &&
                isLastStackedTote && (
                  <Grid item sx={{ position: "absolute", top: 0, right: -5 }}>
                    <ExpressIcon
                      expressIconSizePx={25}
                      translateVerticallyExpressIconPercentage={10}
                      translateVerticallyExpressTextPercentage={-55}
                      expressTextVariant="caption"
                      expressTextWeight={700}
                    />
                  </Grid>
                )}
              <Grid
                item
                xs={4}
                container
                justifyContent="center"
                style={{
                  marginTop: fusionPortScreen ? "0.75em" : "1.25em"
                }}
              >
                {/* checkmark */}
                {showCheckmark && isLastStackedTote && (
                  <AnimatedCheckMark
                    checkColor={checkColor}
                    size={fusionPortScreen ? 60 : 100}
                  />
                )}
                {showX && isLastStackedTote && (
                  <AnimatedX
                    color={palette.autostoreRed.main}
                    size={fusionPortScreen ? 60 : 100}
                  />
                )}
                {!(showCheckmark || showX) &&
                  isExpressOrderState &&
                  isLastStackedTote && (
                    <ExpressIcon
                      expressIconSizePx={50}
                      translateVerticallyExpressIconPercentage={10}
                      translateVerticallyExpressTextPercentage={-45}
                      expressTextVariant="body2"
                      expressTextWeight={700}
                    />
                  )}
              </Grid>
              <Grid
                item
                xs={4}
                container
                alignItems="flex-end"
                justifyContent="flex-end"
                style={{
                  position: fusionPortScreen ? "absolute" : undefined,
                  right: fusionPortScreen ? 0 : undefined,
                  bottom: fusionPortScreen ? "-14%" : undefined
                }}
              >
                {showPickQuantity && (
                  <Typography variant="h3">
                    {matchingPickQuantity || ""}
                  </Typography>
                )}
              </Grid>
              {externalToteId && isLastStackedTote && (
                <Grid
                  item
                  xs={12}
                  style={{
                    position: "absolute",
                    bottom: fusionPortScreen ? "-10%" : "2%",
                    left: "0%",
                    width: "70%"
                  }}
                >
                  {externalToteId && (
                    <Tooltip
                      title={<Typography>{externalToteId}</Typography>}
                      arrow
                    >
                      <Typography variant="h5" noWrap>
                        {externalToteId}
                      </Typography>
                    </Tooltip>
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        </ProgressButton>
      </Box>
      {envConstants.ENABLE_PTL_SIMULATION === "true" &&
        ptlSimulationEnabled && <PTL position={tote?.totePosition || 0} />}
      {tote?.picks && isLastStackedTote && (
        <Grid
          container
          sx={{ maxWidth: 240, margin: "10px auto 0" }}
          id="progressBar"
          data-testid="progress-bar"
          justifyContent="center"
        >
          <ToteProgressBar
            allPicksForTote={tote.picks}
            pickingIsComplete={!!pickingIsComplete}
          />
        </Grid>
      )}
    </Container>
  );
}

export default AutostoreTote;
