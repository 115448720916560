import Button from "@locaisolutions/button";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  TextField
} from "@mui/material";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";

import { ModalDialogTransition } from "~/components/ModalDialogTransition";
import { OrderTypesDropdown } from "~/components/orderTypes/OrderTypesDropdown";
import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { dayOfWeekAsString } from "~/lib/shared";
import {
  useDeleteRecurringScheduleMutation,
  usePostRecurringScheduleMutation
} from "~/redux/warehouse/recurringSchedule.hooks";
import {
  CreateRecurringScheduleRequest,
  RecurringScheduleDto
} from "~/types/api";

type Props = {
  recurringSchedule: RecurringScheduleDto;
  onClose: () => void;
};

export default function EditRecurringScheduleModal(props: Props) {
  const { errorToast, successToast } = useToast();
  const { recurringSchedule, onClose } = props;
  const {
    batchDependency,
    dayOfWeek,
    interval,
    minMaxOrdersPerWave,
    orderTypes,
    recurringScheduleId,
    scheduleWindow,
    shipmentConfig
  } = recurringSchedule;

  const [deleteRecurringSchedule] = useDeleteRecurringScheduleMutation();
  const [postRecurringSchedule] = usePostRecurringScheduleMutation();

  const {
    register,
    getValues,
    control,
    formState: { isDirty, isValid }
  } = useForm<CreateRecurringScheduleRequest>({
    defaultValues: {
      interval,
      minMaxOrdersPerWave,
      shipmentConfig,
      dayOfWeek,
      scheduleWindow,
      orderTypes,
      batchDependency
    }
  });

  const handleSave = async () => {
    const newSchedule = getValues();
    try {
      await deleteRecurringSchedule(recurringScheduleId).unwrap();
      await postRecurringSchedule(newSchedule).unwrap();
      successToast(t("batch setting updated"));
      onClose();
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  return (
    <Dialog
      open={!!recurringSchedule}
      TransitionComponent={ModalDialogTransition}
      onClose={onClose}
    >
      <DialogTitle>{dayOfWeekAsString(dayOfWeek)}</DialogTitle>

      <DialogContent>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          pt="20px"
          gap="20px 40px"
        >
          <Box>
            <InputLabel htmlFor="startTime" shrink>
              {t("start time")}
            </InputLabel>
            <TextField
              id="startTime"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">hh:mm:ss</InputAdornment>
                )
              }}
              {...register("scheduleWindow.0", {
                required: true
              })}
            />
          </Box>

          <Box>
            <InputLabel htmlFor="minimum" shrink>
              {t("batch minimum label")}
            </InputLabel>
            <TextField
              id="minimum"
              type="number"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("orders").toLowerCase()}
                  </InputAdornment>
                )
              }}
              {...register("minMaxOrdersPerWave.0", {
                valueAsNumber: true,
                required: true
              })}
            />
          </Box>

          <Box>
            <InputLabel htmlFor="endTime" shrink>
              {t("end time")}
            </InputLabel>
            <TextField
              id="endTime"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">hh:mm:ss</InputAdornment>
                )
              }}
              {...register("scheduleWindow.1", {
                required: true
              })}
            />
          </Box>

          <Box>
            <InputLabel htmlFor="maximum" shrink>
              {t("batch maximum label")}
            </InputLabel>
            <TextField
              id="maximum"
              type="number"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("orders").toLowerCase()}
                  </InputAdornment>
                )
              }}
              {...register("minMaxOrdersPerWave.1", {
                valueAsNumber: true,
                required: true
              })}
            />
          </Box>

          <Box>
            <InputLabel htmlFor="interval" shrink>
              {t("batch interval label")}
            </InputLabel>
            <TextField
              id="interval"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">hh:mm:ss</InputAdornment>
                )
              }}
              {...register("interval", { required: true })}
            />
          </Box>

          <OrderTypesDropdown control={control} fieldName="orderTypes" />
        </Box>

        <Controller
          name="shipmentConfig"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label="Use Shipments"
              sx={{ mt: 4 }}
              control={
                <Checkbox
                  checked={value.toLowerCase() === "useshipments"}
                  onChange={(_, checked) => {
                    onChange(checked ? "UseShipments" : "NoSpecifiedShipment");
                  }}
                />
              }
            />
          )}
        />
      </DialogContent>

      <DialogActions sx={{ gap: 1 }}>
        <Button onClick={onClose} kind="subtle" color="secondary" size="large">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSave}
          disabled={!isDirty || !isValid}
          kind="accent"
          size="large"
          sx={{ minWidth: 88 }}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
