import ASButton from "@locaisolutions/button";
import PlayIcon from "@locaisolutions/icons/dist/icons20px/Play20Px";
import { Alert } from "@mui/material";

import { ProgressButton } from "frontend-components";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router";

import { useAppSelector } from "~/app/store";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import {
  useCloseWorkstationMutation,
  useStartBinReconfigurationMutation
} from "~/redux/warehouse/workstation.hooks";

export const StartBinReconfigurationButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [startBinReconfiguration, { error: startBinReconfigurationError }] =
    useStartBinReconfigurationMutation();
  const [closeWorkstation, { error: closeWorkstationError }] =
    useCloseWorkstationMutation();
  const workstation = useAppSelector(selectThisWorkstation);
  const sourceBinConfigurationId = useAppSelector(
    (store) => store.binReconfiguration.sourceBinConfigurationId
  );
  const targetBinConfigurationId = useAppSelector(
    (store) => store.binReconfiguration.targetBinConfigurationId
  );

  const handleStartBinReconfiguration = async () => {
    if (!workstation || !sourceBinConfigurationId || !targetBinConfigurationId)
      return;

    try {
      await closeWorkstation({
        autostoreGridId: workstation.autostoreGridId,
        workstationId: workstation.id
      }).unwrap();
      await startBinReconfiguration({
        workstationId: workstation.id,
        binConfigurationId: sourceBinConfigurationId
      }).unwrap();

      navigate({
        pathname: "/bin-reconfiguration",
        search: new URLSearchParams({
          sourceBinConfigurationId,
          targetBinConfigurationId,
          autostore: "true"
        }).toString()
      });
    } catch {
      // error handled by alert
    }
  };

  if (startBinReconfigurationError ?? closeWorkstationError) {
    return (
      <Alert
        variant="outlined"
        severity="error"
        action={
          <ASButton onClick={handleStartBinReconfiguration}>Retry</ASButton>
        }
      >
        {getMessageFromRtkError(
          startBinReconfigurationError ?? closeWorkstationError
        )}
      </Alert>
    );
  }

  return (
    <>
      <ProgressButton
        style={{ justifySelf: "end" }}
        size="large"
        startIcon={<PlayIcon style={{ fill: "white" }} />}
        disabled={!sourceBinConfigurationId || !targetBinConfigurationId}
        onClick={handleStartBinReconfiguration}
      >
        {t("start reconfiguration")}
      </ProgressButton>
    </>
  );
};
