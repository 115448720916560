import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { CardMediaWithFallback } from "~/components/CardMediaWithFallback";
import {
  ASTableV2Cell,
  ASTableV2,
  ASTableV2Body,
  ASTableV2Header,
  ASTableV2Row
} from "~/components/autostore/table";
import { useFilteredVariantInventorySummaries } from "~/hooks/useFilteredVariantInventorySummaries";
import {
  selectOrderCreationSelectedAutostoreGridId,
  selectOrderCreationSelectedPage,
  selectOrderCreationSelectedSku,
  selectOrderCreationOnlyAvailable,
  selectOrderCreationSimplified
} from "~/redux/selectors/orderCreationSelectors";

import { useGetVariantInventorySummariesQuery } from "~/redux/warehouse/inventory.hooks";
import { VariantInventorySummaryRecord } from "~/types/api";

import { setSelectedPage, setSelectedSku } from "./orderCreation.slice";

function toKey(vis: VariantInventorySummaryRecord): string {
  return `${vis.variantId}-${vis.units}`;
}

const InventoryRow = ({ aggKey }: { aggKey: string }) => {
  const dispatch = useAppDispatch();
  const selectedSku = useAppSelector(selectOrderCreationSelectedSku);
  const simplified = useAppSelector(selectOrderCreationSimplified);

  const { data } = useGetVariantInventorySummariesQuery();
  const vis = data?.variantInventorySummaries.find(
    (record) => record && toKey(record) === aggKey
  );
  if (!vis) return <div />;

  const selected = vis.sku === selectedSku;

  return (
    <ASTableV2Row
      key={aggKey}
      onClick={() => {
        dispatch(setSelectedSku(vis.sku));
      }}
      selected={selected}
    >
      <ASTableV2Cell>
        <CardMediaWithFallback
          sx={{ height: "50px", width: "75px", backgroundSize: "contain" }}
          image={vis.imageUrl}
        />
      </ASTableV2Cell>
      <ASTableV2Cell
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start"
        }}
      >
        <Typography variant="body2">{vis.sku || "no sku"}</Typography>
        <Typography variant="body2">{vis.productName}</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {vis.temperatureZones.join(", ")}
        </Typography>
        {!simplified && (
          <Typography
            variant="subtitle2"
            color="red"
            textTransform={"capitalize"}
          >
            {vis.holds.join(", ")}
          </Typography>
        )}
      </ASTableV2Cell>
      <ASTableV2Cell align="right">{vis.totalAvailable}</ASTableV2Cell>
      {!simplified && (
        <ASTableV2Cell align="right">{`${vis.totalAvailableToMake ?? ""}`}</ASTableV2Cell>
      )}
      {!simplified && (
        <ASTableV2Cell align="right">{`${vis.total} ${vis.units}`}</ASTableV2Cell>
      )}
    </ASTableV2Row>
  );
};

export const InventoryTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedPage = useAppSelector(selectOrderCreationSelectedPage);
  const simplified = useAppSelector(selectOrderCreationSimplified);
  const onlyAvailable = useAppSelector(selectOrderCreationOnlyAvailable);
  const selectedAutostoreGridId = useAppSelector(
    selectOrderCreationSelectedAutostoreGridId
  );

  const {
    variantInventorySummaries,
    isLoading: variantInventorySummariesLoading,
    pageCount: variantInventorySummariesPageCount
  } = useFilteredVariantInventorySummaries(
    selectedPage,
    selectedAutostoreGridId,
    onlyAvailable
  );

  return (
    <ASTableV2
      gridTemplateColumns={`75px 1fr repeat(${simplified ? 1 : 3}, 100px)`}
      gridTemplateRows={`auto repeat(8, 1fr)`}
      isLoading={variantInventorySummariesLoading}
      isEmpty={!variantInventorySummaries?.length}
      isEmptyMessage="No Inventory Available"
      pagination={{
        pageCount: variantInventorySummariesPageCount,
        page: selectedPage,
        setPage: (p) => {
          dispatch(setSelectedPage(p));
          if (window.scrollTo) window.scrollTo(0, 0);
        }
      }}
    >
      <ASTableV2Header>
        <ASTableV2Row>
          <ASTableV2Cell></ASTableV2Cell>
          <ASTableV2Cell>{t("product")}</ASTableV2Cell>
          <ASTableV2Cell align="right">Available</ASTableV2Cell>
          {!simplified && (
            <ASTableV2Cell align="right">Available to Make</ASTableV2Cell>
          )}
          {!simplified && <ASTableV2Cell align="right">Quantity</ASTableV2Cell>}
        </ASTableV2Row>
      </ASTableV2Header>
      <ASTableV2Body>
        {!!variantInventorySummaries.length &&
          variantInventorySummaries.map((vis) => {
            return <InventoryRow key={toKey(vis)} aggKey={toKey(vis)} />;
          })}
      </ASTableV2Body>
    </ASTableV2>
  );
};
