import { Outlet } from "react-router";

import { ErrorBoundary } from "~/components/ErrorBoundary";
import { Navbar } from "~/components/navbar/Navbar";
import { Shell } from "~/components/shell/Shell";

export function BasicLayout() {
  return (
    <Shell>
      <Navbar />
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </Shell>
  );
}
