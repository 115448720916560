import { Box, Typography, useMediaQuery } from "@mui/material";

import { mobileWidth } from "frontend-components";
import { Fragment } from "react";

import { useAppSelector } from "~/app/store";
import CircularProgressWithLabel from "~/components/CircularProgress";
import { formatTime } from "~/lib/helpers";

import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import { selectBatches } from "./batchCards.slice";

export const BatchOrderSummaries = ({ batchId }: { batchId: Guid }) => {
  const batch = useAppSelector(selectBatches).find(
    (b) => b.batchId === batchId
  )!;
  const isMobile = useMediaQuery(mobileWidth);
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const { orderSummaries } = batch;
  const columnTemplate = isMobile ? "1fr 1fr" : "50px 1fr 50px 1fr 50px 1fr ";

  return (
    <Box
      display="grid"
      gridTemplateColumns={columnTemplate}
      alignItems="center"
    >
      {orderSummaries.map((orderSummary, i) => (
        <Fragment key={i}>
          {!isMobile && (
            <CircularProgressWithLabel
              aria-label={`order-${i}-circular-progress`}
              totalCount={orderSummary.orderPicks}
              completeCount={orderSummary.orderCompletedPicks}
              cancelledCount={orderSummary.orderCanceledPicks}
              size={24}
            />
          )}
          <Box>
            <Typography variant="caption">
              {`${orderSummary.firstName} ${orderSummary.lastName} ${formatTime(
                orderSummary.pickingEndTime,
                fulfillmentCenter?.timeZone
              )}`}
            </Typography>
            {orderSummary.externalOrderId && (
              <Typography variant="caption" sx={{ color: "darkGray.main" }}>
                {` ${orderSummary.externalOrderId}`}
              </Typography>
            )}
          </Box>
        </Fragment>
      ))}
    </Box>
  );
};
