/* eslint-disable react/no-array-index-key */
import { FiberNew, Iso, CallSplit } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EventIcon from "@mui/icons-material/Event";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CardActionArea,
  CardHeader,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography
} from "@mui/material";
import { Dayjs } from "dayjs";
import { ProgressButton } from "frontend-components";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { warehouseService } from "~/api/warehouse";
import { ConfirmationModal } from "~/components/ConfirmationModal";
import DatePickerContainer from "~/components/DatePickerContainer";
import { ErrorSuccessSnackbar } from "~/components/ErrorSuccessSnackbar";

import { BinLocation } from "~/components/productCard/BinLocation";
import { CardAvatar } from "~/components/productCard/CardAvatar";
import { ProductCardContainer } from "~/components/productCard/ProductCardContainer";
import { ProductImage } from "~/components/productCard/ProductImage";
import { useNavbar } from "~/hooks/useNavbar";
import { useView } from "~/hooks/useView";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import { formatUtcDate } from "~/lib/dateHelpers";
import {
  checkIsExpiration,
  generateLocationNameFromBin,
  inventoryDateLabel,
  getInventoryDateObj,
  getInventoryDateObjDayjs,
  hasUserOriginSpecificMessage
} from "~/lib/helpers";
import { adjustInventoryNet } from "~/redux/actions/inventory";
import {
  getPutawayInventoryByVariant,
  clearErrorMessage,
  putAwayItem
} from "~/redux/actions/putaway";
import { setUserMessage } from "~/redux/actions/site";
import { StoreState } from "~/redux/reducers";
import { UserMessage } from "~/redux/reducers/site";
import { PutAwayTaskSummaryDto, SearchBinRecord } from "~/types/api";

import PutawayAdjustModal from "./PutawayAdjustModal";

const mapStateToProps = (
  state: StoreState
): {
  putawayInventoryByVariant: PutAwayTaskSummaryDto[];
  loadingPutawayByVariant: boolean;
  putawayError: string | null;
  userMessages: UserMessage[] | [];
  inv_inventoryDateLabel: string;
  workstationId: Guid | undefined;
} => ({
  putawayInventoryByVariant: state.putaway.putawayInventoryByVariant,
  loadingPutawayByVariant: state.putaway.loadingPutawayByVariant,
  putawayError: state.putaway.error,
  userMessages: state.site.userMessages,
  inv_inventoryDateLabel: state.site.clientConfig.inv_inventoryDateLabel,
  workstationId: state.workstations.siteWorkstation?.id
});

const connector = connect(mapStateToProps, {
  getPutawayInventoryByVariant,
  adjustInventoryNet,
  clearErrorMessage,
  putAwayItem,
  setUserMessage
});
type PutawayTaskInheritedProps = { viewTitle?: string };
type PropsFromRedux = ConnectedProps<typeof connector>;
export type PutawayProps = PropsFromRedux & PutawayTaskInheritedProps;

export function Putaway(props: PutawayProps) {
  const {
    putawayInventoryByVariant,
    loadingPutawayByVariant,
    putawayError,
    userMessages,
    inv_inventoryDateLabel,
    workstationId,
    viewTitle
  } = props;

  const { variantId } = useParams<{ variantId: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useView({ permanentSidenav: true });
  useNavbar({ viewTitle });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props.getPutawayInventoryByVariant(variantId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantId]);

  // active putaway is task being operated on (skip, partial, new bin etc)
  const [activePutaway, setActivePutaway] =
    useState<PutAwayTaskSummaryDto | null>(null);
  const [partialQtyModalOpen, setPartialQtyModalOpen] =
    useState<boolean>(false);
  const [partialQuantity, setPartialQuantity] = useState<number | null>(null);
  const [newBinModalOpen, setNewBinModalOpen] = useState<boolean>(false);
  const [newBinLocation, setNewBinLocation] = useState<string | null>(null);
  const [inventoryDate, setInventoryDate] = useState<Date>(
    moment().utc().add(1, "year").toDate()
  );
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [putawayForAdjustModal, setPutawayForAdjustModal] =
    useState<PutAwayTaskSummaryDto | null>(null);

  const adjustExpiration = (putaway: PutAwayTaskSummaryDto, date: Dayjs) => {
    const countObj = { units: putaway.quantity.units, value: 0 };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props
      .adjustInventoryNet({
        netAdjustment: countObj,
        netCommittedAdjustment: countObj,
        inventoryId: putaway.inventoryId || "",
        reasonCode: "Expiration Corrected",
        ...getInventoryDateObjDayjs(inv_inventoryDateLabel, date),
        workstationId
      })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        props.getPutawayInventoryByVariant(variantId);
      });
  };

  useEffect(() => {
    const hasInventoryAdjustedMessage =
      hasUserOriginSpecificMessage(
        userMessages,
        "ADJUST_INVENTORY_SUCCESS",
        "success"
      ) ||
      hasUserOriginSpecificMessage(
        userMessages,
        "ADJUST_INVENTORY_NET_SUCCESS",
        "success"
      );
    // if after succesful quantity change (inv adjustment), we have no putaway tasks left, redirect to putaway list
    if (!putawayInventoryByVariant.length && hasInventoryAdjustedMessage) {
      navigate("/putaway");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putawayInventoryByVariant, userMessages.length]);

  // happy path putaway
  const handleInventoryPutaway = (putaway: PutAwayTaskSummaryDto) => {
    if (putaway.target && putaway.inventoryId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props
        .putAwayItem({
          putAwayTaskId: putaway.putAwayTaskId,
          quantity: putaway.remaining,
          targetBinId: putaway.target.bin.binId,
          targetPort: undefined,
          ...getInventoryDateObj(
            inv_inventoryDateLabel,
            moment(
              checkIsExpiration(inv_inventoryDateLabel)
                ? putaway.expirationDate
                : putaway.manufactureDate
            )
          ),
          workstationId
        })
        .then(() => {
          navigate("/putaway");
        });
    } else if (!putaway.target) {
      props.setUserMessage({
        title: "Putaway task does not have a target.",
        severity: "error",
        origin: "putaway/PUTAWAY_TASK_DOES_NOT_HAVE_A_TARGET"
      });
    } else if (!putaway.inventoryId) {
      props.setUserMessage({
        title: "Putaway task does not have an Inventory ID.",
        severity: "error",
        origin: "putaway//PUTAWAY_TASK_DOES_NOT_HAVE_AN_INVENTORY_ID"
      });
    }
  };

  const handlePartialPutaway = () => {
    if (activePutaway && activePutaway.inventoryId && activePutaway.target) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props
        .putAwayItem({
          putAwayTaskId: activePutaway.putAwayTaskId,
          quantity: {
            units: activePutaway.remaining.units,
            value: partialQuantity || 0
          },
          targetBinId: activePutaway.target.bin.binId,
          targetPort: undefined,
          ...getInventoryDateObj(
            inv_inventoryDateLabel,
            moment(
              checkIsExpiration(inv_inventoryDateLabel)
                ? activePutaway.expirationDate
                : activePutaway.manufactureDate
            )
          ),
          workstationId
        })
        .then(() => {
          setPartialQtyModalOpen(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          props.getPutawayInventoryByVariant(variantId);
        });
    }
  };

  const handleNewBinPutaway = async () => {
    const binSearch = await warehouseService.get<SearchBinRecord[]>(
      `/api/bins/search/${newBinLocation || ""}`
    );
    const binRecords = binSearch.data;
    const bin = binRecords && binRecords.length === 1 ? binRecords[0] : null;
    if (!bin) {
      props.setUserMessage({
        title: "No Bin Found",
        severity: "error",
        origin: "putaway/NO_BIN_FOUND"
      });
    }
    return activePutaway && bin
      ? props
          .putAwayItem({
            putAwayTaskId: activePutaway.putAwayTaskId,
            quantity: {
              units: activePutaway.remaining.units,
              value: activePutaway.remaining.value
            },
            targetBinId: bin.bin_id,
            targetPort: undefined,
            ...getInventoryDateObj(
              inv_inventoryDateLabel,
              moment(
                checkIsExpiration(inv_inventoryDateLabel)
                  ? activePutaway.expirationDate
                  : activePutaway.manufactureDate
              )
            ),
            workstationId
          })
          .then(() => {
            navigate("/putaway");
          })
      : null;
  };

  useKeyDownHandler();
  useBarcodeScanner<PutAwayTaskSummaryDto | false>({
    findScanMatch: (buffer: string) => {
      if (newBinModalOpen) {
        // user is scanning new bin
        setNewBinLocation(buffer);
        return false;
      } // user is scanning target bin provided by warehouse
      if (partialQtyModalOpen && !partialQuantity) {
        props.setUserMessage({
          title: "Set quantity before scanning bin",
          severity: "error",
          origin: "putaway/SET_QUANTITY_BEFORE_SCANNING_BIN"
        });
        return false;
      }
      const putawayMatch = putawayInventoryByVariant.find((putaway) => {
        const target = putaway.target || null;
        return (
          target &&
          generateLocationNameFromBin(target.bin)
            .toLowerCase()
            .replaceAll(" ", "")
            .replaceAll("-", "") === buffer.toLowerCase()
        );
      });
      return putawayMatch || false;
    },
    processScanMatch: (match) => {
      if (match) {
        if (partialQtyModalOpen) {
          handlePartialPutaway();
        } else {
          handleInventoryPutaway(match);
        }
      }
    },
    deps: [
      inventoryDate,
      putawayInventoryByVariant,
      newBinModalOpen,
      partialQtyModalOpen,
      partialQuantity
    ]
  });
  return (
    <Container>
      {putawayInventoryByVariant &&
        !loadingPutawayByVariant &&
        putawayInventoryByVariant.map((putaway: PutAwayTaskSummaryDto, i) => {
          const targetBin = (!!putaway.target && putaway.target.bin) || null;

          return (
            <Box
              key={`${putaway.inventoryId || ""}-${i}`}
              marginBottom="20px"
              marginTop="24px"
            >
              <ProductCardContainer>
                <CardHeader
                  avatar={
                    <CardAvatar
                      quantity={putaway.remaining.value}
                      quantityIconVariant="filled"
                      unitOfMeasure={putaway.remaining.units}
                    />
                  }
                  title={putaway.product.name}
                  subheader={
                    <>
                      <Typography variant="body2">
                        {putaway.product.brandName}
                      </Typography>
                      {putaway.purchaseOrderLineItem?.purchaseOrderNumber && (
                        <Typography variant="body2">
                          {putaway.purchaseOrderLineItem.purchaseOrderNumber}
                        </Typography>
                      )}
                    </>
                  }
                />
                <BinLocation bin={targetBin} />
                <CardActionArea
                  onClick={(): void => {
                    if (targetBin) {
                      setActivePutaway(putaway);
                      setIsConfirmationModalOpen(true);
                    }
                  }}
                  style={{ height: 220 }}
                  data-testid="card-action"
                >
                  <ProductImage
                    imageFilename={putaway.product.imageFilename}
                    modalStatus="none"
                    modalStatusText={null}
                    upc={putaway.product.upc}
                    sku={putaway.product.sku}
                    data-testid={`product-image-${i}`}
                  />
                </CardActionArea>
                <Container sx={{ backgroundColor: "background.default" }}>
                  <Typography
                    variant="overline"
                    color="initial"
                    component="p"
                    align="center"
                  >
                    {` ${t(inventoryDateLabel(inv_inventoryDateLabel))}: ${
                      putaway.manufactureDate || putaway.expirationDate
                        ? formatUtcDate(
                            putaway.manufactureDate || putaway.expirationDate
                          )
                        : "No date set"
                    }`}
                  </Typography>
                </Container>
                <BottomNavigation showLabels sx={{ marginTop: 1 }}>
                  <BottomNavigationAction
                    label={t("change quantity")}
                    icon={<Iso />}
                    onClick={() => setPutawayForAdjustModal(putaway)}
                  />
                  {targetBin && (
                    <BottomNavigationAction
                      label={t("split putaway")}
                      icon={<CallSplit />}
                      onClick={() => {
                        setActivePutaway(putaway);
                        setPartialQtyModalOpen(true);
                      }}
                    />
                  )}
                  <BottomNavigationAction
                    label={t("select new bin")}
                    icon={<FiberNew />}
                    onClick={() => {
                      setActivePutaway(putaway);
                      setNewBinModalOpen(true);
                    }}
                  />
                  <BottomNavigationAction
                    label={t(`change ${inv_inventoryDateLabel}`)}
                    icon={<EventIcon />}
                    onClick={() => {
                      setActivePutaway(putaway);
                      setDatePickerIsOpen(true);
                    }}
                  />
                </BottomNavigation>
              </ProductCardContainer>
            </Box>
          );
        })}

      {/* Partial Putaway Modal */}
      <Dialog
        key="partial-putaway-dialog"
        open={!!activePutaway && partialQtyModalOpen}
        onClose={() => {
          setPartialQtyModalOpen(false);
          setActivePutaway(null);
        }}
      >
        <DialogTitle>{t("split putaway")}</DialogTitle>
        <DialogContent sx={{ textAlign: "center", mt: 1 }}>
          <Box maxWidth="300px">
            <FormControl style={{ margin: 10 }}>
              <TextField
                inputProps={{
                  id: "putaway-qty"
                }}
                variant="outlined"
                type="number"
                label={t("putaway qty")}
                value={partialQuantity || ""}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                  if ((e.target.value as number) < 0) {
                    setPartialQuantity(0);
                  } else if (
                    activePutaway?.remaining.value &&
                    (e.target.value as number) > activePutaway?.remaining.value
                  ) {
                    setPartialQuantity(activePutaway?.remaining.value);
                  } else {
                    setPartialQuantity(e.target.value as number);
                  }
                }}
              />
            </FormControl>
            <Chip
              label={t("scan bin confirm")}
              disabled={
                !!(
                  partialQuantity &&
                  activePutaway &&
                  partialQuantity >= (activePutaway.remaining.value || 0)
                )
              }
              icon={<DoneIcon />}
              color={partialQuantity ? "primary" : "default"}
              style={{ margin: 2 }}
              onClick={() => handlePartialPutaway()}
            />
          </Box>
        </DialogContent>
      </Dialog>

      {/* New Bin Location Modal */}
      <Dialog
        key="new-bin-dialog"
        open={!!activePutaway && newBinModalOpen}
        onClose={() => {
          setNewBinModalOpen(false);
          setActivePutaway(null);
        }}
      >
        <DialogTitle>{t("new bin")}</DialogTitle>
        <DialogContent sx={{ textAlign: "center", mt: 1 }}>
          <FormControl style={{ margin: 10 }}>
            <TextField
              variant="outlined"
              type="text"
              value={newBinLocation}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                setNewBinLocation(e.target.value as string);
              }}
              placeholder={t("scan new bin")}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <ProgressButton
            autoFocus
            onClick={() => {
              setNewBinModalOpen(false);
              setActivePutaway(null);
            }}
            color="secondary"
            startIcon={<CloseIcon />}
            buttonSize="medium"
            emphasis="high"
            responsive
            fullWidth
          >
            {t("cancel")}
          </ProgressButton>
          <ProgressButton
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
              handleNewBinPutaway();
              setNewBinModalOpen(false);
              setActivePutaway(null);
            }}
            color="primary"
            autoFocus
            startIcon={<CheckIcon />}
            buttonSize="medium"
            emphasis="high"
            responsive
            fullWidth
          >
            {t("confirm")}
          </ProgressButton>
        </DialogActions>
      </Dialog>

      {/* Adjust Quantity Modal */}
      <Dialog
        open={!!putawayForAdjustModal}
        onClose={() => setPutawayForAdjustModal(null)}
      >
        {!!putawayForAdjustModal && (
          <PutawayAdjustModal
            putawayTask={putawayForAdjustModal}
            closeModal={() => setPutawayForAdjustModal(null)}
            successCallback={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
              props.getPutawayInventoryByVariant(variantId);
            }}
          />
        )}
      </Dialog>

      <ErrorSuccessSnackbar
        errorMessage={putawayError}
        clearErrorMessage={() => {
          props.clearErrorMessage();
        }}
        clearSuccessMessage={() => null}
      />
      <DatePickerContainer
        isOpen={datePickerIsOpen}
        setIsOpenCb={(isOpen) => setDatePickerIsOpen(isOpen)}
        onDateChange={(date: Date | null): void => {
          if (date) setInventoryDate(date);
        }}
        selectedDate={inventoryDate}
        onAcceptDate={(date) => {
          if (activePutaway && date) {
            setDatePickerIsOpen(false);
            adjustExpiration(activePutaway, date);
          }
        }}
        minDate={
          checkIsExpiration(inv_inventoryDateLabel) ? new Date() : undefined
        }
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        confirmCb={() => {
          if (activePutaway) {
            handleInventoryPutaway(activePutaway);
            setIsConfirmationModalOpen(false);
            setActivePutaway(null);
          }
        }}
        closeCb={() => {
          setIsConfirmationModalOpen(false);
          setActivePutaway(null);
        }}
        modalTitle={t("complete putaway confirm")}
        modalText=""
      />
    </Container>
  );
}

export default connector(Putaway);
