import {
  CreateRecurringScheduleRequest,
  RecurringScheduleDto
} from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const recurringScheduleApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getRecurringSchedules: build.query<RecurringScheduleDto[], void>({
      query: () => ({
        url: `/recurring-schedules`,
        method: "GET"
      }),
      providesTags: ["recurring schedule"]
    }),
    deleteRecurringSchedule: build.mutation<void, Guid>({
      query: (recurringScheduleId) => ({
        url: `/recurring-schedules/${recurringScheduleId}`,
        method: "DELETE"
      })
    }),
    postRecurringSchedule: build.mutation<void, CreateRecurringScheduleRequest>(
      {
        query: (data) => ({
          url: `/recurring-schedules`,
          method: "POST",
          data
        }),
        invalidatesTags: ["recurring schedule"]
      }
    )
  }),
  overrideExisting: false
});
