import { VariantDto, VariantFrontendDto } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const variantApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    variantBySku: build.query<VariantDto, string>({
      query: (sku) => ({
        url: `/variants/by-sku/${sku}`
      }),
      providesTags: ["variant"]
    }),
    variantsById: build.query<
      VariantFrontendDto[],
      { variantIds: Guid[] | string }
    >({
      query: ({ variantIds }) => {
        return {
          url: `/variants`,
          method: "POST",
          data: Array.isArray(variantIds) ? variantIds : [variantIds]
        };
      }
    }),
    getLastPickedUOM: build.query<string, { variantId: Guid }>({
      query: ({ variantId }) => ({
        url: `/variants/last-picked-uom/${variantId}`
      })
    })
  })
});
