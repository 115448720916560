import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import { ProgressButton } from "frontend-components";
import { t } from "i18next";

export function AutostorePortSelector({
  ports,
  unusedPorts,
  addPort,
  updatePort,
  removePort
}: {
  ports: number[];
  unusedPorts: number[];
  addPort: () => void;
  updatePort: (index: number, value: number) => void;
  removePort: (index: number) => void;
}) {
  const showAddPortButton = ports && unusedPorts.length > 1 && ports.length < 3;

  return (
    <Box>
      <Stack direction="column" spacing={2}>
        {ports.map((portId, index) => (
          <Box key={index}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box sx={{ width: "66.66%" }}>
                <InputLabel shrink>{t("port")}</InputLabel>
                <Select
                  aria-label="autostore port selector"
                  fullWidth
                  value={portId.toString()}
                  onChange={(event) =>
                    updatePort(index, parseInt(event.target.value, 10))
                  }
                  variant="standard"
                >
                  {unusedPorts
                    .filter((port) => !ports.includes(port) || port === portId)
                    .map((port) => (
                      <MenuItem key={port} value={port}>
                        {`Port ${port}`}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box sx={{ width: "33.33%" }}>
                <IconButton
                  aria-label="remove port"
                  onClick={() => removePort(index)}
                  data-testid={`remove-port-${index}`}
                  disabled={ports.length <= 1}
                  sx={{ mt: 2 }}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
            </Stack>
          </Box>
        ))}
        <Box>
          {showAddPortButton && (
            <ProgressButton
              onClick={addPort}
              disabled={ports.length >= unusedPorts.length}
            >
              <AddCircleIcon />
              <Typography sx={{ ml: 1 }}>{t("add port")}</Typography>
            </ProgressButton>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
