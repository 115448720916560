import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardHeader,
  Chip,
  Container,
  List,
  Stack
} from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ProgressButton } from "frontend-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";

import { useAppSelector, useAppDispatch } from "~/app/store";

import { ActivityIndicator } from "./ActivityIndicator";
import { EventChip } from "./EventChip";
import EventInfoBox from "./EventInfoBox";
import WorkstationSelectorModal from "./WorkstationSelectorModal";
import {
  setEventExplorerIds,
  AndonEvent,
  selectAndonWorkstations,
  selectEventExplorerIds,
  selectAndonLooseThreadEvents
} from "./andon.slice";

export function EventExplorer() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [focusedEvent, setFocusedEvent] = useState<AndonEvent | null>(null);
  const eventExplorerIds = useAppSelector(selectEventExplorerIds);
  const andonWorkstations = useAppSelector(selectAndonWorkstations);
  const looseThreadEvents = useAppSelector(selectAndonLooseThreadEvents);

  const [workstationSelectorOpen, setWorkstationSelectorOpen] = useState(false);

  return (
    <Container>
      <Stack gap={4}>
        {/* buttons container */}
        <Stack direction="row" gap={4}>
          <ProgressButton
            emphasis="high"
            responsive
            variant="contained"
            color="primary"
            onClick={() => {
              setWorkstationSelectorOpen(true);
            }}
          >
            {t("select workstations")}
          </ProgressButton>
          <Box height={25} width={100}>
            <ActivityIndicator />
          </Box>
        </Stack>
        <Stack direction="row" gap={2}>
          {/* first column */}
          <List sx={{ width: "32rem" }}>
            {Object.values(andonWorkstations)
              .filter((aws) => eventExplorerIds.includes(aws.id))
              .slice()
              .sort(
                (a, b) =>
                  a.workstation.autostoreGridName.localeCompare(
                    b.workstation.autostoreGridName
                  ) ||
                  a.workstation.deviceId.localeCompare(b.workstation.deviceId)
              )
              .map((aws) => {
                const { workstation, parentPort, ports } = aws;

                return (
                  <Accordion component="li" key={workstation.id}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-labelledby={`workstation-${workstation.deviceId}`}
                    >
                      <Stack flexGrow={1} gap={1} paddingRight={1}>
                        <Box display="flex" flexGrow={1}>
                          <Typography
                            id={`workstation-${workstation.deviceId}`}
                          >
                            {workstation.deviceId}
                          </Typography>
                          <Stack
                            direction="row"
                            justifySelf="flex-end"
                            marginLeft="auto"
                            gap={1}
                          >
                            {ports.map((port) => (
                              <Chip
                                key={`port-${port.portId}`}
                                color="primary"
                                variant="outlined"
                                label={port.portId}
                                size="small"
                              />
                            ))}
                            {parentPort && (
                              <Chip
                                color="primary"
                                label={parentPort.portId}
                                size="small"
                              />
                            )}
                          </Stack>
                        </Box>
                        <Stack direction="row">
                          {aws.events.length === 0 && (
                            <Typography color="textSecondary">
                              {t("No Events")}
                            </Typography>
                          )}
                          {aws.events.slice(0, 10).map((andonEvent, i) => (
                            <EventChip
                              key={`${
                                typeof andonEvent.timestamp === "string"
                                  ? andonEvent.timestamp
                                  : andonEvent.timestamp.toISOString()
                              }x${i}`}
                              event={andonEvent}
                            />
                          ))}
                        </Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "5px",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          height: "300px",
                          overflowY: "scroll",
                          marginTop: "10px"
                        }}
                      >
                        {aws.events.map((ae, index) => (
                          <EventInfoBox
                            key={`andonEvent-${index}`}
                            andonEvent={ae}
                            onClickCb={() => setFocusedEvent(ae)}
                          />
                        ))}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            <Accordion component="li">
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-label="Loose Threads"
              >
                <Stack gap={1}>
                  <Typography>Loose Threads</Typography>
                  <Stack direction="row">
                    {looseThreadEvents.length === 0 && (
                      <Typography color="textSecondary">No Events</Typography>
                    )}
                    {looseThreadEvents.slice(0, 10).map((andonEvent, i) => (
                      <EventChip
                        key={`${i}a${
                          typeof andonEvent.timestamp === "string"
                            ? andonEvent.timestamp
                            : andonEvent.timestamp.toISOString()
                        }`}
                        event={andonEvent}
                      />
                    ))}
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    display: "flex",
                    padding: "5px",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    height: "300px",
                    overflowY: "scroll",
                    marginTop: "10px"
                  }}
                >
                  {looseThreadEvents.map((ae, index) => (
                    <EventInfoBox
                      key={`andonEvent-${index}`}
                      andonEvent={ae}
                      onClickCb={() => setFocusedEvent(ae)}
                    />
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </List>

          {/* second column */}
          <Card sx={{ my: 1, flexGrow: 1 }}>
            <CardHeader title={t("Focused Event")} />
            <CardContent>
              {focusedEvent ? (
                <ReactJson
                  displayDataTypes={false}
                  displayObjectSize={false}
                  quotesOnKeys={false}
                  collapsed={3}
                  theme="tube"
                  src={focusedEvent || {}}
                  indentWidth={3}
                />
              ) : (
                <Typography color="textSecondary">
                  {t("Select an event to view details")}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Stack>
      </Stack>
      {/* modals */}
      <WorkstationSelectorModal
        isOpen={workstationSelectorOpen}
        selectedWorkstationIds={eventExplorerIds}
        workstationSelectorCb={(newIds) => {
          dispatch(setEventExplorerIds(newIds));
        }}
        setModalOpen={(isOpen) => setWorkstationSelectorOpen(isOpen)}
      />
    </Container>
  );
}

export default EventExplorer;
