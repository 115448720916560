import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";

import { Navigate } from "react-router-dom";

import { ErrorBoundary } from "~/components/ErrorBoundary";
import { ErrorPage } from "~/components/ErrorPage";
import { LoadingPage } from "~/components/LoadingPage";
import { AutostoreStatusInfo } from "~/components/debug/AutostoreStatusInfo";
import EventSimulator from "~/components/debug/EventSimulator";
import { Navbar } from "~/components/navbar/Navbar";
import { Shell } from "~/components/shell/Shell";
import envConstants from "~/config/envConstants";
import { DevCheatsContext } from "~/hooks/useDevCheats";
import { getStatusCodeFromError } from "~/lib/getStatusCodeFromError";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

import { useAppSelector } from "./store";
/**
 * Renders Autostore pages.
 *
 * Fetches workstations and throws an error if fetching fails.
 * @constructor
 */
export function AutostoreLayout() {
  const { isPortPolling, showAutostoreStatus, showEventSimulator } =
    useContext(DevCheatsContext);

  const { t } = useTranslation();
  const [isAutostoreStatusOpen, setIsAutostoreStatusOpen] = useState(false);
  const [isEventSimulatorOpen, setIsEventSimulatorOpen] = useState(false);
  const userHasAnFc = !!useAppSelector(selectUsersFulfillmentCenter);

  // fetch workstations as a prerequisite to rendering any children
  const { isLoading: isGetWorkstationsLoading, error: getWorkstationsError } =
    useGetWorkstationsQuery(userHasAnFc ? undefined : skipToken);

  const devCheatClickHandler = useCallback(() => {
    if (envConstants.DEV_CHEATS_UAT !== "true") {
      return;
    }
    if (showAutostoreStatus) {
      setIsAutostoreStatusOpen(!isAutostoreStatusOpen);
    }
    if (showEventSimulator) {
      setIsEventSimulatorOpen(!isEventSimulatorOpen);
    }
  }, [
    isAutostoreStatusOpen,
    isEventSimulatorOpen,
    showAutostoreStatus,
    showEventSimulator
  ]);

  if (!userHasAnFc) {
    return <Navigate to="/settings" replace />;
  }

  return (
    <Shell>
      <Navbar onDevCheatClick={devCheatClickHandler} />
      <ErrorBoundary>
        {isGetWorkstationsLoading && (
          <LoadingPage loadingMessage={t("fetching workstations")} />
        )}
        {!!getWorkstationsError && (
          <ErrorPage
            errorMessage={getMessageFromRtkError(getWorkstationsError)}
            errorCode={getStatusCodeFromError(getWorkstationsError)}
          />
        )}
        {!isGetWorkstationsLoading && !getWorkstationsError && <Outlet />}
        {isAutostoreStatusOpen && (
          <AutostoreStatusInfo
            defaultAccordion="bin present"
            portPollingIsActive={isPortPolling}
            floating
          />
        )}
        {isEventSimulatorOpen && <EventSimulator floating />}
      </ErrorBoundary>
    </Shell>
  );
}
