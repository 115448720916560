import { variantToDisplayName } from "~/lib/helpers";

import { ProductSearchProduct } from "~/types/api";

/**
 * Create abbreviated results records for the given Search Product hits.
 *
 * @exactUpcMatchFilter Optional UPC to filter results by.
 */
export const createProductSearchOptions = (args: {
  hits: ProductSearchProduct[];
  exactUpcMatchFilter?: string;
}): {
  type: string;
  variantId: string;
  displayText: string;
}[] => {
  const { hits, exactUpcMatchFilter } = args;
  return hits
    .filter((hit) => {
      if (
        exactUpcMatchFilter &&
        ![...(hit.alternateLookups || []), hit.upc].includes(
          exactUpcMatchFilter
        )
      )
        return false;
      return true;
    })
    .flatMap((hit) =>
      hit.variants.map((variant) => ({
        type: "product",
        variantId: variant.productVariantId,
        sku: variant.sku,
        displayText: variantToDisplayName(variant, hit.name)
      }))
    );
};
