import {
  Auth0Client,
  RedirectLoginOptions,
  IdToken,
  LogoutOptions
} from "@auth0/auth0-spa-js";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import sha1 from "crypto-js/sha1";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { Auth0Profile } from "~/api/usersTypes/auth0Profile";
import { LoginResponse } from "~/api/usersTypes/loginResponse";
import {
  handleWarehouseErrorAsyncThunk,
  warehouseService
} from "~/api/warehouse";

import { AsyncAppThunk } from "~/app/store";

import envConstants from "~/config/envConstants";
import { StoreState } from "~/redux/reducers";

import { LoginState } from "./login.slice.interfaces";
import { persistMigrate, persistVersion } from "./login.slice.migrations";

const qubitAuthClient: Auth0Client = new Auth0Client({
  domain: envConstants.AUTH0_CONFIG_DOMAIN,
  clientId: envConstants.AUTH0_CONFIG_CLIENT_ID,
  authorizationParams: {
    audience: envConstants.AUTH0_CONFIG_AUDIENCE,
    redirect_uri: `${window.location.origin}/callback`
  },
  useRefreshTokens: true,
  cacheLocation: "localstorage"
});

const andonAuthClient: Auth0Client = new Auth0Client({
  domain: envConstants.AUTH0_CONFIG_DOMAIN,
  clientId: envConstants.AUTH0_CONFIG_ANDON_CLIENT_ID,
  authorizationParams: {
    audience: envConstants.AUTH0_CONFIG_AUDIENCE,
    redirect_uri: `${window.location.origin}/callback`
  },
  useRefreshTokens: true,
  cacheLocation: "localstorage"
});

/// this is identical to LoginResponse that is currently part of the Users service API.
export interface LoginSuccessResponse {
  userProfile: Auth0Profile;
  tokens: {
    idToken: string;
    accessToken: string;
  };
}

const initialState: LoginState = {
  accessToken: null,
  profile: null,
  errorMessage: null,
  isUserLoggedIn: false,
  authMethod: null
};

export function formProfileFromIdTokenClaims(
  idTokenClaims: Partial<IdToken>
): Auth0Profile {
  const auth0Namespace = envConstants.AUTH0_CONFIG_NAMESPACE;

  return {
    name: idTokenClaims["name"] || "",
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    roles: idTokenClaims[`${auth0Namespace}/roles`],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    sub: idTokenClaims["sub"] || "",
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    userId: idTokenClaims["sub"] || "",
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    "/client_ids": idTokenClaims[`${auth0Namespace}/client_ids`],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    login_path: idTokenClaims[`${auth0Namespace}/login_path`] || "/login",
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    custom_login_path:
      idTokenClaims[`${auth0Namespace}/custom_login_path`] || ""
  };
}

/**
 * Initiates the Qubit login flow with a redirect. Only the "pending" action can be handled.
 */
export const loginQubit = createAsyncThunk<
  void,
  RedirectLoginOptions | undefined
>("login/loginQubit", async (options) => {
  await qubitAuthClient.loginWithRedirect(options);
});

/**
 * Initiates the Andon login flow with a redirect.
 */
export const loginAndon = createAsyncThunk<
  void,
  RedirectLoginOptions | undefined
>("login/loginAndon", async (options) => {
  await andonAuthClient.loginWithRedirect(options);
});

/**
 * This must return the user profile for valid users. The auth callback page calls this to determine if
 * a user is authenticated.
 */
export const handleAuthCallback = createAsyncThunk<
  Auth0Profile,
  void,
  {
    state: StoreState;
    rejectValue: string;
  }
>("login/handleAuthCallback", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState();

    // set when the user clicks the login button, depending on the login path
    const method = state.login.authMethod;

    switch (method) {
      case "QUBIT": {
        // this library function parses the url to get the tokens
        // stores these tokens (ID token, access token, and refresh token) in qubitAuthClient
        await qubitAuthClient.handleRedirectCallback();
        const idTokenClaims: Partial<IdToken> | undefined =
          await qubitAuthClient.getIdTokenClaims();

        // passes idToken to state.login.profile in slice below
        return idTokenClaims
          ? formProfileFromIdTokenClaims(idTokenClaims)
          : thunkAPI.rejectWithValue("user info not found");
      }
      case "ANDON": {
        await andonAuthClient.handleRedirectCallback();
        const idTokenClaims = await andonAuthClient.getIdTokenClaims();

        return idTokenClaims
          ? formProfileFromIdTokenClaims(idTokenClaims)
          : thunkAPI.rejectWithValue("user info not found");
      }
      case "TEST":
      case "E2E":
      case "BEV_QUBIT": {
        // we expect this to be set during the initial login. // question
        return (
          state.login.profile ?? thunkAPI.rejectWithValue("user info not found")
        );
      }
      default:
        return thunkAPI.rejectWithValue(
          "Cannot handle redirect callback: user not logged in or login method not detected."
        );
    }
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(JSON.stringify(e.message));
    } else {
      return thunkAPI.rejectWithValue("An unknown error occurred.");
    }
  }
});

// for bev client operator login
export const getLoginResponseFromWarehouse = createAsyncThunk<
  LoginResponse,
  {
    operator: string;
    password: string;
  },
  {
    state: StoreState;
    rejectValue: string;
  }
>("getLoginResponseFromWarehouse", async ({ operator, password }, thunkAPI) => {
  try {
    const hash = sha1(password).toString();

    const store = thunkAPI.getState();
    const workstation = store.workstations.siteWorkstation;
    const fulfillmentCenterId =
      store.workstations.siteFulfillmentCenterId || "";
    const gridName: unknown = (workstation || {}).autostoreGridName || "";
    const workstationId: unknown =
      (workstation || {}).deviceId || "00000000-0000-0000-0000-000000000000";

    const response = await warehouseService.post<LoginResponse>(
      `/pepsi/api/v1/user/login`,
      {
        Operator: operator,
        Password: hash,
        FulfillmentCenterId: fulfillmentCenterId,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        AutoStoreGridName: gridName,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        WorkstationId: workstationId
      }
    );
    const { data } = response;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(handleWarehouseErrorAsyncThunk(err));
  }
});

/**
 * Handle authMethod-specific logout flows.
 * Similar to the login thunks, this may only dispatch the pending action.
 */
export const logout = createAsyncThunk<
  void,
  LogoutOptions | undefined,
  { state: StoreState; rejectValue: string }
>("logout", async (options, thunkAPI) => {
  const state = thunkAPI.getState();
  const method = state.login.authMethod;
  switch (method) {
    case "QUBIT":
      return await qubitAuthClient.logout(options);
    case "ANDON":
      return await andonAuthClient.logout(options);
    case "BEV_QUBIT":
      return;
    default:
      return;
  }
});

export const accessTokenFactory = async (
  accessToken: string | null,
  authMethod: string | null
) => {
  switch (authMethod) {
    case "QUBIT":
      try {
        return await qubitAuthClient.getTokenSilently();
      } catch {
        return "";
      }
    case "ANDON":
      try {
        return await andonAuthClient.getTokenSilently();
      } catch {
        return "";
      }
    case "BEV_QUBIT":
      return accessToken ?? "";
    case "E2E":
      return window.localStorage.getItem("e2e_access_token") ?? "";
    default:
      // This should further prevent users from needing to log out when we migrate to this new version.
      // It can be replaced with "" after this has been deployed.
      return accessToken ?? "";
  }
};

// this is not a redux thunk because it is called frequently and would overwhelm the reducer, making it harder to read history.
export const getAccessToken = (): AsyncAppThunk<string> => (_, getState) => {
  const state = getState();
  return accessTokenFactory(state.login.accessToken, state.login.authMethod);
};

// Slice
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginTest: (state: LoginState, action: PayloadAction<Auth0Profile>) => {
      state.accessToken = "access_token";
      state.profile = action.payload;
      state.isUserLoggedIn = true;
      state.authMethod = "TEST";
    },
    clearLoginState: (state: LoginState) => {
      state.accessToken = null;
      state.profile = null;
      state.errorMessage = null;
      state.isUserLoggedIn = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginQubit.pending, (state: LoginState) => {
      state.authMethod = "QUBIT";
    });
    builder.addCase(loginAndon.pending, (state: LoginState) => {
      state.authMethod = "ANDON";
    });
    builder.addCase(loginQubit.rejected, (state: LoginState) => {
      state.errorMessage = "Login failed.  Please try again.";
    });
    builder.addCase(loginAndon.rejected, (state: LoginState) => {
      state.errorMessage = "Login failed.  Please try again.";
    });

    builder.addCase(
      handleAuthCallback.fulfilled,
      (state: LoginState, action: PayloadAction<Auth0Profile | null>) => {
        state.isUserLoggedIn = !!action.payload;
        state.errorMessage = null;
        state.profile = action.payload;
      }
    );
    builder.addCase(
      handleAuthCallback.rejected,
      (state: LoginState, action: PayloadAction<string | undefined>) => {
        state.errorMessage = action.payload ?? "An unknown error occurred.";
      }
    );
    builder.addCase(
      getLoginResponseFromWarehouse.pending,
      (state: LoginState) => {
        state.accessToken = null;
        state.profile = null;
        state.errorMessage = null;
        state.authMethod = "BEV_QUBIT";
      }
    );
    builder.addCase(
      getLoginResponseFromWarehouse.fulfilled,
      (state: LoginState, action: PayloadAction<LoginResponse>) => {
        state.accessToken = action.payload.tokens.accessToken;
        state.profile = action.payload.userProfile;
        state.errorMessage = null;
      }
    );
    builder.addCase(
      getLoginResponseFromWarehouse.rejected,
      (state: LoginState, action): void => {
        state.errorMessage = action.payload || "Something went wrong";
      }
    );
  }
});

const loginPersistConfig = {
  key: "login",
  storage,
  version: persistVersion,
  migrate: persistMigrate,
  whitelist: ["accessToken", "profile", "isUserLoggedIn", "authMethod"]
};
export const { clearLoginState, loginTest } = loginSlice.actions;
export const login = persistReducer(loginPersistConfig, loginSlice.reducer);
