import { Box } from "@mui/material";

import { ReactNode, useContext } from "react";

import { navBarHeight } from "~/components/navbar/Navbar";
import { ViewContext } from "~/hooks/useView";

import { SideNav } from "./SideNav";

export type Props = {
  children?: ReactNode;
};

export function Shell({ children }: Props) {
  // TODO: refactor fixed layout up to the AutostoreLayout component, and remove the context & hooks
  const { fixedHeight, hideXScrollbar, hideYScrollbar } =
    useContext(ViewContext);

  return (
    <Box
      sx={{
        minHeight: fixedHeight ? undefined : "100vh",
        height: fixedHeight ? `calc(100vh - ${navBarHeight})` : undefined,
        overflowX: hideXScrollbar ? "hidden" : undefined,
        overflowY: hideYScrollbar ? "hidden" : undefined
      }}
    >
      <SideNav />
      {children}
    </Box>
  );
}

export default Shell;
