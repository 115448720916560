import Button from "@locaisolutions/button";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { useToast } from "~/hooks/useToast";

import { mixpanelTrack, useMixpanelPageName } from "~/lib/mixpanel-tracking";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { getCycleCountFrequencyByVid } from "~/redux/actions/cycleCounts";
import { usePutCycleCountFrequencyMutation } from "~/redux/warehouse/cycleCounts.hooks";

type CycleCountFrequencyModalProps = {
  open: boolean;
  initialFrequency: number | null;
  closeModalCb: () => void;
};

type CycleCountFrequencyForm = {
  frequency: number;
};

export function CycleCountFrequencyModal(props: CycleCountFrequencyModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const trackedPageName = useMixpanelPageName();
  const { successToast, errorToast } = useToast();
  const { initialFrequency, open, closeModalCb } = props;
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<CycleCountFrequencyForm>({ mode: "onChange" });
  const selectedVariant = useAppSelector(
    (state) => state.inventory.selectedVariant
  );
  const workstationId = useAppSelector(
    (state) => state.workstations.siteWorkstation?.id
  );
  const [updateCycleCountFrequency] = usePutCycleCountFrequencyMutation();

  const handleAddFrequency = async (enteredFrequency: number) => {
    if (selectedVariant?.variantId) {
      try {
        await updateCycleCountFrequency({
          variantId: selectedVariant.variantId,
          frequency: enteredFrequency,
          workstationId
        }).unwrap();
        successToast(t("Inventory Frequency Added"));
        await dispatch(getCycleCountFrequencyByVid(selectedVariant.variantId));
      } catch (error) {
        errorToast(getMessageFromRtkError(error));
      }
      mixpanelTrack({
        trackedPageName,
        type: "Button Click",
        label: "Confirm Inventory Frequency",
        eventProperties: {
          variantId: selectedVariant.variantId,
          frequency: enteredFrequency
        }
      });

      closeModalCb();
    }
  };

  const frequencyTextField = (
    <TextField
      {...register("frequency", {
        min: { value: 1, message: t("value must be greater than 0") },
        shouldUnregister: true,
        valueAsNumber: true
      })}
      id="frequency"
      label="Frequency"
      type="number"
      variant="outlined"
      defaultValue={initialFrequency || undefined}
      error={!!errors.frequency?.message}
      helperText={errors?.frequency?.message || " "}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{t("days")}</InputAdornment>
        )
      }}
    />
  );

  const AddFrequencyButton = (
    <Button
      id="add-button"
      type="submit"
      kind="accent"
      size="large"
      startIcon={<AddIcon style={{ fontSize: 22 }} />}
      disabled={!!errors.frequency}
      sx={{
        fontWeight: "normal"
      }}
    >
      {t("add")}
    </Button>
  );

  return (
    <Dialog
      className="CycleCountFrequency-dialog"
      onClose={() => closeModalCb()}
      open={open}
    >
      <form
        onSubmit={handleSubmit(async (data) => {
          await handleAddFrequency(data.frequency);
        })}
      >
        <DialogTitle>{t("add cycle count frequency")}</DialogTitle>
        <DialogContent>
          <Box my={3}>{frequencyTextField}</Box>
        </DialogContent>
        <DialogActions>{AddFrequencyButton}</DialogActions>
      </form>
    </Dialog>
  );
}
