import {
  Box,
  Grid,
  Typography,
  Pagination,
  useMediaQuery
} from "@mui/material";
import { mobileWidth } from "frontend-components";
import { isGuid } from "is-guid";
import { useState, useEffect, useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { ErrorPanel } from "~/components/ErrorPanel";
import ScanningIndicator, {
  useScanIndicator
} from "~/components/ScanningIndicator";
import { TinyIconGenerator } from "~/components/TinyIconGenerator";
import AutostoreTable from "~/components/autostore/table/AutostoreTable";

import { useNavbar } from "~/hooks/useNavbar";
import { useToast } from "~/hooks/useToast";
import { useView } from "~/hooks/useView";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { ToteOrLoosePickType } from "~/lib/toteOrLoosePick";

import {
  useLoadLoosePicksMutation,
  useVerifyLoosePicksMutation
} from "~/redux/warehouse/loosePicks.hooks";
import { useGetConsolidationSummaryQuery } from "~/redux/warehouse/orders.hooks";
import {
  useLoadTotesMutation,
  useVerifyTotesMutation
} from "~/redux/warehouse/totes.hooks";
import {
  ConsolidationLoosePickDto,
  ConsolidationToteDto,
  ConsolidationSummaryDto
} from "~/types/api";

import { ShipSearch } from "./ShipSearch";
import { ShipToolbar } from "./ShipToolbar";
import { areAllTotesInStatus } from "./areAllTotesInStatus";
import { setSearch, selectConsolidationSummary } from "./ship.slice";

type Tote = { orderId: Guid } & ConsolidationToteDto;
type LoosePick = { orderId: Guid } & ConsolidationLoosePickDto;

function getType(
  toteOrLoosePick:
    | Tote
    | LoosePick
    | ConsolidationLoosePickDto
    | ConsolidationToteDto
): ToteOrLoosePickType {
  return (toteOrLoosePick as Tote | ConsolidationToteDto).toteId
    ? "Tote"
    : "LoosePick";
}

function getId(
  type: string,
  toteOrPick:
    | Tote
    | LoosePick
    | ConsolidationLoosePickDto
    | ConsolidationToteDto
) {
  return type === "Tote"
    ? (toteOrPick as Tote).toteId
    : (toteOrPick as LoosePick).loosePickId;
}

type Props = { viewTitle?: string };

export function Ship(props: Props) {
  const { viewTitle } = props;

  const isMobile = useMediaQuery(mobileWidth);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { successToast, errorToast } = useToast();

  useView({ permanentSidenav: true });
  const { setMenuItems, setToolbar } = useNavbar({
    centerComponent: useMemo(() => <ShipSearch />, []),
    viewTitle
  });

  const orderTypeFilter = useAppSelector((state) => state.ship.orderTypeFilter);
  const putWallIdentifierFilter = useAppSelector(
    (state) => state.ship.putWallIdentifierFilter
  );
  const search = useAppSelector((state) => state.ship.search);
  const selectedOrderId = useAppSelector(
    (state) => state.ship.selectedConsolidationSummary?.orderId
  );

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(isMobile ? 10 : 25);

  const [loadLoosePicks] = useLoadLoosePicksMutation();
  const [loadTotes] = useLoadTotesMutation();
  const [verifyLoosePicks] = useVerifyLoosePicksMutation();
  const [verifyTotes] = useVerifyTotesMutation();

  const { data, isFetching, error } = useGetConsolidationSummaryQuery(
    {
      limit,
      offset: !search ? (page - 1) * limit : 0,
      orderType: orderTypeFilter,
      putWallIdentifier: putWallIdentifierFilter,
      search,
      status: ["batched", "picked", "staged", "verified"]
    },
    { refetchOnMountOrArgChange: true }
  );

  const totalPageCount = data?.totalSummaryCount
    ? Math.ceil(data.totalSummaryCount / limit)
    : 0;

  const consolidationTotes: Tote[] =
    data?.consolidationSummaries.flatMap((summary) =>
      summary.totes.map((tote) => ({ ...tote, orderId: summary.orderId }))
    ) ?? [];
  const consolidationPicks: LoosePick[] =
    data?.consolidationSummaries.flatMap((summary) =>
      summary.loosePicks.map((lp) => ({ ...lp, orderId: summary.orderId }))
    ) ?? [];

  useEffect(() => {
    setToolbar(selectedOrderId ? <ShipToolbar /> : null);
  }, [selectedOrderId, setToolbar]);

  const handleLoadAllVerifiedOrders = useCallback(
    async (option: string) => {
      try {
        let toteIdsToLoad: string[] = [];
        let loosePickIdsToLoad: string[] = [];

        if (option === "verified orders") {
          // only load consolidations when all tote statuses are verified and all loose picks are verified
          const verifiedSummariesToLoad =
            data?.consolidationSummaries.filter((consolidationSummary) => {
              const allToteStatusesVerified = consolidationSummary.totes.reduce(
                (acc, el) => acc && el.status === "Verified",
                true
              );

              const allLoosePicksVerified =
                consolidationSummary.loosePicks.reduce(
                  (acc, el) => acc && el.status === "Verified",
                  true
                );

              return allToteStatusesVerified && allLoosePicksVerified;
            }) ?? [];

          toteIdsToLoad = verifiedSummariesToLoad
            .map((summary) => summary.totes.map((tote) => tote.toteId))
            .flat();

          loosePickIdsToLoad = verifiedSummariesToLoad
            .map((summary) =>
              summary.loosePicks.map((loosePick) => loosePick.loosePickId)
            )
            .flat();
        } else if (option === "totes") {
          toteIdsToLoad =
            data?.consolidationSummaries
              .map((summary) => summary.totes)
              .flat()
              .map((tote) => tote.toteId) || [];
        } else if (option === "loose picks") {
          loosePickIdsToLoad =
            data?.consolidationSummaries
              .map((summary) => summary.loosePicks)
              .flat()
              .map((loosePick) => loosePick.loosePickId) || [];
        }

        await Promise.all([
          loadTotes({
            toteIds: toteIdsToLoad
          }).unwrap(),
          loadLoosePicks({
            loosePickIds: loosePickIdsToLoad
          }).unwrap()
        ]);

        let description = "";
        if (toteIdsToLoad.length) {
          description += `${toteIdsToLoad.length} ${toteIdsToLoad.length === 1 ? "tote" : "totes"}`;
        }
        if (toteIdsToLoad.length && loosePickIdsToLoad.length) {
          description += " and ";
        }
        if (loosePickIdsToLoad.length) {
          description += `${loosePickIdsToLoad.length} loose ${loosePickIdsToLoad.length === 1 ? "pick" : "picks"}`;
        }

        successToast("Loaded", {
          description
        });
        dispatch(selectConsolidationSummary(null));
      } catch (err: unknown) {
        errorToast(getMessageFromRtkError(err));
      }
    },
    [
      data?.consolidationSummaries,
      dispatch,
      errorToast,
      loadLoosePicks,
      loadTotes,
      successToast
    ]
  );

  useEffect(() => {
    setMenuItems([
      {
        textContent: "Load All Visible, Fully Verified Orders",
        actionCb: () => handleLoadAllVerifiedOrders("verified orders")
      },
      {
        textContent: "Load All Visible Verified Totes",
        actionCb: () => handleLoadAllVerifiedOrders("totes")
      },
      {
        textContent: "Load All Visible Verified Loose Picks",
        actionCb: () => handleLoadAllVerifiedOrders("loose picks")
      }
    ]);
  }, [handleLoadAllVerifiedOrders, setMenuItems]);

  const [scanState, setScanState] = useScanIndicator();
  const [scannedBarcode, setScannedBarcode] = useState<string | null>(null);

  useKeyDownHandler();
  useBarcodeScanner<Tote | LoosePick | null>({
    findScanMatch: (buffer: string) => {
      let matchedTote;
      let matchedLoosePick;
      setScannedBarcode(buffer);
      setScanState("success");
      if (isGuid(buffer)) {
        // toteId scan
        matchedTote = consolidationTotes?.find(
          (tote) => tote.toteId === buffer
        );
        matchedLoosePick = consolidationPicks?.find(
          (pick) => pick.loosePickId === buffer
        );
      } else {
        dispatch(setSearch(buffer));

        return null;
      }

      if (matchedTote) {
        return matchedTote;
      }

      if (matchedLoosePick) return matchedLoosePick;

      return null;
    },
    processScanMatch: async (toteOrPick) => {
      if (!toteOrPick) {
        return null;
      }
      const type = getType(toteOrPick);
      const id = getId(type, toteOrPick);
      const { orderId } = toteOrPick;
      const orderSummary = data?.consolidationSummaries.find(
        (summary) => summary.orderId === orderId
      );
      if (
        !orderSummary ||
        !areAllTotesInStatus(
          [...orderSummary.totes, ...orderSummary.loosePicks],
          ["Staged"]
        )
      ) {
        errorToast(
          "All totes for this order must be Staged before it can be Verified"
        );
        return null;
      }
      if (type === "LoosePick") {
        await verifyLoosePicks({
          loosePickIds: [id]
        }).unwrap();
      } else {
        await verifyTotes({ toteIds: [id] }).unwrap();
      }
      return toteOrPick;
    },
    deps: [data?.consolidationSummaries]
  });

  return (
    <Box padding="0 2px 20px" overflow="hidden" margin={2}>
      {!!error && <ErrorPanel message={getMessageFromRtkError(error)} />}
      {!error && (
        <AutostoreTable<ConsolidationSummaryDto>
          headerColNames={[t("orders"), t("totes"), t("put wall")]}
          rowId={(row: ConsolidationSummaryDto) => row.orderId}
          renderColumns={[
            (row: ConsolidationSummaryDto) => (
              <Grid container direction="column">
                <Grid item>{row.customerName}</Grid>
                <Grid item>
                  <Typography variant="subtitle2" color="textSecondary">
                    {row.externalOrderId}
                  </Typography>
                </Grid>
              </Grid>
            ),
            (row: ConsolidationSummaryDto) => {
              const totesAndLoosePicks = [
                ...(row.totes || []),
                ...(row.loosePicks || [])
              ];
              return (
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {totesAndLoosePicks
                    .sort((a, b) =>
                      a.temperatureZone.localeCompare(b.temperatureZone)
                    )
                    .map((toteOrLoosePick) => {
                      const type = getType(toteOrLoosePick);

                      return (
                        <Grid
                          item
                          key={getId(type, toteOrLoosePick)}
                          style={{
                            padding: type === "LoosePick" ? 1 : 4
                          }}
                        >
                          {TinyIconGenerator({
                            tempZone:
                              toteOrLoosePick.temperatureZone.toLowerCase() as
                                | "ambient"
                                | "chilled"
                                | "frozen",
                            type,
                            key: getId(type, toteOrLoosePick),
                            status: toteOrLoosePick.status
                          })}
                        </Grid>
                      );
                    })}
                </Grid>
              );
            },
            (row: ConsolidationSummaryDto) => {
              const totesAndLoosePicks = [
                ...(row.totes || []),
                ...(row.loosePicks || [])
              ];
              const firstToteOrPick =
                !!totesAndLoosePicks.length && totesAndLoosePicks[0];

              const styles = {
                minHeight: 34,
                display: "flex",
                justifyContent: "center"
              };
              return (
                <Box
                  sx={
                    areAllTotesInStatus(totesAndLoosePicks, ["Staged"])
                      ? {
                          ...styles,
                          backgroundColor: "info.main",
                          width: 80
                        }
                      : styles
                  }
                >
                  {firstToteOrPick && (
                    <Typography
                      sx={
                        areAllTotesInStatus(totesAndLoosePicks, ["Staged"])
                          ? { fontSize: 22, color: "#fff" }
                          : { fontSize: 22 }
                      }
                    >{`${firstToteOrPick.putWallIdentifier || ""}${
                      firstToteOrPick.putWallLaneIdentifier || ""
                    }`}</Typography>
                  )}
                </Box>
              );
            }
          ]}
          widthOfCols={["40%", "40%", "20%"]}
          headerVariant="overline"
          bodyVariant="body2"
          rowData={data?.consolidationSummaries ?? []}
          selectedRows={selectedOrderId ? [selectedOrderId] : []}
          selectRowCallback={(row: ConsolidationSummaryDto) => {
            const isSelectedOrder = row.orderId === selectedOrderId;
            dispatch(selectConsolidationSummary(!isSelectedOrder ? row : null));
          }}
          loading={isFetching}
          limit={limit}
          setLimitCb={(newLimit: number) => setLimit(newLimit)}
        />
      )}

      {totalPageCount > 1 && (
        <Grid container justifyContent="center" style={{ marginTop: 16 }}>
          <Pagination
            count={totalPageCount}
            page={page}
            onChange={(_e, p) => {
              setPage(p);
              if (window.scrollTo) window.scrollTo(0, 0);
            }}
            shape="rounded"
          />
        </Grid>
      )}
      <ScanningIndicator
        scanState={scanState}
        scannedBarcode={scannedBarcode}
        placeholderText="Scan Tote"
      />
    </Box>
  );
}
