import { Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { tabletWidth } from "frontend-components";
import { useState } from "react";

import { PickDto, SignalRPickDto } from "~/types/api";

export default function Split(props: {
  open: boolean;
  pickDto: PickDto | SignalRPickDto;
  closePanel: () => void;
  splitPick: (pickId: Guid, splitQty: number) => void;
}) {
  const isTablet = useMediaQuery(tabletWidth);
  const { open, pickDto, closePanel, splitPick } = props;
  const { quantity, pickId } = pickDto;
  const [splitQty, setSplitQty] = useState(1);
  const handleInputChange = (event: SelectChangeEvent<number>): void => {
    setSplitQty(event.target.value as number);
  };

  const handleConfirm = (): void => {
    splitPick(pickId, splitQty);
    closePanel();
  };

  const handleCancel = (): void => {
    setSplitQty(1);
    closePanel();
  };

  const qtyOptions = (): number[] => {
    const optionsArray = [];
    for (let i = 1; i < quantity.value; i++) {
      optionsArray.push(i);
    }
    return optionsArray;
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle id="split-dialog-title">
          <Typography style={{ fontWeight: 600 }}>Split</Typography>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center", paddingTop: 10 }}>
          <FormControl variant="outlined" sx={{ minWidth: 120 }}>
            <InputLabel
              id="split-qty"
              style={{ fontSize: isTablet ? 20 : 30, top: isTablet ? 0 : -5 }}
            >
              qty
            </InputLabel>
            <Select
              labelId="split-qty"
              id="demo-simple-select-outlined"
              value={splitQty}
              onChange={handleInputChange}
              label="qty"
              style={{ fontSize: 40 }}
            >
              {qtyOptions().map((qty) => (
                <MenuItem key={qty} value={qty} style={{ fontSize: 26 }}>
                  {qty}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            color="primary"
            style={{ fontSize: 20 }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            style={{ fontSize: 20 }}
          >
            Split
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
