import {
  Card,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { ProgressButton, ProgressButtonProps } from "frontend-components";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import AutostoreBin from "~/components/autostore/autostoreBin";
import { Root } from "~/components/autostore/modals/Root";
import UniversalProductCard, {
  PickInfoIsLoading
} from "~/components/productCard/UniversalProductCard";
import { getHoldTypeOptions } from "~/lib/helpers";
import { mixpanelSetHoldConfirm, mixpanelTrack } from "~/lib/mixpanel-tracking";

import { flagBin, updatePickingState } from "~/redux/actions";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import {
  selectSelectedPortId,
  selectWorkstationAutostoreGridId,
  selectWorkstationId
} from "~/redux/selectors/workstationsSelectors";
import { useGetBinQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetFocusedPickQuery } from "~/redux/warehouse/autostorePicking.hooks";

import { useHoldBinMutation } from "~/redux/warehouse/bin.hooks";

import {
  useGetInventoryWithVariantInfoByAutostoreBinNumberQuery,
  useLazyGetInventoryByIdQuery
} from "~/redux/warehouse/inventory.hooks";

import {
  closeHoldBinModal,
  openHoldBinModal,
  selectCompartment,
  selectProblemType
} from "./problemSolveModal.slice";

const ProblemSolveModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedCompartment =
    useAppSelector((state) => state.problemSolveModal.selectedCompartment) ?? 1;
  const selectedProblemCode = useAppSelector(
    (state) => state.problemSolveModal.problemType
  );
  const isFullBinProblem =
    selectedProblemCode?.toLowerCase().includes("bin") ?? false;
  const workstationId = useAppSelector(selectWorkstationId);
  const gridId = useAppSelector(selectWorkstationAutostoreGridId);
  const portId = useAppSelector(selectSelectedPortId);
  const {
    ap_excludeRecalledHold,
    ap_includeDamagedBinHold,
    ap_includeDirtyBinHold,
    ap_includeMisconfiguredBinHold
  } = useAppSelector(selectClientConfig);

  const { focusedPickBinNumber, isPickLoading } = useGetFocusedPickQuery(
    workstationId
      ? {
          workstationId: workstationId
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isLoading }) => ({
        focusedPickBinNumber: data?.autostoreBinId,
        isPickLoading: isLoading
      })
    }
  );

  const { data: inventoryInBin, isLoading: isInventoryInBinLoading } =
    useGetInventoryWithVariantInfoByAutostoreBinNumberQuery(
      focusedPickBinNumber && gridId
        ? {
            autostoreGridId: gridId,
            binNumber: focusedPickBinNumber
          }
        : skipToken,
      { refetchOnMountOrArgChange: true }
    );

  const isInventoryOnHold = inventoryInBin?.some(
    (inv) =>
      inv.inventory.holds.length &&
      inv.inventory.bin.autostoreCompartmentNumber === selectedCompartment
  );
  const isBinFlagged = inventoryInBin?.some(
    (inv) => inv.inventory.bin.autostoreBin?.isFlagged
  );

  const { data: bin, isLoading: isBinLoading } = useGetBinQuery(
    focusedPickBinNumber && gridId
      ? { autostoreGridId: gridId, binNumber: focusedPickBinNumber }
      : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const binConfiguration = bin?.binConfiguration;

  const [holdBin] = useHoldBinMutation();
  const [getInventoryByIdQuery] = useLazyGetInventoryByIdQuery();

  const holdTypeOptions = getHoldTypeOptions(
    ap_excludeRecalledHold,
    ap_includeDamagedBinHold,
    ap_includeDirtyBinHold,
    ap_includeMisconfiguredBinHold,
    true
  );

  const isLoading = isPickLoading || isBinLoading || isInventoryInBinLoading;

  const variantToDisplay = inventoryInBin
    ? inventoryInBin.find(
        (specificInventory) =>
          specificInventory.inventory.bin.autostoreCompartmentNumber ===
          selectedCompartment
      )
    : null;

  const confirmCb = async () => {
    const selectedBinId =
      (bin?.compartments ?? []).find(
        (compartment) =>
          compartment.autostoreCompartmentNumber === selectedCompartment
      )?.binId ?? null;

    if (
      selectedProblemCode &&
      focusedPickBinNumber &&
      selectedBinId &&
      gridId &&
      portId &&
      workstationId
    ) {
      mixpanelSetHoldConfirm(
        "Hold Bin Modal",
        "Button Click",
        "Set Hold",
        selectedProblemCode
      );

      // We have two types of reason codes:
      // 1. Related to a specific inventory in a specific compartment
      // 2. Related to the entire bin
      // If the selected reason code is associated with the entire bin,
      // The flag Bin api should be called
      if (selectedProblemCode.length && selectedProblemCode.includes("bin")) {
        const reasonCodeExtracted = selectedProblemCode.split(" ")[0];
        // TODO move this to RTK
        await dispatch(
          flagBin(gridId, portId, focusedPickBinNumber, reasonCodeExtracted)
        );
      } else {
        await holdBin({
          warehouseBinId: selectedBinId,
          reasonCode: selectedProblemCode,
          gridId: gridId,
          portId: portId,
          workstationId: workstationId
        });
      }

      // When the hold is placed on inventory/bin,
      // useGetInventoryByIdQuery won't be refetched because the inventoryId argument is the same
      // We need to refetch query in order to get updated UI
      if (variantToDisplay)
        await getInventoryByIdQuery({
          inventoryId: variantToDisplay.inventory.inventoryId
        });

      // eventually want to remove this!
      await dispatch(updatePickingState({}));
      dispatch(closeHoldBinModal());
    }
  };

  return (
    <Root
      open={!isLoading}
      onClose={() => dispatch(closeHoldBinModal())}
      maxWidth={"md"}
      fullWidth
    >
      <Card>
        <DialogTitle sx={{ mb: 3 }}>
          {t("bin and inventory problems")}
        </DialogTitle>
        <DialogContent sx={{ overflowY: "hidden" }}>
          <Grid container direction="column">
            <Grid container item>
              <Grid container style={{ width: "100%" }}>
                {variantToDisplay ? (
                  <Grid item xs={6}>
                    <UniversalProductCard
                      productName={variantToDisplay.variant.productName}
                      imageFileName={variantToDisplay.variant.imageFilename}
                      sku={variantToDisplay.variant.sku}
                      upc={variantToDisplay.variant.upc}
                      weight=""
                      style={{ height: "100%" }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <PickInfoIsLoading innerHeight="220px" />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="column"
                    sx={{ position: "relative" }}
                  >
                    {!isFullBinProblem &&
                      (binConfiguration?.numberOfCompartments ?? 1) > 1 && (
                        <Grid item sx={{ marginBottom: "1em" }}>
                          <Typography variant="h6">
                            Select Bin Compartment to Place on Hold:
                          </Typography>
                        </Grid>
                      )}

                    <Grid
                      item
                      sx={{
                        transform: "translateX(-5%)",
                        marginBottom: "1em",
                        width: "100%"
                      }}
                    >
                      <Stack alignItems="center">
                        <AutostoreBin
                          state={"Bin Opened"}
                          numberOfColumns={
                            binConfiguration?.verticalCompartmentCount || 1
                          }
                          numberOfRows={
                            binConfiguration?.horizontalCompartmentCount || 1
                          }
                          pickCompartment={null}
                          pickQuantity=""
                          binId={focusedPickBinNumber}
                          hideBinId
                          selectedCompartment={
                            isFullBinProblem
                              ? undefined
                              : selectedCompartment - 1
                          }
                          onBinClick={(num) => {
                            if (typeof num === "number") {
                              dispatch(selectCompartment(num + 1));
                            }
                          }}
                          isSelectedCompartmentInventoryOnHold={
                            isInventoryOnHold
                          }
                          isBinFlagged={isBinFlagged}
                        />
                        {isBinFlagged && (
                          <Typography variant="h6">
                            {t("bin is flagged", { binNumber: "" })}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      container
                      sx={{
                        position: "relative",
                        alignItems: "end"
                      }}
                    >
                      <Grid item xs={6} sx={{ height: "100%" }}>
                        <FormHelperText sx={{ fontWeight: 600 }}>
                          {t("choose a hold type")}
                        </FormHelperText>
                        <TextField
                          data-testid="hold-bin-dropdown"
                          value={selectedProblemCode ?? ""}
                          select
                          variant="outlined"
                          sx={{
                            "& .MuiInputBase-root": {
                              height: 65
                            },
                            width: "90%",
                            "& .MuiSelect-select .notranslate::after": {
                              content: `"${t("available hold types")}"`,
                              opacity: 0.52
                            }
                          }}
                          inputProps={{
                            style: { fontSize: 30, textAlign: "center" }
                          }}
                        >
                          {holdTypeOptions.map((option) => (
                            <MenuItem
                              onClick={(e) => {
                                const liElement = (e.target as Element).closest(
                                  "li"
                                );
                                const reasonCode =
                                  liElement?.getAttribute("data-reasoncode");

                                if (reasonCode) {
                                  dispatch(selectProblemType(reasonCode));
                                }
                              }}
                              data-reasoncode={option.reasonCode}
                              key={option.text.toLocaleLowerCase()}
                              value={option.text.toLocaleLowerCase()}
                              style={{ padding: "1.5em 0 1.5em 0.5em" }}
                            >
                              <Typography>{t(option.text)}</Typography>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item>
                        <ProgressButton
                          buttonSize="large"
                          emphasis="high"
                          responsive
                          variant="contained"
                          color="primary"
                          onClick={confirmCb}
                          fullWidth
                          disabled={!selectedProblemCode}
                          style={{ width: "9em" }}
                        >
                          {t("confirm")}
                        </ProgressButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Card>
    </Root>
  );
};

const ProblemSolveModalButton = (
  props: Omit<ProgressButtonProps, "onClick">
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.problemSolveModal.isOpen);

  return (
    <>
      <ProgressButton
        aria-label={"problem-solve-button"}
        id="problem-solve-button"
        onClick={() => {
          mixpanelTrack({
            trackedPageName: "Picking",
            type: "Button Click",
            label: "Hold Bin"
          });
          dispatch(openHoldBinModal());
        }}
        {...props}
      >
        {t("hold bin")}
      </ProgressButton>
      {isOpen && <ProblemSolveModal />}
    </>
  );
};

export default ProblemSolveModalButton;
