import { AutostoreBinConfigurationDto, SearchBinRecord } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const binApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    holdBin: build.mutation<
      void,
      {
        portId: number;
        gridId: Guid;
        reasonCode: string;
        workstationId: Guid;
        warehouseBinId: Guid;
      }
    >({
      query: ({ warehouseBinId, ...body }) => ({
        url: `/bins/${warehouseBinId}/hold`,
        method: "POST",
        data: body
      })
    }),
    getBinConfigurations: build.query<AutostoreBinConfigurationDto[], void>({
      query: () => ({ url: "/bins/autostore/configurations" }),
      providesTags: ["bin configuration"]
    }),
    patchBinConfiguration: build.mutation<
      void,
      {
        configurationId: string;
        maxBinFillPercent: number;
      }
    >({
      query: ({ configurationId, ...body }) => ({
        url: `/bins/autostore/configurations/${configurationId}/`,
        method: "PATCH",
        data: body
      }),
      invalidatesTags: ["bin configuration"]
    }),
    getBinsBySearch: build.query<SearchBinRecord[], { queryString: string }>({
      query: ({ queryString }) => ({
        url: `/bins/search/${queryString}`
      })
    }),
    getBinsByAutostoreBinNumber: build.query<
      SearchBinRecord[],
      { autostoreGridId: Guid; binNumber: string }
    >({
      query: ({ autostoreGridId, binNumber }) => ({
        url: `/bins/autostore/grid/${autostoreGridId}/by-autostore-bin-number/${binNumber}`
      })
    })
  }),
  overrideExisting: false
});
