import ASButton from "@locaisolutions/button";
import {
  ArrowDoubleRight20Px,
  Bookmark20Px,
  BookmarkFill20Px,
  CheckmarkCircle20Px,
  CloseCircle20Px,
  Edit20Px
} from "@locaisolutions/icons";
import { Box, Stack } from "@mui/material";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import AutostoreBin from "~/components/autostore/autostoreBin";
import { usePortStatusQuery } from "~/hooks/usePortStatus";

import { InventoryMovementResponse } from "~/types/api";

import { BinNotEmptyButton } from "./BinNotEmptyButton";
import { BinReconfigurationButton } from "./BinReconfigurationButton";
import { setTaskState } from "./binMaintenanceWorkstation.slice";

export const BinMaintenancePort = ({
  portId,
  movementData
}: {
  portId: number;
  movementData: InventoryMovementResponse | undefined;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { movementTaskState } = useAppSelector(
    (state) => state.binMaintenanceWorkstation
  );

  const {
    horizontalCompartmentCount,
    verticalCompartmentCount,
    error,
    activityState,
    binState,
    ...portStatus
  } = usePortStatusQuery(portId);

  const { selectedBin: thisBinNumber } = portStatus;
  const { sourceBin, destinationBin } = movementData || {};
  const movingProduct = movementTaskState === "MovingProduct";
  const confirmingMovement = movementTaskState === "ConfirmingMovement";
  const isSourceBin =
    thisBinNumber && thisBinNumber === sourceBin?.autostoreBinId;
  const isDestinationBin =
    thisBinNumber && thisBinNumber === destinationBin?.autostoreBinId;

  return (
    <Stack width={"100%"}>
      {!error && (
        <Box sx={{ position: "relative", padding: 2 }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              padding: 1,
              zIndex: 2,
              borderRadius: "0.5em",
              border: `0.625em solid`,
              borderColor:
                (isSourceBin && movingProduct) ||
                (isDestinationBin && confirmingMovement)
                  ? "primary.main"
                  : "transparent",
              animation:
                isDestinationBin && confirmingMovement
                  ? "slide-in 750ms"
                  : "none",
              "@keyframes slide-in": {
                from: {
                  transform: "translateX(-100%)"
                },
                to: {
                  transform: "translateX(0%)"
                }
              }
            }}
          />
          <AutostoreBin
            state={activityState}
            binId={thisBinNumber}
            pickQuantity={""}
            pickCompartment={null}
            numberOfRows={horizontalCompartmentCount ?? 1}
            numberOfColumns={verticalCompartmentCount ?? 1}
          />
        </Box>
      )}
      {/* Todo: move these buttons and their modals to seperate components */}
      {isSourceBin && (
        <Stack direction="column" alignItems="center" spacing={4} my={3}>
          <ASButton
            kind="accent"
            size="large"
            startIcon={<ArrowDoubleRight20Px fill="white" />}
            disabled={!movingProduct}
            onClick={() => dispatch(setTaskState("ConfirmingMovement"))}
          >
            {t("move inventory")}
          </ASButton>
          {movingProduct ? (
            <ASButton kind="subtle" size="large" startIcon={<Edit20Px />}>
              Adjust
            </ASButton>
          ) : (
            <ASButton kind="subtle" size="large" startIcon={<Bookmark20Px />}>
              Flag Bin
            </ASButton>
          )}
          <ASButton
            kind="subtle"
            size="large"
            startIcon={<CloseCircle20Px />}
            onClick={() => dispatch(setTaskState("MovingProduct"))}
          >
            Cancel Movement
          </ASButton>
        </Stack>
      )}
      {isDestinationBin && (
        <Stack direction="column" alignItems="center" spacing={4} my={3}>
          <ASButton
            kind="accent"
            size="large"
            startIcon={<CheckmarkCircle20Px fill="white" />}
            disabled={!confirmingMovement}
          >
            Confirm Move
          </ASButton>
          {confirmingMovement ? (
            <ASButton kind="subtle" size="large" startIcon={<Edit20Px />}>
              Adjust
            </ASButton>
          ) : (
            <BinNotEmptyButton
              state={activityState}
              binId={thisBinNumber}
              numberOfRows={horizontalCompartmentCount ?? 1}
              numberOfColumns={verticalCompartmentCount ?? 1}
              portId={portId}
              binState={binState}
            />
          )}
          <ASButton kind="subtle" size="large" startIcon={<Bookmark20Px />}>
            Flag Bin
          </ASButton>
        </Stack>
      )}
      {!!thisBinNumber && !isDestinationBin && !isSourceBin && (
        <Stack direction="column" alignItems="center" spacing={4} my={3}>
          <ASButton
            kind="accent"
            size="large"
            startIcon={<BookmarkFill20Px fill="white" />}
          >
            Unflag Bin
          </ASButton>
          <BinNotEmptyButton
            state={activityState}
            binId={thisBinNumber}
            numberOfRows={horizontalCompartmentCount ?? 1}
            numberOfColumns={verticalCompartmentCount ?? 1}
            portId={portId}
            binState={binState}
          />
          {/* <ASButton kind="subtle" size="large" startIcon={<Bookmark20Px />}>
              Flag Bin
            </ASButton> */}
          {/* TODO: This should actually open the flag modal, which will have the option to open bin reconfig */}
          <BinReconfigurationButton binNumber={thisBinNumber} />
        </Stack>
      )}
    </Stack>
  );
};
