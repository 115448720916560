import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { isGuid } from "is-guid";
import { useState, useEffect, useCallback, memo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate, useParams } from "react-router";

import {
  ToteConfiguration,
  PickScanProductConfiguration,
  PickScanConfirmableToteConfigutation,
  PickScanToteConfiguration
} from "~/api/warehouseTypes/fulfillmentCenter";

import { useNavbar } from "~/hooks/useNavbar";
import { useView } from "~/hooks/useView";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import {
  isWeightedBarcode,
  weightedBarcodeWeight,
  getBarcodeValue,
  matchesUpc,
  createWeightedBarcode,
  isPlu
} from "~/lib/helpers";
import { createNumberSequence } from "~/lib/shared";
import { usePickSubscription } from "~/lib/signalr";

import {
  getBatch,
  getTotesByBatch,
  getLoosePicksByBatch,
  fulfillPick,
  weightAdjustPick,
  updatePick,
  setConfirmablePickIds,
  setActivePickCompletion,
  startWeightAdjustment,
  endWeightAdjustment,
  addToteToBatch,
  getCurrentBatch,
  revertPick,
  outOfStockPick
} from "~/redux/actions/batch";
import { setUserMessage } from "~/redux/actions/site";
import { StoreState } from "~/redux/reducers";
import { SignalRPickEventDto } from "~/types/api";

import {
  isPickingComplete,
  PickModel,
  getAllPickModels,
  getMatchingPickModel
} from "./BatchViewModel";
import PackModal from "./PackModal";
import Pick, { PickLoading } from "./Pick";
import WeightAdjust from "./WeightAdjust";

interface ScanObj {
  scanType: "product barcode" | "toteId";
  scannedProductBarcode?: string;
  scannedToteId?: string;
  isNewProductScan?: boolean;
}

const snPickScanProductConfig: PickScanProductConfiguration = "OncePerQuantity";
const snPickScanConfirmableToteConfig: PickScanConfirmableToteConfigutation =
  "Single";
const snPickScanToteConfig: PickScanToteConfiguration = "OncePerPick";

// OncePerPick - scan item, scan tote (regardless of pick qty). OncePerQuantity - scan item, scan item, scan item, etc. scan tote (scan item x Qty, then scan tote)
const pickScanProductConfiguration = snPickScanProductConfig;
// Single - when a product is scanned, the first matching product card turns yellow. Multiple - all of the matching product cards turn yellow
const pickScanConfirmableToteConfiguration = snPickScanConfirmableToteConfig;
// OncePerPick - tote is scanned once per pick (either product, product..., tote or product, tote). OncePerQuantity - tote is scanned multiple times per pick (product, tote, product, tote, etc)
const pickScanToteConfiguration = snPickScanToteConfig;

// if the quantity is in lbs, we treat the full qty as one unit (ie 2lbs sliced cheese will be one thing to scan)
const scanCount = (pick: PickModel) => {
  const unitIsLb = pick.quantity.units.toLowerCase() === "lb";
  return unitIsLb ? pick.quantity.value : 1;
};

const mapStateToProps = (state: StoreState) => ({
  batchViewModel: state.batch.batchViewModel,
  activePickCompletion: state.batch.activePickCompletion,
  confirmablePickIds: state.batch.confirmablePickIds,
  loadingBatchViewModel: state.batch.loadingBatchViewModel,
  toteConfiguration:
    (state.store.usersFulfillmentCenter
      ?.toteConfiguration as ToteConfiguration) || null,
  weightAdjustParameters: state.batch.weightAdjustParameters,
  usersFC: state.store.usersFulfillmentCenter,
  fulfillingPickId: state.batch.fulfillingPickId
});

const connector = connect(mapStateToProps, {
  getBatch,
  getTotesByBatch,
  getLoosePicksByBatch,
  fulfillPick,
  weightAdjustPick,
  updatePick,
  setConfirmablePickIds,
  setActivePickCompletion,
  startWeightAdjustment,
  endWeightAdjustment,
  addToteToBatch,
  getCurrentBatch,
  revertPick,
  outOfStockPick
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type BatchInheritedProps = { viewTitle?: string };
type BatchProps = PropsFromRedux & BatchInheritedProps;

export const Batch = memo((props: BatchProps) => {
  const {
    batchViewModel,
    loadingBatchViewModel,
    confirmablePickIds,
    weightAdjustParameters,
    usersFC,
    activePickCompletion,
    fulfillingPickId,
    viewTitle
  } = props;

  const navigate = useNavigate();
  useView({ permanentSidenav: true });
  const { setMenuItems } = useNavbar({ viewTitle });

  const isBulk = batchViewModel?.batchType === "Bulk";

  const { batchId = "" } = useParams<{ batchId: string }>();

  const [scanState, setScanState] = useState<{
    recentScannedProductBarcode: string | null;
    scannedBarcodes: string[];
    productScanCount: number;
    toteScanCount: number;
  }>({
    recentScannedProductBarcode: null,
    scannedBarcodes: [], // The actual barcodes scanned per pick
    productScanCount: 0,
    toteScanCount: 0
  });

  useEffect(() => {
    if (batchViewModel?.batchName && isBulk) {
      setMenuItems([
        {
          textContent: "Complete Partial Bulk Pick",
          actionCb: () => navigate(`/batches/${batchViewModel.batchName}/stage`)
        }
      ]);
    } else {
      setMenuItems([]);
    }
  }, [batchViewModel?.batchName, isBulk, navigate, setMenuItems]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props.getBatch(batchId, usersFC || undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchId]);

  useEffect(() => {
    if (batchViewModel) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getLoosePicksByBatch(batchViewModel.batchId);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getTotesByBatch(batchViewModel.batchId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchViewModel?.batchId]);

  const pickSubscription = useCallback(
    (message: SignalRPickEventDto) => {
      switch (message.eventType) {
        case "OutOfStock":
        case "Completed":
        case "Reverted":
          if (batchViewModel && message.batchId === batchViewModel.batchId) {
            props.updatePick(batchViewModel, {
              ...message.pick,
              inventoryId: message.pick.inventory?.inventoryId || ""
            });
          }
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [batchViewModel, props.updatePick]
  );
  usePickSubscription(pickSubscription);

  const handleAddToteToBatch = useCallback(
    (currentToteId: Guid) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.addToteToBatch(currentToteId).then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        props.getCurrentBatch(batchId);
        if (batchViewModel) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          props.getTotesByBatch(batchViewModel.batchId);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [batchViewModel]
  );

  const handleRevertPick = useCallback(
    (pickModel: PickModel) => {
      if (batchViewModel) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        props.revertPick(batchViewModel, pickModel);
      }
      setScanState({
        productScanCount: 0,
        toteScanCount: 0,
        scannedBarcodes: [],
        recentScannedProductBarcode: null
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [batchViewModel]
  );

  const fulfillScannedPick = (
    pickToFulfill: PickModel,
    productBarcode: string
  ) => {
    setScanState((prevState) => ({
      ...prevState,
      productScanCount: 0,
      toteScanCount: 0,
      recentScannedProductBarcode: null
    }));

    props.setConfirmablePickIds([]);
    props.setActivePickCompletion(null);

    // Check to make sure the number of scanned barcodes is equal to the qty for the pick
    const scannedBarcodesMatchesPickQty =
      pickToFulfill.quantity.value === scanState.scannedBarcodes.length ||
      (pickToFulfill.quantity.units.toLowerCase() === "lb" &&
        scanState.scannedBarcodes.length === 1);

    if (!scannedBarcodesMatchesPickQty && !isBulk) {
      setScanState((prevState) => ({ ...prevState, scannedBarcodes: [] }));
      setUserMessage({
        title: "Something went wrong. Re-scan product(s).",
        severity: "error"
      });
      return Promise.resolve();
    }

    // handle weighted barcode match
    let weight: number | null = null;
    // if 13 digit barcode - look for leading 20 or 02
    // if 12 digit barcode - look for leading
    if (productBarcode && pickToFulfill && isWeightedBarcode(productBarcode)) {
      weight = weightedBarcodeWeight(productBarcode);
    }
    if (
      pickToFulfill &&
      weight &&
      // for pickScanConfirmableToteConfig === single, we're weight adjusting at the point of the product scan, so don't do it here
      pickScanConfirmableToteConfiguration ===
        ("multiple" as PickScanConfirmableToteConfigutation)
    ) {
      const { pickId } = pickToFulfill;
      if (pickToFulfill.quantity.value === 1 && batchViewModel) {
        // if the scanned item is a weighted item with a single quantity, weightAdjustPick
        // then fulfill the pick by passing weight adjusted batch model and weight adjusted pick model
        return props
          .weightAdjustPick(batchViewModel, pickId, weight, 1)
          .then((weightAdjustedBatchModel) => {
            if (weightAdjustedBatchModel) {
              const weightAdjustedPickModel = getMatchingPickModel(
                weightAdjustedBatchModel,
                pickToFulfill
              );
              return props
                .fulfillPick(
                  weightAdjustedBatchModel,
                  weightAdjustedPickModel,
                  true,
                  scanState.scannedBarcodes
                )
                .then(() =>
                  setScanState((prevState) => ({
                    ...prevState,
                    scannedBarcodes: []
                  }))
                );
            }
            return null;
          });
      }

      return props.startWeightAdjustment({
        pick: pickToFulfill.pickDto,
        weight,
        quantity: null
      });
    }

    // else fulfill the pick
    return (
      !!batchViewModel &&
      props
        .fulfillPick(
          batchViewModel,
          pickToFulfill,
          true,
          // for bulk, the product scan happens at the same time that the fulfillScannedPick is called,
          // so `scannedBarcodes` state hasn't actually been updated and will be an empty array here
          // using [ productBarcode ] is a safe bet since bulk will always be qty 1
          isBulk ? [productBarcode] : scanState.scannedBarcodes
        )
        .then(() =>
          setScanState((prevState) => ({ ...prevState, scannedBarcodes: [] }))
        )
    );
  };

  useKeyDownHandler();
  useBarcodeScanner<ScanObj | false>({
    findScanMatch: (initialBuffer: string) => {
      if (!batchViewModel) return false;
      const buffer: string = getBarcodeValue(initialBuffer);

      /*
          1. SCANNED TOTE ID (find match)
      */

      if (scanState.recentScannedProductBarcode && isGuid(buffer)) {
        // does scanned tote id match a confirmable tote?
        const pickToteMatch = getAllPickModels(batchViewModel)
          .filter((pickModel) => confirmablePickIds.includes(pickModel.pickId))
          .find((pickModel) => buffer === pickModel.assignedToteId);

        // if pick scanning multiple totes
        // and if scanned tote id matches a confirmable tote
        // and tote scan count is less than product scan count
        if (
          pickScanToteConfiguration ===
            ("OncePerQuantity" as PickScanToteConfiguration) &&
          pickToteMatch &&
          scanState.toteScanCount < scanState.productScanCount
        ) {
          return {
            scanType: "toteId",
            scannedToteId: buffer
          };
        }

        // if pick scanning a single tote
        // and product scan count equals pick quantity
        if (
          pickScanToteConfiguration ===
            ("OncePerPick" as PickScanToteConfiguration) &&
          pickToteMatch &&
          pickToteMatch.quantity.value === scanState.productScanCount
        ) {
          return {
            scanType: "toteId",
            scannedToteId: buffer
          };
        }

        return false;
      }

      /*
          2. SCANNED PRODUCT BARCODE (find match)
      */

      // a) no confirmable picks
      if (confirmablePickIds.length === 0) {
        //
        // does scanned product match at least one pick?
        const newScanProductMatch =
          batchViewModel &&
          getAllPickModels(batchViewModel).find((pickModel) => {
            const codesToMatch = pickModel.allUpcs;
            return (
              pickModel.status === "Unfulfilled" &&
              matchesUpc(codesToMatch, buffer)
            );
          });

        // if there is not a match, return false
        if (!newScanProductMatch) return false;

        setScanState((prevState) => ({
          ...prevState,
          scannedBarcodes: [buffer]
        }));
        return {
          scanType: "product barcode",
          scannedProductBarcode: buffer,
          isNewProductScan: true
        };
      }

      // b) existing confirmable picks
      if (confirmablePickIds.length > 0) {
        // does scanned product match a confirmable pick?
        const confirmablePickMatch = getAllPickModels(batchViewModel).find(
          (pickModel) => {
            const codesToMatch = pickModel.allUpcs;
            const barcodeMatch = matchesUpc(codesToMatch, buffer);

            return (
              barcodeMatch &&
              pickModel.status === "Unfulfilled" &&
              confirmablePickIds.includes(pickModel.pickId)
            );
          }
        );

        // product is not confirmable
        if (!confirmablePickMatch) {
          // does scanned product match at least one pick?
          const newScanProductMatch =
            batchViewModel &&
            getAllPickModels(batchViewModel).find((pickModel) => {
              const codesToMatch = pickModel.allUpcs;
              return (
                pickModel.status === "Unfulfilled" &&
                matchesUpc(codesToMatch, buffer)
              );
            });

          // if there is not a match, return false
          if (!newScanProductMatch) {
            setScanState((prevState) => ({
              ...prevState,
              scannedBarcodes: []
            }));
            return false;
          }
          setScanState((prevState) => ({
            ...prevState,
            scannedBarcodes: [buffer]
          }));
          return {
            scanType: "product barcode",
            scannedProductBarcode: buffer,
            isNewProductScan: true
          };
        }

        // product is confirmable
        // a) once per pick
        if (
          pickScanProductConfiguration ===
          ("OncePerPick" as PickScanProductConfiguration)
        ) {
          // If we're only scanning once per pick, we have to assume that all the barcodes are the same
          const duplicateScannedBarcode = createNumberSequence(
            confirmablePickMatch.quantity.value
          ).map(() => buffer);
          setScanState((prevState) => ({
            ...prevState,
            scannedBarcodes: duplicateScannedBarcode
          }));

          return {
            scanType: "product barcode",
            scannedProductBarcode: buffer
          };
        }

        if (
          pickScanProductConfiguration ===
          ("OncePerQuantity" as PickScanProductConfiguration)
        ) {
          if (
            pickScanToteConfiguration ===
              ("OncePerPick" as PickScanToteConfiguration) &&
            scanState.productScanCount < confirmablePickMatch.quantity.value
          ) {
            return {
              scanType: "product barcode",
              scannedProductBarcode: buffer
            };
          }
        }
      }
      return false;
    },

    /*
        PROCESS SCAN MATCH
    */

    processScanMatch: (scanObj) => {
      if (!scanObj || !batchViewModel) return;
      const {
        scanType,
        scannedProductBarcode,
        scannedToteId,
        isNewProductScan
      } = scanObj;

      /*
          0. PRODUCT BARCODE (process)
             "no totes" config, or batch type is bulk
      */

      if (
        (props.toteConfiguration === "NoTotes" || isBulk) &&
        scannedProductBarcode
      ) {
        // fulfill the first unfulfilled pick with the matching upc
        const pickProductMatch = getAllPickModels(batchViewModel).find(
          (pickModel) => {
            const codesToMatch = pickModel.allUpcs;
            return (
              pickModel.status === "Unfulfilled" &&
              matchesUpc(codesToMatch, scannedProductBarcode)
            );
          }
        );

        if (pickProductMatch) {
          setScanState((prevState) => ({
            ...prevState,
            scannedBarcodes: [scannedProductBarcode]
          }));
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          fulfillScannedPick(pickProductMatch, scannedProductBarcode);
        }
        return;
      }

      /*
          1. SCANNED PRODUCT BARCODE (process)
      */

      // TODO: for weight adjusted items with a qty > 1 and a pickScanProductConfiguration of "oncePerPick",
      // we should likely handle these more like "oncePerQty", so that we can capture the weight adjust and each unique product scan

      if (scanType === "product barcode" && scannedProductBarcode) {
        // new product scan, totes multiple
        if (
          isNewProductScan &&
          pickScanConfirmableToteConfiguration ===
            ("Multiple" as PickScanConfirmableToteConfigutation)
        ) {
          // find and set all picks with matching barcode to confirmable
          const pickMatches = getAllPickModels(batchViewModel).filter(
            (pickModel) => {
              const codesToMatch = pickModel.allUpcs;
              return (
                pickModel.status === "Unfulfilled" &&
                matchesUpc(codesToMatch, scannedProductBarcode)
              );
            }
          );

          props.setConfirmablePickIds(
            pickMatches.map((pick) => pick.pickId) || []
          );
          setScanState({
            recentScannedProductBarcode: scannedProductBarcode,
            productScanCount: scanCount(pickMatches[0]),
            toteScanCount: 0,
            scannedBarcodes: [scannedProductBarcode]
          });

          // new product scan, totes single
        } else if (
          isNewProductScan &&
          pickScanConfirmableToteConfiguration ===
            ("Single" as PickScanConfirmableToteConfigutation)
        ) {
          // find the first pick with a barcode / upc match
          const pickProductMatch = getAllPickModels(batchViewModel).find(
            (pickModel) => {
              const codesToMatch = pickModel.allUpcs;

              return (
                pickModel.status === "Unfulfilled" &&
                matchesUpc(codesToMatch, scannedProductBarcode)
              );
            }
          );

          let weight: number | null = null;
          // if 13 digit barcode - look for leading 20 or 02
          // if 12 digit barcode - look for leading
          if (
            scannedProductBarcode &&
            pickProductMatch &&
            isWeightedBarcode(scannedProductBarcode)
          ) {
            weight = weightedBarcodeWeight(scannedProductBarcode);
          }

          if (pickProductMatch && weight) {
            const { pickId } = pickProductMatch;

            if (batchViewModel) {
              // if the scanned item is a weighted item with a single quantity, weightAdjustPick
              // then fulfill the pick by passing weight adjusted batch model and weight adjusted pick model

              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
              props.weightAdjustPick(
                batchViewModel,
                pickId,
                weight,
                scanCount(pickProductMatch)
              );
            }
          }

          if (!pickProductMatch) return;

          const scanQty = scanCount(pickProductMatch);

          props.setConfirmablePickIds([pickProductMatch.pickId]);

          setScanState({
            recentScannedProductBarcode: scannedProductBarcode,
            productScanCount: scanQty,
            toteScanCount: 0,
            scannedBarcodes: [scannedProductBarcode]
          });
          if (
            pickScanProductConfiguration ===
              ("OncePerQuantity" as PickScanProductConfiguration) &&
            pickScanToteConfiguration ===
              ("OncePerPick" as PickScanToteConfiguration)
          ) {
            props.setActivePickCompletion({
              pickId: pickProductMatch.pickId,
              completion: Math.round(
                (scanQty / pickProductMatch.quantity.value) * 100
              ),
              buffer: Math.round((1 / pickProductMatch.quantity.value) * 100)
            });
          }

          // existing product scan,  scan once per quantity
        } else if (
          pickScanProductConfiguration ===
          ("OncePerQuantity" as PickScanProductConfiguration)
        ) {
          if (confirmablePickIds.length === 1) {
            const matchPick = getAllPickModels(batchViewModel).find(
              (pickModel) => pickModel.pickId === confirmablePickIds[0]
            );
            if (matchPick) {
              let weight: number | null = null;
              // if 13 digit barcode - look for leading 20 or 02
              // if 12 digit barcode - look for leading
              if (
                scannedProductBarcode &&
                matchPick &&
                isWeightedBarcode(scannedProductBarcode)
              ) {
                weight = weightedBarcodeWeight(scannedProductBarcode);
              }

              if (matchPick && weight) {
                const { pickId } = matchPick;
                if (batchViewModel) {
                  // if the scanned item is a weighted item with a single quantity, weightAdjustPick
                  // then fulfill the pick by passing weight adjusted batch model and weight adjusted pick model

                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                  props.weightAdjustPick(
                    batchViewModel,
                    pickId,
                    weight,
                    scanCount(matchPick)
                  );
                }
              }
              if (
                pickScanToteConfiguration ===
                ("OncePerPick" as PickScanToteConfiguration)
              ) {
                props.setActivePickCompletion({
                  pickId: matchPick.pickId,
                  completion: Math.round(
                    ((scanState.productScanCount + 1) /
                      matchPick.quantity.value) *
                      100
                  ),
                  buffer: Math.round(
                    ((scanState.productScanCount + 1) /
                      matchPick.quantity.value) *
                      100
                  )
                });
              }
            }
          }
          setScanState((prevState) => ({
            ...prevState,
            scannedBarcodes: [
              ...prevState.scannedBarcodes,
              scannedProductBarcode
            ],
            productScanCount: prevState.productScanCount + 1
          }));
        }

        /*
            2. SCANNED TOTE ID (process)
        */
      } else if (
        scanType === "toteId" &&
        scannedToteId &&
        scanState.recentScannedProductBarcode
      ) {
        // find the confirmable pick with a matching tote id
        const pickConfirmableToteMatch = getAllPickModels(batchViewModel).find(
          (pickModel) =>
            pickModel.assignedToteId === scannedToteId &&
            confirmablePickIds.includes(pickModel.pickId)
        );

        if (!pickConfirmableToteMatch) return;
        // scan product once per pick, or quantity is 1, or units is lb (item count will be 1 even if qty > 1), then fulfill

        if (
          pickScanToteConfiguration ===
            ("OncePerPick" as PickScanToteConfiguration) &&
          (pickConfirmableToteMatch.quantity.value ===
            scanState.productScanCount ||
            pickConfirmableToteMatch.quantity.units === "lb")
        ) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          fulfillScannedPick(
            pickConfirmableToteMatch,
            scanState.recentScannedProductBarcode
          );
          return;
        }

        // scan product once per quantity
        if (
          pickScanProductConfiguration ===
          ("OncePerQuantity" as PickScanProductConfiguration)
        ) {
          if (
            pickConfirmableToteMatch.quantity.value ===
              scanState.toteScanCount + 1 ||
            pickConfirmableToteMatch.quantity.units === "lb"
          ) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            fulfillScannedPick(
              pickConfirmableToteMatch,
              scanState.recentScannedProductBarcode
            );
            return;
          }

          // else quantity is not fulfilled

          // once a tote is has been selected, make it the only confirmable pick
          if (confirmablePickIds.length > 1) {
            props.setConfirmablePickIds([pickConfirmableToteMatch.pickId]);
          }

          setScanState((prevState) => ({
            ...prevState,
            toteScanCount: scanState.toteScanCount + 1
          }));

          props.setActivePickCompletion({
            pickId: pickConfirmableToteMatch.pickId,
            completion: Math.round(
              ((scanState.toteScanCount + 1) /
                pickConfirmableToteMatch.quantity.value) *
                100
            ),
            buffer: Math.round(
              ((scanState.toteScanCount + 1) /
                pickConfirmableToteMatch.quantity.value) *
                100
            )
          });
        }
      }
    },
    deps: [batchViewModel, confirmablePickIds, scanState]
  });

  const pickComplete = isPickingComplete(batchViewModel);

  return (
    <Container
      sx={{
        padding: 0,
        mt: { tablet: 3 },
        width: { tablet: "95%", xs: "100%" }
      }}
    >
      <Grid container direction="column">
        {loadingBatchViewModel && <PickLoading />}
        {(!loadingBatchViewModel &&
          batchViewModel &&
          Object.values(batchViewModel?.picks)
            .flatMap((x) => x)
            .flatMap((pickModel) => (
              <Pick
                pickModel={pickModel}
                key={pickModel.key}
                addToteToBatch={handleAddToteToBatch}
                revertPick={handleRevertPick}
                activePickCompletion={
                  pickModel.pickId === activePickCompletion?.pickId
                    ? activePickCompletion
                    : null
                }
                isFulfilling={fulfillingPickId === pickModel.pickId}
              />
            ))) ||
          []}
      </Grid>
      <PackModal
        pickComplete={pickComplete}
        getTotesByBatch={props.getTotesByBatch}
      />
      {weightAdjustParameters && (
        <WeightAdjust
          open
          pick={weightAdjustParameters.pick}
          scannedWeight={weightAdjustParameters.weight}
          closePanel={(): void => props.endWeightAdjustment()}
          adjustWeight={(pickId, weight, qty): void => {
            if (batchViewModel) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
              props
                .weightAdjustPick(batchViewModel, pickId, weight, qty)
                .then(() => {
                  // how many of the pick qty have already been weight adjusted
                  const currentWeightAdjustedQty = (
                    weightAdjustParameters.pick.exception?.weightAdjustments ||
                    []
                  ).reduce((acc: number, wa) => acc + wa.quantity, 0);
                  const newProductScanCount = currentWeightAdjustedQty + qty;
                  const pickUpc = weightAdjustParameters.pick.upc;
                  const weightPerQty = weight / qty;
                  const isUpcPlu = isPlu(pickUpc);
                  // if its not a plu (e.g. bananas), reconstruct UPC with weight, as though it was scanned
                  const newBarcode = isUpcPlu
                    ? pickUpc
                    : createWeightedBarcode(pickUpc, weightPerQty);
                  // if adjusting multiple qty, duplicate the "barcode"
                  const duplicateBarcodes = createNumberSequence(qty).map(
                    () => newBarcode
                  );
                  const expectedScanCount =
                    weightAdjustParameters.pick.quantity.units.toLowerCase() ===
                    "lb"
                      ? 1
                      : weightAdjustParameters.pick.quantity.value;
                  // pretend that we scanned the item to support mixed manual and scanned weight adjustments
                  setScanState((prevState) => ({
                    ...prevState,
                    productScanCount: newProductScanCount
                  }));
                  // if product scanning is in progress, add to scanned barcodes, otherwise overwrite scanned barcodes
                  const productScanningInProgress =
                    confirmablePickIds.includes(pickId) &&
                    !!scanState.scannedBarcodes.length &&
                    scanState.scannedBarcodes.length < expectedScanCount;
                  if (productScanningInProgress) {
                    setScanState((prevState) => ({
                      ...prevState,
                      scannedBarcodes: {
                        ...prevState.scannedBarcodes,
                        ...duplicateBarcodes
                      }
                    }));
                  } else {
                    setScanState((prevState) => ({
                      ...prevState,
                      scannedBarcodes: duplicateBarcodes
                    }));
                    props.setConfirmablePickIds([pickId]);
                  }
                  setScanState((prevState) => ({
                    ...prevState,
                    recentScannedProductBarcode: newBarcode
                  }));
                  props.setActivePickCompletion({
                    pickId,
                    completion: Math.round(
                      (newProductScanCount /
                        weightAdjustParameters.pick.quantity.value) *
                        100
                    ),
                    buffer: Math.round(
                      (1 / weightAdjustParameters.pick.quantity.value) * 100
                    )
                  });
                });
            }
          }}
        />
      )}
    </Container>
  );
});
Batch.displayName = "Batch";

export default connector(Batch);
